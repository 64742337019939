import fingerprint from '@adv/sdk/fingerprint';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IsAuthenticated } from '@adv/sdk/user';
import { GetStore } from '../stores/stores.thunk';
import { isAuthenticated } from '../../routes';
import { inventoryActions } from '../inventory/inventory.slice';
import { GetAllBusinessCategories } from '../inventory/inventory.thunk';
import { sessionGet } from '@adv/utils/storage';
import Cookies from 'js-cookie';

export const AppInitialize = createAsyncThunk(
  'app/initialize',
  async (payload, thunkAPI): Promise<any> => {
    const id = await fingerprint();
    thunkAPI.dispatch(IsUserAuthenticated());
    thunkAPI.dispatch(GetAllBusinessCategories());
    return thunkAPI.fulfillWithValue(id);
  }
);

export const IsUserAuthenticated = createAsyncThunk(
  'app/auth',
  async (payload, thunkAPI): Promise<any> => {
    const { data } = await IsAuthenticated();
    // console.log(isAuthenticated, 'isAuthenticated');
    if (isAuthenticated()) {
      const storeId = Cookies.get('storeId')
      // const storeId = sessionGet('storeId');
      if (storeId) {
        thunkAPI.dispatch(GetStore(storeId));
      }
    }
    if (data) {
      return thunkAPI.fulfillWithValue(data);
    }
    return thunkAPI.rejectWithValue(data);
  }
);
