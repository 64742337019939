import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, Image, Text, Badge, Button, Group } from '@mantine/core';
import { Link } from 'react-typesafe-routes';
import { router } from '../routes';
import { useAppSelector } from '../store/hooks';
function Home(props: any) {
  const store = useAppSelector((state) => state.store.store);
  return (
    <div className="p-2 h-screen">
      <Card shadow="md" p="sm" withBorder>
        <p className="text-xl">Your e-commerce store is ready!</p>
        <p>Customers can visit the following link and place their orders.</p>
        <a
          href={`https://store.versicles.com/home?id=${store?.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="underline text-primary-900"
        >
          Visit
        </a>
      </Card>
      <div>
      <iframe src={`https://store.versicles.com/home?id=${store?.id}`} title="W3Schools Free Online Web Tutorials" className='w-full h-screen rounded-2xl'></iframe>
      </div>
    </div>
  );
}

Home.propTypes = {};

export default Home;
