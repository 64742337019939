import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { LoadingState } from '@adv/sdk/common';
import { AppInitialize, IsUserAuthenticated } from './application.thunk';
import { sessionSet } from '@adv/utils/storage';

export const APPLICATION_FEATURE_KEY = 'application';

export interface ApplicationState {
  loadingStatus: LoadingState;
  uid: string | null;
}

export const initialApplicationState: ApplicationState = {
  loadingStatus: LoadingState.idle,
  uid: null,
};

export const applicationSlice = createSlice({
  name: APPLICATION_FEATURE_KEY,
  initialState: initialApplicationState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AppInitialize.fulfilled, (state, { payload }) => {
        state.uid = payload;
        sessionSet('uid', payload);
      })
      .addCase(IsUserAuthenticated.fulfilled, (state, { payload }) => {
        // if (payload.code === 400)
        //   window.location.replace('http://localhost:4300/login');
      });
  },
});

export const applicationReducer = applicationSlice.reducer;

export const applicationActions = applicationSlice.actions;
