import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import {
  NumberInput,
  TextInput,
  Button,
  Box,
  Group,
  Textarea,
  Text,
  Select,
  Modal,
  Card,
  Image,
  MultiSelect,
} from '@mantine/core';
import { PhotographIcon } from '@heroicons/react/outline';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { Link, useRouteParams } from 'react-typesafe-routes';
import { router } from '../routes';
import { AdvButton } from '@adv/ui';
import { inventoryActions } from '../store/inventory/inventory.slice';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { ArrowLeftIcon, TrashIcon } from '@heroicons/react/solid';
import {
  CreateProduct,
  UpdateProduct,
} from '../store/inventory/inventory.thunk';
import { GetBusinessProduct, UploadImage } from '@adv/sdk/stores';

const schema = Yup.object().shape({
  name: Yup.string().min(2, 'Name should have at least 2 letters'),
  unit: Yup.string().when('size', {
    is: (size) => !!size,
    then: Yup.string().required('Unit is required'),
  }),
  // product_type: Yup.string().required('Product type is required'),
});

function ImageUploadIcon({ status, ...props }: any) {
  if (status.accepted) {
    return <PhotographIcon {...props} />;
  }

  if (status.rejected) {
    // return <X {...props} />;
    return <PhotographIcon {...props} />;
  }

  // return <Photo {...props} />;
  return <PhotographIcon {...props} />;
}

export const dropzoneChildren = (status: any) => (
  <Group
    position="center"
    spacing="xl"
    style={{ minHeight: 100, pointerEvents: 'none' }}
  >
    <ImageUploadIcon
      status={status}
      style={{ color: '#ced4da', width: '50px', height: '50px' }}
    />

    <div>
      <Text size="md" inline>
        Drag images here or{' '}
        <span className="text-primary-700">click to select files</span>
      </Text>
      <Text size="sm" color="dimmed" inline mt={7}>
        Attach as many files as you like, each file should not exceed 5mb
      </Text>
    </div>
  </Group>
);

function ProductForm(props: any) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const products = useAppSelector((state: any) => state.inventory.product);
  const categories = useAppSelector((state: any) => state.inventory.category);
  const store = useAppSelector((state: any) => state.store.store);
  const productTypes = useAppSelector(
    (state: any) => state.inventory.productTypes
  );
  const [isSoldOut, setSoldOut] = useState(false);
  const [isActive, setActive] = useState(true);
  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      name: '',
      description: '',
      price: '',
      discounted_price: '',
      sku: '',
      industry: '',
      product_categories: [],
      images: [],
      sold_out: false,
      product_type: '',
      boost: 0,
      size: null,
      unit: '',
      label: '',
      active: true,
    },
  });
  const options = useRouteParams(router.catalogue().updateProduct);
  const getImage = (imageFile: any) => {
    setImageUpload(imageFile[0].path);
  };
  const [imageUpload, setImageUpload] = useState('');
  const [opened, setOpened] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [updateProduct, setUpdateProduct] = useState<any>(null);
  const [uploadImages, setUploadImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const setImage = async (files) => {
    setImageLoading(true);
    const { data, status, error } = await UploadImage(files);
    if (data && data?.length > 0) {
      const formVal = form.values;
      form.setValues({
        ...formVal,
        images: [...formVal.images, data[0]?.id],
      });
      setUploadImages([
        ...uploadImages,
        { id: data[0]?.id, url: data[0]?.url },
      ]);
      setImageLoading(false);
    }
  };
  const deleteImg = (img) => {
    const formVal = form.values;
    const updatedImgList = uploadImages.filter((item) => item.id !== img?.id);
    const updatedFormImgList = formVal.images.filter(
      (item) => item !== img?.id
    );
    form.setValues({
      ...formVal,
      images: [...updatedFormImgList],
    });
    setUploadImages([...updatedImgList]);
  };
  useEffect(() => {
    console.log(options.id);
    if (options?.id) {
      setUpdate(true);
      getProductDetails(options?.id);
    }
  }, [products]);

  const getProductDetails = async (productId) => {
    const { data, status, error } = await GetBusinessProduct(
      store?.id,
      options?.id
    );
    const selectedProduct = data?.product;
    // const selectedProduct = products.filter((e) => e.id == options.id)[0];
    console.log(selectedProduct);
    if (!selectedProduct) return;
    const selectedCategory = selectedProduct?.product_categories?.map(
      (e) => e.id
    );
    const selectedProductType = selectedProduct?.product_type?.id
      ? '' + selectedProduct?.product_type?.id
      : '';
    console.log(selectedProductType, productTypes);

    const selectedImages = selectedProduct?.image
      ? selectedProduct?.image?.map((e) => e.id)
      : [];

    form.setValues({
      name: selectedProduct?.name,
      description: selectedProduct?.description || '',
      price: selectedProduct?.price || '',
      discounted_price: selectedProduct?.discounted_price || null,
      sku: selectedProduct?.sku || '',
      industry: selectedProduct?.industry || '',
      product_categories: [...selectedCategory] || [],
      images: [...selectedImages] || [],
      sold_out: selectedProduct?.sold_out || false,
      product_type: selectedProductType,
      boost: selectedProduct?.boost || 0,
      size: +selectedProduct?.product_dimension?.size || '',
      unit: selectedProduct?.product_dimension?.unit || '',
      label: selectedProduct?.product_dimension?.label || '',
      active: selectedProduct?.active || false,
    });
    setSoldOut(selectedProduct?.sold_out || false);
    setActive(selectedProduct?.active);
    setUpdateProduct(selectedProduct);
    setUploadImages(selectedProduct.image ? [...selectedProduct.image] : []);
  };

  const onFormSubmit = (values) => {
    console.log(values);
    if (isUpdate) {
      dispatch(
        UpdateProduct({
          ...values,
          product_dimension: {
            size: values?.size?.toString(),
            unit: values?.unit,
            label: values?.label,
          },
          product_type: values?.product_type ? [+values?.product_type] : [],
          id: options?.id,
          storeId: store?.id,
          sold_out: isSoldOut,
          active: isActive,
        })
      );
    } else {
      const currentDate = new Date();
      const currentISOTime = currentDate.toISOString();
      dispatch(
        CreateProduct({
          ...values,
          product_type: values?.product_type ? [+values?.product_type] : [],
          storeId: store?.id,
          sold_out: isSoldOut,
          active: isActive,
          publishedAt: currentISOTime,
        })
      );
    }

    setOpened(true);
    history.push(router.catalogue().products().$);
  };

  const UploadedImages = uploadImages
    ? uploadImages.map((img, index) => (
        <div className="w-32 mx-2" key={index}>
          <Card shadow="md" p="sm" withBorder>
            <Card.Section>
              <div
                className="flex justify-end cursor-pointer p-1"
                onClick={(e: any) => {
                  deleteImg(img);
                  // console.log('delete', img);
                }}
              >
                <TrashIcon className="w-5 h-5"></TrashIcon>
              </div>
            </Card.Section>
            <Image
              src={
                img?.url ||
                'https://versicles-strapi-cms-media.s3.amazonaws.com/store-def.jpg'
              }
              className="w-24"
              alt={updateProduct?.name}
              fit="contain"
            />
          </Card>
        </div>
      ))
    : '';

  return (
    <>
      <div
        className="flex justify-start items-center p-2 cursor-pointer"
        onClick={() => {
          // history.push(router.catalogue().products().$);
          history.goBack();
        }}
      >
        <ArrowLeftIcon className="w-4 h-4"></ArrowLeftIcon>
        Back
      </div>
      <div className=" productForm m-auto mt-4 w-10/12">
        <form
          onSubmit={form.onSubmit((values) => {
            onFormSubmit(values);
          })}
        >
          <div className=" bg-gray-100 p-2 rounded-md shadow-sm font-semibold mb-2 sticky z-20 top-16 flex justify-between items-center">
            Product Information
            <Button
              className="bg-primary-900 hover:bg-primary-900"
              type="submit"
              disabled={imageLoading}
            >
              {' '}
              {isUpdate ? 'Save Product' : 'Add Product'}
            </Button>
          </div>
          <TextInput
            required
            label="Product Name "
            placeholder="Name"
            {...form.getInputProps('name')}
          />
          <MultiSelect
            className="mt-4"
            label="Product Category"
            placeholder="Product Category"
            data={categories?.map((e) => ({
              label: e.name,
              value: e.id,
              ...e,
            }))}
            searchable
            nothingFound="Nothing found"
            creatable
            {...form.getInputProps('product_categories')}
          />
          <Textarea
            label="Description"
            placeholder="Description"
            mt="sm"
            autosize
            minRows={4}
            maxRows={6}
            {...form.getInputProps('description')}
          />
          <div className="grid sm:grid-cols-2 gap-4">
            <NumberInput
              required
              hideControls
              label="Price"
              placeholder="Price"
              mt="sm"
              mb="sm"
              {...form.getInputProps('price')}
              precision={2}
            />
            <NumberInput
              hideControls
              label="Discounted Price"
              placeholder="Discounted Price"
              mt="sm"
              mb="sm"
              {...form.getInputProps('discounted_price')}
              precision={2}
            />
          </div>
          <div className="flex justify-between w-full my-2">
            <label className="font-medium inline-block text-gray-800">
              Sold Out
            </label>
            <input
              {...form.getInputProps('sold_out')}
              className="toggle_switch cursor-pointer"
              type="checkbox"
              checked={isSoldOut}
              onChange={(event) => {
                setSoldOut(event.currentTarget.checked);
              }}
            />
          </div>
          {/* <div className="flex justify-between w-full my-2">
            <label className="font-medium inline-block text-gray-800">
              Active
            </label>
            <input
              {...form.getInputProps('active')}
              className="toggle_switch cursor-pointer"
              type="checkbox"
              checked={isActive}
              onChange={(event) => {
                setActive(event.currentTarget.checked);
              }}
            />
          </div> */}
          <Select
            label="Product type"
            required
            placeholder="product_food_type"
            data={productTypes?.map((e) => ({
              label: e.label,
              value: e.value,
            }))}
            nothingFound="Nothing found"
            {...form.getInputProps('product_type')}
          />

          <div className="py-1 px-1 mt-2 border border-solid ">
            <p className="text-base font-semibold  px-1">Product Dimension</p>
            <div className="grid sm:grid-cols-2 gap-4">
              <NumberInput
                className=""
                required={form.values.unit ? true : false}
                hideControls
                label="Weight(in gm/ml)"
                placeholder="Weight"
                {...form.getInputProps('size')}
              />
              <Select
                className=""
                required={form.values.size ? true : false}
                label="Unit"
                placeholder="Pick one"
                data={[
                  // { value: 'kg', label: 'kg' },
                  { value: 'gm', label: 'gm' },
                  // { value: 'Ltr', label: 'Ltr' },
                  { value: 'ml', label: 'ml' },
                ]}
                {...form.getInputProps('unit')}
              />
            </div>
            <div className="grid sm:grid-cols-2 gap-4">
              <TextInput
                className=""
                label="Label"
                placeholder="Label"
                mt="xs"
                mb="xs"
                {...form.getInputProps('label')}
              />
            </div>
          </div>
          <Dropzone
            mt="xl"
            onDrop={(files) => setImage(files)}
            onReject={(files) => console.log('rejected files', files)}
            maxSize={3 * 1024 ** 2}
            accept={IMAGE_MIME_TYPE}
          >
            {(status) => dropzoneChildren(status)}
          </Dropzone>
          {UploadedImages && (
            <>
              <div className="my-6">Uploads:</div>
              <div className="flex flex-wrap">{UploadedImages}</div>
            </>
          )}
        </form>
      </div>
    </>
  );
}

ProductForm.propTypes = {};

export default ProductForm;
