import { Api, GetHeader } from '../utils/api';
import Cookies from 'js-cookie';

const USER_URL = 'user';
const token = Cookies.get('token');

const config = {
  headers: { Authorization: `Bearer ${token}` },
};
export interface UserLoginArgs {
  email: string;
  password: string;
}

export interface UserSignUpArgs extends UserLoginArgs {
  // name: string;
  email: string;
  password: string;
  // tenant: any[];
  // roles: any[];
}

export const SignUp = async (args: UserSignUpArgs) => {
  const url = `${USER_URL}/sign-up`;
  try {
    const { data, status } = await Api.post(url, args);
    return {
      status: status || 200,
      data,
    };
  } catch (error) {
    return { status: 400, error };
  }
};

export const Login = async (args: UserLoginArgs) => {
  const url = `auth/login`;
  try {
    const res = await Api.post(url, args);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};

export const IsAuthenticated = async () => {
  const url = `auth`;
  try {
    const res = await Api.get(url, config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const getuserbymail = async (mailid: string) => {
  try {
    const res = await Api.get(`/user/details/email/${mailid}`, config);
    return {
      status: +res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};
export const getuserbyId = async (id: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const res = await Api.get(`/user/details/${id}`, config);
    return {
      status: +res.status || 200,
      userData: res.data || null,
    };
  } catch (error) {
    return { status: 400, error, userdata: null };
  }
};
export const UpdateUser = async (id: string, data: any) => {
  try {
    const res = await Api.put(`/user/${id}`, data, config);
    return {
      status: 200,
      Updatedata: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const UserList = async () => {
  const url = `user`;

  try {
    const res = await Api.get(url, config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const UserListbyorg = async (id: any) => {
  const url = `user/byOrg`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const res = await Api.get(`${url}/${id}`, config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const adminCreateUser = async (data: any) => {
  const url = `user/admin/create`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const res = await Api.post(`${url}`, data, config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const adminDeleteUsers = async (id: string) => {
  const url = `user`;
  try {
    const res = await Api.delete(`${url}/${id}`, config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const Userdetails = async () => {
  const url = `user/details`;
  try {
    const res = await Api.get(url, config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const findbytoken = async (tok:any) => {
  const url = `auth/findbytoken`;
  const config = {
    headers: { Authorization: `Bearer ${tok}` },
  };
  try {
    const res = await Api.post(url,config, config);
    return {
      status: res.status || 200,
      userdetails: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const userRoles = async () => {
  const url = `role`;
  try {
    const res = await Api.get(url, config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};

export const allOrganizations = async () => {
  const url = `organization`;
  try {
    const res = await Api.get(url, config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};

export const getorganizationbyId = async (id: string) => {
  try {
    const res = await Api.get(`/organization/${id}`, config);
    return {
      status: +res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};
export const createOrganization = async (data: any) => {
  try {
    const res = await Api.post('/organization', data, config);
    const resd = await Api.post('/organization/strapi', data, config);
    return {
      status: 200,
      org: res || null,
      resd:resd||null
      
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const createOrganizationSignup = async (data: any,token:any) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const res = await Api.post('/organization', data, config);
    const resd = await Api.post('/organization/strapi', data, config);
    return {
      status: 200,
      org: res || null,
      resd:resd||null
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const deleteOrganization = async (id: string) => {
  const url = `organization`;
  try {
    const res = await Api.delete(`${url}/${id}`, config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const UpdateOrganization = async (id: string, data: any) => {
  const url = `organization`;
  try {
    const res = await Api.put(`${url}/${id}`, data, config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};

export const AccountUsersByWebsite = async (id: any) => {
  const url = `user/byWebsite`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const res = await Api.get(`${url}/${id}`, config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};

export const setUserAvatar = async (id: string, data: any) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  try {
    const res = await Api.post(`/user/avatar/${id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return {
      status: 200,
      org: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const setUserSignupload = async (id: string, data: any) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  try {
    const res = await Api.post(`/user/sign/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      status: 200,
      org: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
