import { ArrowLeftIcon, PrinterIcon } from '@heroicons/react/solid';
import {
  Button,
  Card,
  CloseButton,
  Dialog,
  Group,
  Image,
  Modal,
  Select,
  Skeleton,
  Stack,
  Table,
  Tabs,
  Text,
  TextInput,
  Timeline,
} from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { useRouteParams } from 'react-typesafe-routes';
import { router } from '../routes';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useHistory } from 'react-router-dom';
import { addDays, format, formatRelative, parseISO } from 'date-fns';
import { orderActions } from '../store/orders/orders.slice';
import {
  GetOrderById,
  UpdateOrder,
  UpdateOrderLockerInfo,
  UpdateOrderStatusInfo,
  OrderRefundInfo,
} from '../store/orders/orders.thunk';
import { order_status, default_order_timeLine } from '@adv/enums';
import { useMediaQuery } from '@mantine/hooks';
import { getAvailableBox, getSellerAgency } from '@adv/sdk/stores';
import { socket } from '@adv/utils/socket';
import { useReactToPrint } from 'react-to-print';
import BillForPrint from './billForPrint';

function OrderDetails() {
  const options = useRouteParams(router.order().viewOrder);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const store = useAppSelector((state) => state.store.store);
  // const items = orders.filter((e) => e.id == options?.id)[0];
  const orderData = useAppSelector((state: any) => state.order.order);

  const loading = useAppSelector((state: any) => state.order.loading);

  const [status, setStatus] = useState(null);
  const [passCode, setPassCode] = useState(null);
  const [boxId, setBoxId] = useState(null);
  const [isCancelDialogOpened, setCancelDialogOpened] = useState(false);
  const [isAddToLockerModalOpened, setAddToLockerModalOpened] = useState(false);
  const [isAddAgencyModalOpened, setAddAgencyModalOpened] = useState(false);
  const [nextStatus, setNextStatus] = useState(null);
  const [lockerBoxes, setLockerBoxes] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [agencyId, setAgencyId] = useState(null);
  const [formattedOrderItem, setFormattedOrderItem] = useState([]);
  const isMobile = useMediaQuery('(max-width: 700px)');
  const [order_timeLine, setOrder_timeLine] = useState<any>(
    default_order_timeLine
  );
  const [orderItems, setOrderItems] = useState<any>();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    dispatch(orderActions.clearOrder());
    if (options?.id) {
      dispatch(GetOrderById({ businessId: store?.id, orderId: options?.id }));
      socket.on('statusUpdate', (orderId) => {
        // console.log(orderId, 'orderStaus',options);
        if (options?.id == orderId) {
          dispatch(GetOrderById({ businessId: store?.id, orderId: orderId }));
        }
      });
    }
    const interval = setInterval(fetchData, 60000); // Fetch data every 1 min
    return () => {
      // console.log('closeStatusUpdate');
      socket?.off('statusUpdate');
      clearInterval(interval);
    };
  }, []);
  const fetchData = async () => {
    if (store?.id && options?.id) {
      dispatch(GetOrderById({ businessId: store?.id, orderId: options?.id }));
    }
  };
  useEffect(() => {
    // console.log('stores', store);
  }, [store]);

  useEffect(() => {
    if (!orderData) return;
    let updatedTimeline = order_timeLine;
    if (orderData?.deliveryTime?.whereToPickup == 'driveUp') {
      updatedTimeline = {
        ...order_timeLine,
        completed: {
          step: 5,
          name: 'Order Ready to pickup',
          btn_default: { label: 'Cancel order', next: 'cancelled' },
          orderStatus: order_status.completed,
          orderStatusLabel: 'Ready to pickup',
        },
      };
      setOrder_timeLine(updatedTimeline);

      // timeLine.completed = {
      //   step: 5,
      //   name: 'Order Ready to pickup',
      //   btn_default: { label: 'Cancel order', next: 'cancelled' },
      //   orderStatus: order_status.completed,
      //   orderStatusLabel: 'Ready to pickup',
      // }
    } else {
      updatedTimeline = default_order_timeLine;
      setOrder_timeLine(updatedTimeline);
    }
    setStatus(updatedTimeline[orderData?.status?.name]);
    const mapped = {};
    orderData?.order_items.length > 0 &&
      orderData?.order_items?.forEach((item) => {
        if (item?.business?.name in mapped)
          return mapped[item?.business?.name].push(item);

        mapped[item?.business?.name] = [item];
      });
    const expectedFormat = Object.keys(mapped).map((key) => {
      const o = {};
      o['businessName'] = key == 'undefined' ? '' : key;
      o['items'] = mapped[key];
      o['status'] = mapped[key][0].status;
      // o[key] = mapped[key];

      return o;
    });
    console.log(expectedFormat, 'expectedFormat');
    setFormattedOrderItem(expectedFormat);
  }, [orderData]);

  const formatAvailableBox = (boxes) => {
    boxes?.locker_boxes
      ? setLockerBoxes(boxes?.locker_boxes)
      : setLockerBoxes(boxes.filter((e) => !e.is_locked));
  };
  const nextStep = (step) => {
    const nextStep = order_timeLine[step];
    setNextStatus(nextStep);
    switch (nextStep.step) {
      case 4:
        setCancelDialogOpened(true);
        break;
      case 2:
        checkAgencyDetails();
        break;
      // case 6:
      //   if (store?.is_kiosk_business && !orderData?.cash_on_delivery) {
      //     const lockerId = store?.kiosks[0]?.locker.id;
      //     (async () => {
      //       const boxes = await getAvailableBox(lockerId);
      //       formatAvailableBox(boxes.data);
      //       setAddToLockerModalOpened(true);
      //     })();
      //     setPassCode(Math.floor(1000 + Math.random() * 9000));
      //   } else if (store?.is_kiosk_business && orderData?.cash_on_delivery) {
      //     dispatch(
      //       UpdateOrderStatusInfo({
      //         businessId: store?.id,
      //         orderId: orderData?.id,
      //         status: nextStep.orderStatus,
      //       })
      //     );
      //   }
      //   break;
      default:
        // setStatus(nextStep);
        dispatch(
          UpdateOrderStatusInfo({
            businessId: store?.id,
            orderId: orderData?.id,
            status: nextStep.orderStatus,
          })
        );
        // setOrderTrack((prev) => {
        //   console.log([...prev, currentTrack]);
        //   return [...prev, currentTrack];
        // });
        break;
    }
  };

  const addToLocker = async () => {
    const locker_id = orderData?.deliveryTime?.lockerId;
    const boxes = await getAvailableBox(locker_id);
    formatAvailableBox(boxes.data);
    setAddToLockerModalOpened(true);
    // setPassCode(Math.floor(1000 + Math.random() * 9000));
    // console.log(status, 'status');
  };
  const cancelOrder = () => {
    setCancelDialogOpened(false);
    dispatch(
      UpdateOrderStatusInfo({
        businessId: store?.id,
        orderId: orderData?.id,
        status: nextStatus.orderStatus,
      })
    );
    // setStatus(nextStatus);
    // setOrderTrack((prev) => {
    //   console.log([...prev, nextStatus]);
    //   return [...prev, nextStatus];
    // });
  };

  const addLockerDetails = async () => {
    setAddToLockerModalOpened(false);
    const selectedBox = lockerBoxes.filter((e) => e.id == boxId)[0];
    // console.log(selectedBox, 'lockerBoxes');
    const locker = {
      number: selectedBox?.number?.toString(),
      locker_box_id: selectedBox?.id,
      locker_id: orderData?.deliveryTime?.lockerId,
    };
    const res = await dispatch(
      UpdateOrderLockerInfo({
        businessId: store?.id,
        orderId: orderData?.id,
        kioskLocker: locker,
        customer: orderData?.customer,
      })
    );
    if (!(res?.payload as any)?.id) {
      return;
    }
    dispatch(
      UpdateOrderStatusInfo({
        businessId: store?.id,
        orderId: orderData?.id,
        status: order_status.intransit,
      })
    );
  };
  const checkAgencyDetails = async () => {
    const seller_id = store?.seller?.seller_id;
    // console.log(seller_id, 'Api');

    if (seller_id) {
      const seller_details = await getSellerAgency(seller_id);
      // console.log(seller_details, 'seller_details');
      if (seller_details.error) return;
      if (seller_details?.data?.agency.length > 0) {
        setAgencies(seller_details?.data?.agency);
        setAddAgencyModalOpened(true);
      } else {
        dispatch(
          UpdateOrderStatusInfo({
            businessId: store?.id,
            orderId: orderData?.id,
            status: order_status.accepted,
          })
        );
      }
    } else {
      dispatch(
        UpdateOrderStatusInfo({
          businessId: store?.id,
          orderId: orderData?.id,
          status: order_status.accepted,
        })
      );
    }
  };
  const submitAgency = async () => {
    const selectedAgency = agencies.filter((e) => e.id == agencyId)[0];
    // console.log(selectedAgency, 'selectedAgency');
    const updatedOrder = {
      ...orderData,
      agency: {
        agency_id: selectedAgency?.id,
        name: selectedAgency?.name,
      },
    };
    const orderRes: any = await dispatch(
      UpdateOrder({
        businessId: store?.id,
        orderId: orderData?.id,
        orderDetails: updatedOrder,
      })
    );
    // console.log('orderRes', orderRes);
    if (orderRes?.error) {
      setAddAgencyModalOpened(false);
      return;
    }
    dispatch(
      UpdateOrderStatusInfo({
        businessId: store?.id,
        orderId: orderData?.id,
        status: order_status.accepted,
      })
    );
    setAddAgencyModalOpened(false);
  };

  const getWeight = (e) => {
    let val = 0;
    let unit = '';
    if (Number(e?.weight) / 1000 >= 1) {
      val = Number(e?.weight) / 1000;
      switch (e?.product?.product_dimension?.unit) {
        case 'gm':
          unit = 'kg';
          break;
        case 'ml':
          unit = 'L';
          break;
        default:
          break;
      }
    } else {
      val = Number(e?.weight);
      unit = e?.product?.product_dimension?.unit;
    }
    return `${val} ${unit}`;
  };

  const ordersArray = [];
  const formattedRows = (items: any) =>
    items?.map((element, index) => {
      ordersArray.push(element);
      return (
        <tr
          key={index}
          className="border"
          onClick={(e) => console.log(element)}
        >
          <td>
            <tr className="flex items-center">
              <Image
                src={
                  element?.product?.image
                    ? element?.product?.image[0]?.url
                    : 'https://versicles-strapi-cms-media.s3.amazonaws.com/category-def.jpg'
                }
                height={20}
                width={20}
                alt={element?.product?.name}
              />
              <p className="px-2">{element?.product?.name}</p>
            </tr>
            <tr className="flex items-start justify-start">
              <td>
                <p>{element?.comments}</p>
              </td>
            </tr>
          </td>
          {Number(element?.weight) ? (
            <td>{getWeight(element)}</td>
          ) : (
            <td>&#x2715; {element?.quantity}</td>
          )}
          <td>{Number(element?.price).toFixed(2)}</td>
          <td>{Number(element?.price * element?.quantity).toFixed(2)}</td>
        </tr>
      );
    });

  useEffect(() => {
    // console.log(ordersArray);
    setOrderItems(ordersArray);
  }, []);
  const transactionStatus = () => {
    let btnContent = 'Processing';
    let btnColor = '#3f51b5';
    if (
      orderData?.transaction_status.some(
        (obj) => obj.status === 'payment.refund'
      )
    ) {
      btnContent = 'Refund Processed';
      btnColor = '#3f51b5';
    } else if (
      orderData?.transaction_status.some(
        (obj) => obj.status === 'payment.captured'
      )
    ) {
      btnContent = 'Paid';
      btnColor = 'green';
    } else {
      const transaction_status =
        orderData?.transaction_status[orderData?.transaction_status?.length - 1]
          ?.status;
      switch (transaction_status) {
        case 'payment.authorized':
          btnContent = 'Payment Initiated';
          btnColor = 'yellow';
          break;
        case 'payment.captured':
          btnContent = 'Paid';
          btnColor = 'green';
          break;
        case 'payment.refund':
          btnContent = 'Refund Processed';
          btnColor = '#3f51b5';
          break;
        case 'payment.failed':
          btnContent = 'Failed';
          btnColor = 'red';
          break;
        default:
          btnContent = 'Processing';
          btnColor = '#3f51b5';
      }
    }

    return (
      <div
        className="mx-4 px-4 border-2  font-bold border-solid rounded-md"
        style={{ borderColor: btnColor, color: btnColor }}
      >
        {btnContent}
      </div>
    );
  };

  const paymentDetailCard = (
    <Card className="m-2  " shadow="md" p="md" withBorder>
      <div className="flex justify-between items-center pb-4">
        <p className=" text-lg font-semibold">Order Summary</p>
        <div>
          {!orderData?.cash_on_delivery && orderData?.transaction_id ? (
            transactionStatus()
          ) : (
            <div
              className="mx-4 px-4 border-2 border-red-800 rounded-md text-red-800 font-bold"
              // style={{ transform: 'rotate(-6deg)' }}
            >
              COD
            </div>
          )}
        </div>
      </div>

      <div>
        {orderData?.payment_details?.price_split?.subTotal && (
          <div className="flex justify-between">
            <p>Subtotal : </p>
            <p>
              &#x20b9;
              {Number(
                orderData?.payment_details?.price_split?.subTotal
              ).toFixed(2)}
            </p>
          </div>
        )}
        {orderData?.payment_details?.price_split?.convenienceFee && (
          <div className="flex justify-between">
            <p>Convenience Fee : </p>
            <p>
              &#x20b9;
              {Number(
                orderData?.payment_details?.price_split?.convenienceFee
              ).toFixed(2)}
            </p>
          </div>
        )}
        {orderData?.payment_details?.price_split?.transactionFee && (
          <div className="flex justify-between">
            <p>Transaction Fee : </p>
            <p>
              &#x20b9;
              {Number(
                orderData?.payment_details?.price_split?.transactionFee
              ).toFixed(2)}
            </p>
          </div>
        )}
        {orderData?.payment_details?.price_split?.cgst && (
          <div className="flex justify-between">
            <p>CGST (2.5%) : </p>
            <p>
              &#x20b9;
              {Number(orderData?.payment_details?.price_split?.cgst).toFixed(2)}
            </p>
          </div>
        )}
        {orderData?.payment_details?.price_split?.sgst && (
          <div className="flex justify-between">
            <p> SGST (2.5%) : </p>
            <p>
              &#x20b9;
              {Number(orderData?.payment_details?.price_split?.sgst).toFixed(2)}
            </p>
          </div>
        )}
        {orderData?.payment_details?.price_split?.discount && (
          <div className="flex justify-between">
            <p> Promotion applied : </p>
            <p>
              &#x20b9;
              {Number(
                orderData?.payment_details?.price_split?.discount
              ).toFixed(2)}
            </p>
          </div>
        )}
        {orderData?.payment_details?.price_split?.total && (
          <div className="flex justify-between text-lg font-semibold">
            <p>Total : </p>
            <p>
              &#x20b9;
              {Number(orderData?.payment_details?.price_split?.total).toFixed(
                2
              )}
            </p>
          </div>
        )}
      </div>
    </Card>
  );
  const customerDetailCard = (
    <Card className="m-2" shadow="md" p="md" withBorder>
      <p className=" text-lg font-semibold">Customer Details</p>
      <div className="flex flex-col md:flex-row md:justify-between">
        <div className="flex flex-col justify-between">
          <p>Name: {orderData?.customer?.name}</p>
          {orderData?.is_primary_order && (
            <p>Email: {orderData?.customer?.email}</p>
          )}
        </div>
        <div className="flex flex-col justify-between">
          {orderData?.is_primary_order && (
            <p>Phone: {orderData?.customer?.phone}</p>
          )}
          {orderData?.is_primary_order && (
            <p>Address: {orderData?.customer?.address}</p>
          )}
        </div>
      </div>
    </Card>
  );
  const deliveryDetailCard = (
    <Card className="m-2" shadow="md" p="md" withBorder>
      <p className=" text-lg font-semibold">Delivery Details</p>
      <p>
        Pickup From:{' '}
        <span className="font-semibold">
          {orderData?.deliveryTime?.whereToPickup}{' '}
        </span>
      </p>
      <p>
        Pickup Location:{' '}
        <span className="font-semibold">
          {orderData?.deliveryTime?.deliveryAddress?.locationName}
        </span>
        <br />
        <span>{orderData?.deliveryTime?.deliveryAddress?.address}</span>
      </p>
      {orderData?.deliveryTime?.whereToPickup == 'driveUp' &&
        orderData?.deliveryTime?.landmark && (
          <p>
            Landmark:{' '}
            <span className="font-semibold">
              {orderData?.deliveryTime?.landmark}
            </span>
          </p>
        )}
      <p>Delivery Date: {orderData?.deliveryTime?.date}</p>
      <p>Delivery Time: {orderData?.deliveryTime?.time}</p>
      {/* <p>
        Delivery Address: {orderData?.deliveryTime?.deliveryAddress?.address}
      </p> */}

      <p>
        Delivery Mode:{' '}
        <span className="font-semibold">
          {orderData?.deliveryTime?.deliveryMode}
        </span>
      </p>
      <p>
        Payment Mode:{' '}
        <span className="font-semibold">
          {orderData?.cash_on_delivery ? 'COD' : 'ONLINE'}
        </span>
      </p>
      {orderData?.agency?.id && (
        <p>
          Agency:{' '}
          <span className="font-semibold">{orderData?.agency?.name}</span>
        </p>
      )}
    </Card>
  );
  return (
    <div>
      <div className="flex justify-between items-center">
        <div
          className="flex justify-start items-center p-2 cursor-pointer"
          onClick={() => {
            history.push(router.order().$);
          }}
        >
          <ArrowLeftIcon className="w-4 h-4"></ArrowLeftIcon>
          Back
        </div>
        <div className="flex flex-row">
          <button
            className="flex px-5 pr-3 py-2 rounded-lg my-1
            text-sm font-medium "
            onClick={() =>
              dispatch(
                GetOrderById({ businessId: store?.id, orderId: options?.id })
              )
            }
            title="reload"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <button
            className="flex px-5 pr-3 py-2 rounded-lg text-white my-1
            text-sm font-medium bg-white"
            onClick={handlePrint}
            title="Print"
          >
            <PrinterIcon
              className="fill-black"
              style={{
                height: '30px',
                width: '30px',
                fill: '#000',
                border: '1px solid #000',
                padding: '5px',
                borderRadius: '5px',
              }}
            />
          </button>
        </div>
      </div>

      {orderData && !loading && (
        <>
          <div className=" bg-white p-2 flex md:justify-between md:items-center flex-col md:flex-row rounded-md shadow-sm font-semibold mb-2 sticky z-20 top-16 ">
            <div className="flex justify-center items-center">
              <div>
                Order Id <span>#{orderData?.order_id}</span>{' '}
              </div>
              <div className="flex">
                {orderData?.locker_box?.code && (
                  <div className="mx-4">
                    PassCode:{' '}
                    <span className="text-primary-700">
                      {orderData?.locker_box?.code}
                    </span>
                  </div>
                )}
                {orderData?.locker?.locker_box_number && (
                  <div className="mx-4">
                    Locker Number:{' '}
                    <span className="text-primary-700">
                      {orderData?.locker?.locker_box_number}
                    </span>
                  </div>
                )}
                {store?.is_kiosk_business &&
                  !orderData?.locker_box?.code &&
                  status?.step == 5 &&
                  orderData.deliveryTime.whereToPickup != 'driveUp' && (
                    // <div>
                    <Button
                      className="bg-primary-900 hover:bg-primary-900 mx-1 cursor-pointer ml-4"
                      onClick={() => addToLocker()}
                    >
                      Add To Locker
                    </Button>
                    // </div>
                  )}
              </div>
            </div>
            <div className="flex justify-between">
              {store?.is_kiosk_business &&
                status?.step == 4 &&
                !status?.btn_default &&
                !status?.btn_success && (
                  <Button
                    className="bg-primary-900 hover:bg-primary-900 mx-1 cursor-pointer"
                    onClick={() =>
                      dispatch(
                        OrderRefundInfo({
                          businessId: store?.id,
                          orderId: orderData?.id,
                        })
                      )
                    }
                  >
                    Refund
                  </Button>
                )}
              {(store?.is_kiosk_business || status?.step < 5) &&
                status?.btn_default && (
                  <Button
                    className="text-primary-900 mx-1 cursor-pointer"
                    variant="subtle"
                    onClick={() => nextStep(status.btn_default.next)}
                  >
                    {status.btn_default.label}
                  </Button>
                )}
              {(store?.is_kiosk_business || status?.step < 5) &&
                status?.btn_success && (
                  <Button
                    className="bg-primary-900 hover:bg-primary-900 mx-1 cursor-pointer "
                    onClick={() => nextStep(status.btn_success.next)}
                  >
                    {status.btn_success.label}
                  </Button>
                )}
            </div>
          </div>

          {isMobile ? (
            <Tabs>
              <Tabs.Tab label="Order Details">
                {customerDetailCard}
                {formattedOrderItem &&
                  formattedOrderItem.map((e, index) => (
                    <Card
                      className="m-2"
                      shadow="md"
                      p="md"
                      withBorder
                      key={index}
                    >
                      <p className=" text-lg font-semibold">{e.businessName}</p>
                      <Table>
                        <thead>
                          <tr>
                            <th>Items</th>
                            <th>QTY</th>
                            <th>Price</th>
                            <th>Total Price</th>
                          </tr>
                        </thead>
                        <tbody>{formattedRows(e?.items)}</tbody>
                      </Table>
                    </Card>
                  ))}
                <div className="grid gap-2 sm:grid-cols-2">
                  {deliveryDetailCard}
                  {orderData?.total && paymentDetailCard}
                </div>
              </Tabs.Tab>
              <Tabs.Tab label="Timeline">
                <Card className="m-2" shadow="md" p="md" withBorder>
                  <p className=" text-lg font-semibold">Timeline</p>

                  <Timeline
                    active={orderData.timeline.length}
                    lineWidth={3}
                    bulletSize={16}
                  >
                    {orderData.timeline.length > 0 &&
                      orderData.timeline.map((e, index) => (
                        <Timeline.Item
                          title={order_timeLine[e?.status?.name]?.name}
                          key={index}
                        >
                          <Text size="xs" mt={4} className="capitalize">
                            {formatRelative(parseISO(e?.date_time), new Date())}
                          </Text>
                        </Timeline.Item>
                      ))}
                  </Timeline>
                </Card>
              </Tabs.Tab>
            </Tabs>
          ) : (
            <div className="flex">
              <div className="w-2/3">
                {customerDetailCard}
                {formattedOrderItem &&
                  formattedOrderItem.map((e, index) => (
                    <Card
                      className="m-2"
                      shadow="md"
                      p="md"
                      withBorder
                      key={index}
                    >
                      <div className="flex justify-between">
                        <p className=" text-lg font-semibold">
                          {e.businessName}
                        </p>
                        {store?.is_kiosk_business &&
                          order_timeLine[e?.status?.name]?.name && (
                            <p className="mx-4 mr-5 leading-6 px-4 border-2 border-primary-900 rounded-md text-sm font-semibold text-primary-900 ">
                              {order_timeLine[e?.status?.name]?.name}
                            </p>
                          )}
                      </div>
                      <Table>
                        <thead>
                          <tr>
                            <th>Items</th>
                            <th>QTY</th>
                            <th>Price</th>
                            <th>Total Price</th>
                          </tr>
                        </thead>
                        <tbody>{formattedRows(e?.items)}</tbody>
                      </Table>
                    </Card>
                  ))}

                <div className="grid gap-2 sm:grid-cols-2">
                  {deliveryDetailCard}
                  {orderData?.total && paymentDetailCard}
                </div>
              </div>
              <div className="w-1/3">
                <Card className="m-2" shadow="md" p="md" withBorder>
                  <p className=" text-lg font-semibold">Timeline</p>

                  <Timeline
                    active={orderData.timeline.length}
                    lineWidth={3}
                    bulletSize={16}
                  >
                    {orderData.timeline.length > 0 &&
                      orderData.timeline.map((e, index) => (
                        <Timeline.Item
                          title={order_timeLine[e?.status?.name]?.name}
                          key={index}
                        >
                          <Text size="xs" mt={4} className="capitalize">
                            {formatRelative(parseISO(e?.date_time), new Date())}
                          </Text>
                        </Timeline.Item>
                      ))}
                  </Timeline>
                </Card>
              </div>
            </div>
          )}
        </>
      )}
      {loading && (
        <>
          <div className=" p-2 rounded-md shadow-sm font-semibold mb-2 sticky z-20 top-16 flex justify-between items-center">
            <Skeleton height={30} width="150px"></Skeleton>

            <div className="flex">
              <Skeleton height={30} width="100px" className="mr-1"></Skeleton>

              <Skeleton height={30} width="100px"></Skeleton>
            </div>
          </div>

          <div className="flex mx-4">
            <div className="w-2/3">
              <Skeleton height={200} mt={6}></Skeleton>
              <div className="grid gap-2 sm:grid-cols-2">
                <Skeleton height={150} mt={6}></Skeleton>
                <Skeleton height={150} mt={6}></Skeleton>
              </div>
            </div>
            <div className="w-1/3">
              <Skeleton height={260} mt={6} className="ml-4 "></Skeleton>
            </div>
          </div>
        </>
      )}

      <Modal
        opened={isCancelDialogOpened}
        onClose={() => setCancelDialogOpened(false)}
        size="md"
        radius="md"
        transition="slide-up"
        transitionDuration={300}
        transitionTimingFunction="ease"
        withCloseButton={false}
      >
        <CloseButton
          aria-label="Close modal"
          className="absolute"
          style={{ top: '5px', right: '20px' }}
          onClick={() => setCancelDialogOpened(false)}
        />
        <Text
          size="sm"
          style={{ marginBottom: 10 }}
          weight={500}
          align="center"
        >
          Cancel Order
        </Text>
        <div className="flex flex-col justify-center items-center">
          <Text size="sm" weight={100} style={{ marginBottom: 10 }}>
            Once you cancel the order, you can’t process it anymore.
          </Text>
          <Button className="mt-4" color="pink" onClick={() => cancelOrder()}>
            Cancel Order
          </Button>
        </div>
      </Modal>
      <Modal
        opened={isAddToLockerModalOpened}
        onClose={() => setAddToLockerModalOpened(false)}
        size="md"
        radius="md"
        transition="slide-up"
        transitionDuration={300}
        transitionTimingFunction="ease"
        withCloseButton={false}
      >
        <CloseButton
          aria-label="Close modal"
          className="absolute"
          style={{ top: '5px', right: '20px' }}
          onClick={() => setAddToLockerModalOpened(false)}
        />
        <Text
          size="sm"
          style={{ marginBottom: 10 }}
          weight={500}
          align="center"
        >
          Locker Details
        </Text>

        <div className="flex flex-col justify-center items-center">
          <Stack spacing={10} mt={5}>
            <Group className="">
              <Text size="md" weight={100}>
                Locker Number :{' '}
              </Text>
              {/* <TextInput
                radius="md"
                className="productForm w-16"
                value={boxId}
                onChange={(event) => setBoxId(event.currentTarget.value)}
              /> */}
              <Select
                data={lockerBoxes.map((e) => ({
                  value: '' + e.id,
                  label: e.number,
                }))}
                value={boxId}
                onChange={(e) => setBoxId(e)}
              />
            </Group>
          </Stack>
          <Button
            className="mt-4"
            color="pink"
            onClick={() => addLockerDetails()}
          >
            Submit
          </Button>
        </div>
      </Modal>
      <Modal
        opened={isAddAgencyModalOpened}
        onClose={() => setAddAgencyModalOpened(false)}
        size="md"
        radius="md"
        transition="slide-up"
        transitionDuration={300}
        transitionTimingFunction="ease"
        withCloseButton={false}
      >
        <CloseButton
          aria-label="Close modal"
          className="absolute"
          style={{ top: '5px', right: '20px' }}
          onClick={() => setAddAgencyModalOpened(false)}
        />
        <Text
          size="sm"
          style={{ marginBottom: 10 }}
          weight={500}
          align="center"
        >
          Choose Your Agency For Delivery
        </Text>

        <div className="flex flex-col justify-center items-center">
          <Stack spacing={10} mt={5}>
            <Group className="">
              <Text size="md" weight={100}>
                Agency :{' '}
              </Text>
              <Select
                data={agencies.map((e) => ({
                  value: '' + e.id,
                  label: e.name,
                }))}
                value={agencyId}
                onChange={(e) => setAgencyId(e)}
              />
            </Group>
          </Stack>
          <Button className="mt-4" color="pink" onClick={() => submitAgency()}>
            Submit
          </Button>
        </div>
      </Modal>

      <BillForPrint
        ref={componentRef}
        ordersArray={ordersArray}
        orderData={orderData}
      />
      {/* <BillForPrint  {...ordersArray}  /> */}
    </div>
  );
}

export default OrderDetails;
