import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, Image, Text, Badge, Button, Group } from '@mantine/core';
import { Link } from 'react-typesafe-routes';
import { router } from '../routes';
function Categories(props: any) {
  const category = [
    {
      image:
        'https://formfacade.com/uploaded/1FAIpQLSeaeZEzyP3aqIGIBnjJj3gQJGVaYkIzq0zrCAndagPImApzeg/820077713/prdimage/Assorted%20baby%20Carrots.png',
      name: 'Grocery',
    },
    {
      image:
        'https://formfacade.com/uploaded/1FAIpQLSe15qH7QHKzIfE43dF5-9GrlO1j3nCmJpZdJxgBkB8zxOMHMQ/1622838293/prdimage/Lemon%20Juice.jpeg',
      name: 'Restaurant',
    },
    {
      image:
        'https://formfacade.com/uploaded/1FAIpQLSdQuslegLtcFqfEFsmAQf04DksiO6kAJ_8JmkVSZXq3x1AjkQ/499567162/prdimage/Black%20Round%20Neck%20T-Shirt.png',
      name: 'Clothing',
    },
  ];
  return (
    <div className="p-2">
      <Grid gutter="xs">
        {category.map((e) => (
          <Grid.Col span={6} xs={6} sm={2}>
            <Link to={router.catalogue().category()}>
              <Card shadow="md" p="sm" withBorder>
                <Card.Section>
                  <div
                    style={{
                      // width: 240,
                      padding: '5px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <Image
                      radius="md"
                      fit="contain"
                      height={150}
                      src={e.image}
                      alt={e.name}
                      // caption={e.name}
                    />
                  </div>
                </Card.Section>
                <Text weight={500} align="center" className="mt-2">
                  {e.name}
                </Text>
              </Card>
            </Link>
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
}

Categories.propTypes = {};

export default Categories;
