import React, { useEffect, useRef, useState } from 'react';
import { PencilAltIcon, PhotographIcon } from '@heroicons/react/solid';
import {
  Box,
  Button,
  Card,
  Group,
  Image,
  NumberInput,
  Select,
  Table,
  Text,
  TextInput,
} from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { StoreInputContainer } from './gettingStart';
import { UpdateStore } from '../store/stores/stores.thunk';
import { UploadImage } from '@adv/sdk/stores';
import { showNotification, updateNotification } from '@mantine/notifications';
import { LoadingState } from '@adv/sdk/common';
import { useHistory } from 'react-router-dom';
import { router } from '../routes';
import { storeActions } from '../store/stores/stores.slice';
interface StoreInfo {
  name: string;
  address: string;
  category: string;
  country: string;
  email: string;
  phone: string;
  logo: string;
  closed_for_order: boolean;
  storeTiming: any;
  store_opens_Type: string;
  store_opens_in_hours: string;
  costForTwo: number;
  deliveryTime: any;
}

enum day {
  sunday = 'Sunday',
  monday = 'Monday',
  tuesday = 'Tuesday',
  wednesday = 'Wednesday',
  thursday = 'Thursday',
  friday = 'Friday',
  saturday = 'Saturday',
}

const defaultStoreTiming = [
  {
    day: 'sunday',
    online: false,
    opening: '9:00 AM',
    closing: '5:00 PM',
  },
  {
    day: 'monday',
    online: true,
    opening: '9:00 AM',
    closing: '5:00 PM',
  },
  {
    day: 'tuesday',
    online: true,
    opening: '9:00 AM',
    closing: '5:00 PM',
  },
  {
    day: 'wednesday',
    online: true,
    opening: '9:00 AM',
    closing: '5:00 PM',
  },
  {
    day: 'thursday',
    online: true,
    opening: '9:00 AM',
    closing: '5:00 PM',
  },
  {
    day: 'friday',
    online: true,
    opening: '9:00 AM',
    closing: '5:00 PM',
  },
  {
    day: 'saturday',
    online: false,
    opening: '9:00 AM',
    closing: '5:00 PM',
  },
];

const store_opens_Type = [
  { label: 'Tomorrow', value: '1' },
  { label: 'Until I turn it ON', value: '2' },
  { label: 'hours', value: '3' },
];

// landing_page: {image_carousel: {title: 'Zam Zam1', caption: 'test2'}}
function ImageUploadIcon({ status, ...props }: any) {
  if (status.accepted) {
    return <PhotographIcon {...props} />;
  }
  if (status.rejected) {
    return <PhotographIcon {...props} />;
  }
  return <PhotographIcon {...props} />;
}
export const dropzoneChildren = (status: any) => (
  <div></div>
  // <Group
  //   position="center"
  //   spacing="xl"
  //   style={{ minHeight: 100, pointerEvents: 'none' }}
  // >
  //   <ImageUploadIcon
  //     status={status}
  //     style={{ color: '#ced4da', width: '50px', height: '50px' }}
  //   />

  //   <div>
  //     <Text size="md" inline>
  //       Drag your logo here or{' '}
  //       <span className="text-primary-700">click to select files</span>
  //     </Text>
  //   </div>
  // </Group>
);

function StoreForm() {
  const dispatch = useAppDispatch();
  const methods = useForm<StoreInfo>({
    defaultValues: {
      address: '',
      category: '',
      closed_for_order: false,
      email: '',
      logo: '',
      name: '',
      phone: '',
      storeTiming: [],
      store_opens_Type: '',
      store_opens_in_hours: '',
      deliveryTime: null,
      costForTwo: null,
    },
  });
  const dropzoneRef = useRef<() => void>();
  const store = useAppSelector((state) => state.store.store);
  const updateStatus = useAppSelector((state) => state.store.updateStatus);
  const isInitialSetup = useAppSelector((state) => state.store.isInitialSetup);
  const [isShowNotification, setIsShowNotification] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [deliveryTimes, setDeliveryTimes] = useState([]);
  const [storeTiming, setStoreTiming] = useState([]);
  const [hours, setHours] = useState([]);
  const categoryList = useAppSelector(
    (state: any) => state.inventory.inventory
  );
  const history = useHistory();

  const getAvailableTimes = () => {
    const x = 30; //minutes interval
    const times = []; // time array
    const startTime = 0;
    const endTime = 24;
    let st = startTime * 60; // start time
    const et = endTime * 60;
    const ap = ['AM', 'PM']; // AM-PM
    //loop to increment the time and push results in array
    for (let i = 0; st <= et; i++) {
      const hh = Math.floor(st / 60); // getting hours of day in 0-24 format
      const mm = st % 60; // getting minutes of the hour in 0-55 format
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (ap[Math.floor(hh / 12)]) {
        times[i] = {
          hour: '' + (hh == 12 ? 12 : hh % 12) + ':' + ('0' + mm).slice(-2),
          period: ap[Math.floor(hh / 12)],
        };
      }
      // pushing data in array in [00:00 - 12:00 AM/PM format]
      st = st + x;
    }
    const formatedTime = times.length > 0 ? times : [];
    const availableTimes = formatedTime.map((e) => ({
      ...e,
      label: `${e.hour} ${e.period}`,
      value: `${e.hour} ${e.period}`,
    }));
    // console.log(availableTimes, 'availableTimes');

    return availableTimes;
  };

  const updateAvailableTiming = (online, day) => {
    const prevStoreTiming = [...storeTiming];
    const objIndex = storeTiming.findIndex((obj) => obj.day == day);
    const updatedValue = {
      ...storeTiming[objIndex],
      online,
    };
    prevStoreTiming[objIndex] = updatedValue;
    const updatedTiming = prevStoreTiming;
    setStoreTiming((prev) => {
      return [...updatedTiming];
    });
  };

  const setInitialStoreTiming = (storeTiming, opens_in_hours?) => {
    storeTiming?.map((e, index) => {
      methods.setValue(`storeTiming.${index}.day`, e.day);
      methods.setValue(`storeTiming.${index}.online`, e.online);
      methods.setValue(`storeTiming.${index}.opening`, e.opening);
      methods.setValue(`storeTiming.${index}.closing`, e.closing);
    });
    let startTime = 1;
    const hoursList = [];
    for (let i = 0; startTime < 24; i++) {
      const tt = ('0' + startTime).slice(-2);
      hoursList.push({ value: tt.toString(), label: tt.toString() });
      startTime += 1;
    }
    setHours(hoursList);
    const store_opens_in_hours = opens_in_hours
      ? opens_in_hours
      : hoursList[0].value;
    methods.setValue('store_opens_in_hours', store_opens_in_hours);
  };

  const rows = () => {
    return storeTiming?.map((e, index) => (
      <tr key={index}>
        <td> {day[e.day]}</td>
        <td className="">
          <div className="flex">
            <input
              {...methods.register(`storeTiming.${index}.online`)}
              className="toggle_switch  cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              // checked={e.status}
              onChange={(event) => {
                updateAvailableTiming(event.currentTarget.checked, e.day);
              }}
            />
            <span className="mx-2">{e.online ? 'Open' : 'Closed'}</span>
          </div>
        </td>
        {e.online ? (
          <>
            <td className="">
              {' '}
              <Controller
                name={`storeTiming.${index}.opening`}
                control={methods.control}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="Pick one"
                    nothingFound="No options"
                    maxDropdownHeight={180}
                    data={availableTimes}

                    // value={e.opening}
                  />
                )}
              />
            </td>
            <td>TO</td>
            <td>
              {' '}
              <Controller
                name={`storeTiming.${index}.closing`}
                control={methods.control}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="Pick one"
                    searchable
                    nothingFound="No options"
                    maxDropdownHeight={180}
                    data={availableTimes}
                    // value={e.closing}
                  />
                )}
              />
            </td>
          </>
        ) : (
          ''
        )}
      </tr>
    ));
  };
  const onSubmit: SubmitHandler<StoreInfo> = (data) => {
    const selectedBusiness_category = categoryList
      ?.filter((e) => e.value == data?.category)
      .map((e) => ({ id: e.value, name: e.label }))[0];
    const logo = uploadImages ? uploadImages.map((e) => e.id) : [];
    const formattedValue = {
      name: data.name,
      email: data.email,
      address: data.address,
      phone: data.phone,
      business_category: +data.category,
      theme: store?.theme?.id,
      logo: logo,
      closed_for_order: data?.closed_for_order,
      opening_hours: data?.storeTiming,
      store_opens_Type: data?.store_opens_Type,
      store_opens_in_hours: data?.store_opens_in_hours,
      delivery_time: {
        max_time: data?.deliveryTime?.max_time,
        min_time: data?.deliveryTime?.min_time,
        time_in: data?.deliveryTime?.time_in,
      },
    };
    // console.log({ ...store, ...formattedValue, storeId: store?.id });
    dispatch(UpdateStore({ ...store, ...formattedValue }));
  };

  useEffect(() => {
    if (updateStatus === LoadingState.loading) {
      showNotification({
        id: 'load-data',
        loading: true,
        title: 'Saving your Store',
        message: 'Saving your Store Information.',
        autoClose: false,
        disallowClose: true,
      });
      setIsShowNotification(true);
    }
    if (updateStatus === LoadingState.loaded) {
      updateNotification({
        id: 'load-data',
        color: 'teal',
        title: 'Store Updated Successfully!',
        message: 'Store Updated Successfully!',
        autoClose: 2000,
      });
      if (isShowNotification) {
        setTimeout(() => {
          if (isInitialSetup) history.push(router.home().$);
          dispatch(storeActions.InitialStoreSetupComplete());
        }, 2000);
      }
      setIsShowNotification(false);
    }
    if (updateStatus === LoadingState.error) {
      updateNotification({
        id: 'load-data',
        color: 'red',
        title: 'Store Update failed!',
        message: 'Store Update failed!',
        autoClose: 2000,
      });
      setIsShowNotification(false);
    }
  }, [updateStatus]);

  useEffect(() => {
    console.log(store);
    methods.setValue('name', store?.name);
    methods.setValue('category', String(store?.business_categories[0]?.id));
    methods.setValue('email', store?.email);
    methods.setValue('phone', store?.phone);
    methods.setValue('address', store?.address);
    methods.setValue('logo', store?.logo?.id);
    methods.setValue('closed_for_order', store?.closed_for_order || false);
    methods.setValue(
      'store_opens_Type',
      store?.store_opens_Type || store_opens_Type[0].value
    );
    if (store?.logo) setUploadImages([store?.logo]);
    setChecked(store?.closed_for_order || false);
    setAvailableTimes(getAvailableTimes());
    setStoreTiming(store?.opening_hours || defaultStoreTiming);
    setInitialStoreTiming(
      store?.opening_hours || defaultStoreTiming,
      store?.store_opens_in_hours
    );
    methods.setValue(
      `deliveryTime.time_in`,
      store?.delivery_time?.time_in || 'minute'
    );
    methods.setValue(`deliveryTime.min_time`, store?.delivery_time?.min_time);
    methods.setValue(`deliveryTime.max_time`, store?.delivery_time?.max_time);
  }, []);
  const [uploadImages, setUploadImages] = useState([]);
  const setImage = async (files) => {
    setImageLoading(true);
    const { data, status, error } = await UploadImage(files);
    if (data && data?.length > 0) {
      methods.setValue('logo', data[0]?.id);
      setUploadImages([{ id: data[0]?.id, url: data[0]?.url }]);
    }
    setImageLoading(false);
  };
  return (
    <div className=" productForm m-auto mt-4 w-10/12 relative">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className=" bg-gray-100 p-2 rounded-md shadow-sm font-semibold mb-2 sticky z-20 top-16 flex justify-between items-center">
            Store Information
            <Button
              className="bg-primary-900 hover:bg-primary-900"
              type="submit"
              disabled={imageLoading}
            >
              {' '}
              {isInitialSetup ? 'Complete your store setup' : 'Save'}
            </Button>
          </div>

          <StoreInputContainer type="row" />
          <div className="grid sm:grid-cols-2 gap-4">
            <div>
              <Controller
                name="email"
                control={methods.control}
                render={({ field }) => (
                  <TextInput
                    label="Email Address"
                    placeholder="Email Address"
                    {...field}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="phone"
                control={methods.control}
                render={({ field }) => (
                  <TextInput
                    label="Mobile Number"
                    placeholder="Mobile Number"
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <Controller
            name="address"
            control={methods.control}
            render={({ field }) => (
              <TextInput
                label="Store Address"
                placeholder="Store Address"
                {...field}
              />
            )}
          />
          <div className="grid sm:grid-cols-3 gap-4 py-2">
            <div className="col-span-2">
              {/* <Controller
                name="deliveryTime"
                control={methods.control}
                render={({ field }) => (
                  <TextInput
                    label="delivery Time"
                    placeholder="delivery Time"
                    {...field}
                  />
                )}
              /> */}
              <h1
                className="flex items-center font-medium"
                style={{ fontSize: '14px', marginBottom: '7px' }}
              >
                Delivery Time
              </h1>
              <tr>
                <td className="">
                  {' '}
                  <Controller
                    name={`deliveryTime.min_time`}
                    control={methods.control}
                    render={({ field }) => (
                      <NumberInput
                        hideControls
                        // label="mintime"
                        placeholder="mintime"
                        {...field}
                      />
                    )}
                  />
                </td>
                <td className="px-3">TO</td>
                {/* <td className="relative w-10 h-10 ">
                  <div
                    className="absolute"
                    style={{
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, 0)',
                    }}
                  >
                    TO
                  </div>
                </td> */}
                <td>
                  {' '}
                  <Controller
                    name={`deliveryTime.max_time`}
                    control={methods.control}
                    render={({ field }) => (
                      <NumberInput
                        hideControls
                        // label="max time"
                        placeholder="max time"
                        {...field}
                      />
                    )}
                  />
                </td>
                <td>
                  <Controller
                    name={`deliveryTime.time_in`}
                    control={methods.control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        // label="time"
                        placeholder="Pick one"
                        maxDropdownHeight={180}
                        className="ml-2"
                        data={[
                          { value: 'minute', label: 'minute' },
                          { value: 'hour', label: 'hour' },
                          { value: 'day', label: 'day' },
                        ]}
                      />
                    )}
                  />
                </td>
              </tr>
            </div>
            <div className="flex items-end justify-end">
              <Controller
                name="costForTwo"
                control={methods.control}
                render={({ field }) => (
                  <NumberInput
                    className="w-full"
                    hideControls
                    label="Cost For Two"
                    placeholder="cost For Two"
                    precision={2}
                    parser={(value) => value.replace(/₹\s?|(,*)/g, '')}
                    formatter={(value) =>
                      !Number.isNaN(parseFloat(value))
                        ? `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : '₹ '
                    }
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className=" bg-gray-100 p-2 rounded-md shadow-sm font-semibold my-2 mt-4">
            <div className="flex justify-between w-full my-2">
              <label
                className="font-medium inline-block text-gray-800"
                htmlFor="flexSwitchCheckDefault"
              >
                Close Order
              </label>
              <input
                {...methods.register('closed_for_order')}
                className="toggle_switch cursor-pointer"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                onChange={(event) => {
                  setChecked(event.currentTarget.checked);
                }}
              />
            </div>
          </div>
          {checked && (
            <div className="flex flex-col py-2">
              <div className="py-1 font-medium text-sm">Go online</div>
              <div className="flex gap-3  py-2">
                {store_opens_Type.map((type, index) => {
                  return (
                    <label
                      htmlFor={type.label}
                      className=" flex items-center text-sm font-medium text-gray-700 cursor-pointer"
                      onClick={() => {
                        methods.setValue('store_opens_Type', type.value);
                      }}
                    >
                      <input
                        {...methods.register('store_opens_Type')}
                        id={`store_opens_Type-${index}`}
                        name="store_opens_Type"
                        type="radio"
                        value={type.value}
                        // checked={value == type.value}
                        onChange={(e) => {
                          return {};
                        }}
                        className="focus:ring-primary-700 h-4 w-4 mr-2 text-primary-700 border-gray-300 cursor-pointer"
                      />
                      {type.value !== '3' ? (
                        <span>{type.label}</span>
                      ) : (
                        <div className="flex flex-row justify-center items-center cursor-pointer">
                          <span className="mr-2">After</span>
                          <Controller
                            control={methods.control}
                            name="store_opens_in_hours"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <>
                                <Select
                                  className="w-20"
                                  data={hours}
                                  variant="default"
                                  placeholder="hours"
                                  onChange={onChange}
                                  value={value}
                                  maxDropdownHeight={180}
                                />{' '}
                              </>
                            )}
                          />
                          <span className="mx-2">{type.label}</span>
                        </div>
                      )}
                    </label>
                  );
                })}
              </div>
            </div>
          )}
          <div className=" bg-gray-100 p-2 rounded-md shadow-sm font-semibold my-2">
            Store Timing
          </div>
          {storeTiming.length > 0 ? (
            <Table>
              <tbody>{rows()}</tbody>
            </Table>
          ) : (
            ''
          )}
          <Controller
            name="logo"
            control={methods.control}
            render={({ field }) => (
              <div className="mt-4">
                <p className="mb-2 font-medium">Logo</p>
                <div className="w-32">
                  <Card shadow="md" p="sm" withBorder>
                    <Card.Section>
                      <div
                        className="flex justify-end cursor-pointer p-1"
                        onClick={(e) => dropzoneRef.current()}
                      >
                        <PencilAltIcon className="w-5 h-5"></PencilAltIcon>
                      </div>
                    </Card.Section>
                    <Image
                      src={
                        uploadImages && uploadImages.length > 0
                          ? uploadImages[0]?.url
                          : 'https://versicles-strapi-cms-media.s3.amazonaws.com/store-def.jpg'
                      }
                      className="w-24"
                      alt={store?.name}
                      fit="contain"
                    />
                  </Card>
                </div>
                <Dropzone
                  className="hidden"
                  openRef={dropzoneRef}
                  onDrop={(files) => setImage(files)}
                  onReject={(files) => console.log('rejected files', files)}
                  maxSize={3 * 1024 ** 2}
                  accept={IMAGE_MIME_TYPE}
                  // {...field}
                >
                  {(status) => dropzoneChildren(status)}
                </Dropzone>
              </div>
            )}
          />

          {/* <Group position="right" mt="xl">
            <Button
              className="bg-primary-900 hover:bg-primary-900"
              type="submit"
              disabled={imageLoading}
            >
              {' '}
              {isInitialSetup ? 'Complete your store setup' : 'Save'}
            </Button>
          </Group> */}
        </form>
      </FormProvider>
    </div>
  );
}

export default StoreForm;
