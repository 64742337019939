import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AdvButton, EyeClosed, EyeOpened } from '@adv/ui';
import { Link, useRouteParams } from 'react-typesafe-routes';
import { router } from '../routes';
import {
  Card,
  Image,
  Text,
  Button,
  Group,
  Stack,
  Grid,
  Drawer,
  Table,
  Menu,
  TextInput,
  Modal,
  CloseButton,
  Skeleton,
} from '@mantine/core';
import { UploadIcon, DotsVerticalIcon } from '@heroicons/react/outline';
import PublishForm from './publishForm';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useHistory } from 'react-router-dom';
import { inventoryActions } from '../store/inventory/inventory.slice';
import {
  ArrowLeftIcon,
  SearchIcon,
  ViewGridIcon,
  ViewListIcon,
  LightningBoltIcon,
  XIcon,
} from '@heroicons/react/solid';
import { useMediaQuery } from '@mantine/hooks';
import {
  DeleteProduct,
  UpdateProduct,
} from '../store/inventory/inventory.thunk';
import { LoadingState } from '@adv/sdk/common';

function Product(props: any) {
  const options = useRouteParams(router.catalogue().categoryByProducts);
  const productList = useAppSelector((state: any) => state.inventory.product);
  const category = useAppSelector((state: any) => state.inventory.category);
  const loadingStatus = useAppSelector(
    (state) => state.inventory.loadingStatus
  );

  const dispatch = useAppDispatch();
  const history = useHistory();
  const [opened, setOpened] = useState(false);
  const [checked, setChecked] = useState(false);
  const [view, setView] = useState('list');
  const isMobile = useMediaQuery('(max-width: 700px)');
  const [q, setQ] = useState('');
  const [mobileSearch, setMobileSearch] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const ads = useAppSelector((state: any) => state.inventory.adsCart);
  const store = useAppSelector((state: any) => state.store.store);
  const [boost, setBoost] = useState(null);
  const [isBoostModalOpened, setBoostModalOpened] = useState(false);
  const [products, setProducts] = useState(productList);
  const rowItems = Array(10).fill(null);

  useEffect(() => {
    if (isMobile) {
      setView('grid');
    }
  }, [isMobile]);
  useEffect(() => {
    if (options?.id) {
      const selectedProduct = category.filter((e) => e.id == options.id)[0]
        .products;
      setProducts(selectedProduct);
    }
  }, [options]);
  useEffect(() => {
    if (!options?.id) {
      setProducts(productList);
    }
  }, [productList]);

  const boostItem = (item: any) => {
    const updatedBoost = item?.boost + 1;
    dispatch(
      UpdateProduct({
        ...item,
        boost: updatedBoost,
        id: item?.id,
        storeId: store?.id,
      })
    );
  };
  const handleActive = (item: any) => {
    const active = item?.active;
    dispatch(
      UpdateProduct({
        ...item,
        active: !active,
        id: item?.id,
        storeId: store?.id,
      })
    );
  };
  const handleClear = () => {
    setQ('');
  };
  const rows = search(products)?.map((element, index) => (
    <tr
      key={index}
      className={`border cursor-pointer ${
        element?.active ? 'hover:bg-gray-100' : 'bg-gray-300'
      }`}
    >
      <td onClick={(e) => dispatch(inventoryActions.AddToAds(element))}>
        <input
          type="checkbox"
          className="text-primary-900 focus:ring-0  border border-black cursor-pointer"
          value={element?.id}
          onChange={() => console.log(element?.id)}
          checked={ads.some((e) => e.id === element?.id)}
        />
      </td>
      <td onClick={(e) => dispatch(inventoryActions.AddToAds(element))}>
        <div className="flex  items-center">
          <Image
            src={
              element?.image
                ? element?.image[0]?.url
                : 'https://versicles-strapi-cms-media.s3.amazonaws.com/category-def.jpg'
            }
            height={50}
            width={50}
            alt={element.name}
          />
          <p className="px-2">{element.name}</p>
        </div>
      </td>
      <td onClick={(e) => dispatch(inventoryActions.AddToAds(element))}>
        {element?.product_categories?.[0]?.name}
      </td>
      <td onClick={(e) => dispatch(inventoryActions.AddToAds(element))}>
        {element.price}
      </td>
      <td>
        <div className="flex flex-row justify-end items-center">
          <Button
            className="bg-primary-900 mr-4"
            leftIcon={<LightningBoltIcon className="text-white h-5 w-5" />}
            onClick={() => boostItem(element)}
          >
            Boost
          </Button>
          {element?.active ? (
            <span className="mx-8" onClick={() => handleActive(element)}>
              <EyeClosed />
            </span>
          ) : (
            <span className="mx-8" onClick={() => handleActive(element)}>
              <EyeOpened />
            </span>
          )}
          {/* <div
            className="flex justify-end items-center"
            onClick={() => boostItem(element)}
          >
            <LightningBoltIcon className="text-primary-700 h-7 w-6 pr-1"></LightningBoltIcon>
          </div> */}

          <div className="flex justify-end items-center">
            <Menu
              control={
                <DotsVerticalIcon className="h-5 w-5"></DotsVerticalIcon>
              }
            >
              <div>
                <Menu.Item
                  onClick={() => {
                    history.push(
                      router.catalogue().updateProduct({ id: element.id }).$
                    );
                  }}
                >
                  <p className="p-2 hover:bg-gray-100 cursor-pointer">Edit</p>
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    dispatch(inventoryActions.productDelete(element));
                    dispatch(
                      DeleteProduct({
                        ...element,
                        id: element?.id,
                        storeId: store?.id,
                      })
                    );
                  }}
                >
                  <p className="p-2 hover:bg-gray-100 cursor-pointer">Delete</p>
                </Menu.Item>
              </div>
            </Menu>
          </div>
        </div>
      </td>
    </tr>
  ));
  function search(items) {
    return items?.filter((item) => {
      return item.name.toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
    });
  }

  const formSubmit = () => {
    setOpened(false);
    dispatch(inventoryActions.RemoveAds());
  };

  return (
    <>
      <div className="h-full">
        {options.id && (
          <div
            className="flex justify-start items-center p-2 cursor-pointer"
            onClick={() => {
              history.push(router.catalogue().category().$);
            }}
          >
            <ArrowLeftIcon className="w-4 h-4"></ArrowLeftIcon>
            Back
          </div>
        )}
        {mobileSearch ? (
          <div className="flex items-center relative">
            <div
              className="flex justify-start items-center p-2 cursor-pointer"
              onClick={() => {
                setMobileSearch(false);
              }}
            >
              <ArrowLeftIcon className="w-4 h-4"></ArrowLeftIcon>
            </div>
            <input
              placeholder="Search"
              className="ml-2 border border-gray-200 rounded-md text-md w-10/12 h-9 pl-2 my-2 outline-none"
              onChange={(ev) => setQ(ev.target.value)}
              value={q}
            />
            {q && (
              <XIcon
                className="h-6 absolute right-8 top-3 text-gray-500 cursor-pointer"
                onClick={handleClear}
              />
            )}
          </div>
        ) : (
          <div className="flex flex-row items-end justify-end m-2 ">
            {isMobile ? (
              <div
                className="p-2 bg-gray-100 rounded-md mx-2"
                onClick={() => setMobileSearch(true)}
              >
                <SearchIcon className="w-5 h-5 text-primary-900"></SearchIcon>
              </div>
            ) : (
              <div className="relative">
                <input
                  placeholder="Search"
                  className="ml-2 border border-gray-200 rounded-md text-md w-56 h-9 pl-2 outline-none"
                  onChange={(ev) => setQ(ev.target.value)}
                  value={q}
                />
                {q ? (
                  <XIcon
                    className="h-6 absolute right-1 top-1.5 text-gray-500 cursor-pointer"
                    onClick={handleClear}
                  />
                ) : (
                  <SearchIcon className="h-6 absolute right-1 top-1.5 text-gray-500" />
                )}
              </div>
            )}
            <div
              className="p-2 bg-gray-100 rounded-md mx-2 cursor-pointer"
              onClick={() => setView('list')}
            >
              <ViewListIcon className="w-5 h-5 text-primary-900"></ViewListIcon>
            </div>
            <div
              className="p-2 bg-gray-100 rounded-md mx-2 cursor-pointer"
              onClick={() => setView('grid')}
            >
              <ViewGridIcon className="w-5 h-5 text-primary-900"></ViewGridIcon>
            </div>
            <div
              className="p-2 bg-gray-100 rounded-md mx-2 cursor-pointer"
              onClick={() => setOpened(true)}
            >
              <UploadIcon className="w-5 h-5 text-primary-900"></UploadIcon>
            </div>

            {/* Add Product */}
            <Link to={router.catalogue().addProduct()}>
              <AdvButton
                color="yellow"
                type="submit"
                className="text-white w-28"
                // onClick={() => setOpened(true)}
              >
                Add Product
              </AdvButton>
            </Link>
          </div>
        )}

        <div className="p-2">
          {view === 'grid' && (
            <Grid gutter="xs">
              {search(products).map((e: any, index) => (
                <Grid.Col span={6} xs={6} sm={3} key={index}>
                  <Card
                    className="h-80 flex flex-col"
                    shadow="md"
                    p="md"
                    withBorder
                  >
                    <Card.Section>
                      <Menu
                        className="absolute z-10 right-1 top-1"
                        control={
                          <DotsVerticalIcon className="h-5 w-5"></DotsVerticalIcon>
                        }
                      >
                        <div>
                          <p
                            className="p-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              history.push(
                                router.catalogue().updateProduct({ id: e.id }).$
                              );
                            }}
                          >
                            Edit
                          </p>
                          <p
                            className="p-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              console.log(e);

                              // dispatch(inventoryActions.productDelete(e));
                              dispatch(
                                DeleteProduct({
                                  ...e,
                                  id: e?.id,
                                  storeId: store?.id,
                                })
                              );
                            }}
                          >
                            Delete
                          </p>
                        </div>
                      </Menu>
                      <Image
                        src={
                          e?.image
                            ? e?.image[0]?.url
                            : 'https://versicles-strapi-cms-media.s3.amazonaws.com/category-def.jpg'
                        }
                        height={160}
                        alt={e.name}
                      />
                    </Card.Section>

                    <Group position="apart" style={{ marginBottom: 5 }}>
                      <Text
                        weight={500}
                        className="text-base sm:text-xl"
                        lineClamp={2}
                      >
                        {e.name}
                      </Text>
                    </Group>
                    <Text
                      size="sm"
                      style={{
                        color: '#000',
                        lineHeight: 1.5,
                        marginBottom: 5,
                      }}
                      lineClamp={1}
                    >
                      {e.description}
                    </Text>
                    <Stack
                      spacing="xs"
                      align="flex-start"
                      justify="flex-start"
                      style={{ marginBottom: 5 }}
                    >
                      <Text className="font-bold">{e.price}</Text>
                      {/* <Text>
                      DiscountedPrice:<span>{e.discount}</span>
                    </Text> */}
                    </Stack>
                    {/* <Button
                      variant="light"
                      color="blue"
                      fullWidth
                      style={{ marginTop: 14 }}
                      onClick={() => setOpened(true)}
                    >
                      Publish
                    </Button> */}
                    <div className="flex justify-between  flex-grow items-end">
                      <Button
                        className="bg-primary-900 mr-4"
                        leftIcon={
                          <LightningBoltIcon className="text-white h-5 w-5" />
                        }
                        onClick={() => boostItem(e)}
                      >
                        Boost
                      </Button>
                      <div className="flex items-center justify-center h-9">
                        {e?.active ? (
                          <span
                            className="cursor-pointer"
                            onClick={() => handleActive(e)}
                          >
                            <EyeClosed />
                          </span>
                        ) : (
                          <span
                            className="cursor-pointer"
                            onClick={() => handleActive(e)}
                          >
                            <EyeOpened />
                          </span>
                        )}
                      </div>
                    </div>
                  </Card>
                </Grid.Col>
              ))}
            </Grid>
          )}
          {view === 'list' && products.length > 0 && (
            <Table>
              <thead>
                <tr>
                  <th className="w-10"></th>
                  <th className="w-1/4">Product</th>
                  <th className="w-1/4">Category</th>
                  <th>Price</th>
                  <th></th>
                </tr>
              </thead>
              {loadingStatus !== LoadingState.loading ? (
                <tbody>{rows}</tbody>
              ) : (
                <tbody>
                  {rowItems.map((_, index) => (
                    <tr key={index}>
                      <td colSpan={5}>
                        <Skeleton height={70} width="100%"></Skeleton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
          )}
        </div>
      </div>
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        title="Publish"
        padding="xl"
        size="xl"
        position="right"
      >
        <PublishForm submitForm={(e) => formSubmit()}></PublishForm>
      </Drawer>
      <Modal
        opened={isBoostModalOpened}
        onClose={() => setBoostModalOpened(false)}
        size="md"
        radius="md"
        transition="slide-up"
        transitionDuration={300}
        transitionTimingFunction="ease"
        withCloseButton={false}
      >
        <CloseButton
          aria-label="Close modal"
          className="absolute"
          style={{ top: '5px', right: '20px' }}
          onClick={() => setBoostModalOpened(false)}
        />
        <Text
          size="sm"
          style={{ marginBottom: 10 }}
          weight={500}
          align="center"
        >
          Boost Product
        </Text>

        <div className="flex flex-col justify-center items-center">
          <Stack spacing={10} mt={5}>
            <Group>
              <Text size="md" weight={100}>
                boost :{' '}
              </Text>
              <TextInput
                radius="md"
                className="productForm w-16"
                value={boost}
                onChange={(event) => setBoost(event.currentTarget.value)}
              />
            </Group>
          </Stack>
          <Button
            className="mt-4"
            color="pink"
            onClick={() => console.log('submit')}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
}

// Product.propTypes = {
//   formValue: {
//     name: string;
//     description: string;
//     msrp: number;
//     discount: number;
//     sku: string;
//     image: string;
//     industry: string;
// }
// };

export default Product;
