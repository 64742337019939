import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import {
  NumberInput,
  TextInput,
  Button,
  Box,
  Group,
  Textarea,
  Text,
  Select,
  Modal,
  Card,
  Image,
} from '@mantine/core';
import { PhotographIcon } from '@heroicons/react/outline';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { Link, useRouteParams } from 'react-typesafe-routes';
import { router } from '../routes';
import { AdvButton } from '@adv/ui';
import { inventoryActions } from '../store/inventory/inventory.slice';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import Categories from './categories';
import {
  CreateCategory,
  UpdateCategory,
} from '../store/inventory/inventory.thunk';
import { ArrowLeftIcon, TrashIcon } from '@heroicons/react/solid';
import { UploadImage } from '@adv/sdk/stores';

const schema = Yup.object().shape({
  name: Yup.string().min(2, 'Name should have at least 2 letters'),
  // msrp: Yup.number().max(1000000, 'Invalid msrp'),
  // discount: Yup.number().min(
  //   18,
  //   'You must be at least 18 to create an account'
  // ),
});

function ImageUploadIcon({ status, ...props }: any) {
  if (status.accepted) {
    return <PhotographIcon {...props} />;
  }

  if (status.rejected) {
    // return <X {...props} />;
    return <PhotographIcon {...props} />;
  }

  // return <Photo {...props} />;
  return <PhotographIcon {...props} />;
}

export const dropzoneChildren = (status: any) => (
  <Group
    position="center"
    spacing="xl"
    style={{ minHeight: 100, pointerEvents: 'none' }}
  >
    <ImageUploadIcon
      status={status}
      style={{ color: '#ced4da', width: '50px', height: '50px' }}
    />

    <div>
      <Text size="md" inline>
        Drag images here or{' '}
        <span className="text-primary-700">click to select files</span>
      </Text>
      <Text size="sm" color="dimmed" inline mt={7}>
        Attach as many files as you like, each file should not exceed 5mb
      </Text>
    </div>
  </Group>
);

function CategoryForm(props: any) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const category = useAppSelector((state: any) => state.inventory.category);
  const store = useAppSelector((state: any) => state.store.store);
  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      name: '',
      images: [],
    },
  });
  const options = useRouteParams(router.catalogue().updateCategory);
  const getImage = (imageFile: any) => {
    setImageUpload(imageFile[0].path);
  };
  const [imageUpload, setImageUpload] = useState('');
  const [opened, setOpened] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [updateCategory, setUpdateCategory] = useState<any>(null);
  const [uploadImages, setUploadImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const setImage = async (files) => {
    setImageLoading(true);
    const { data, status, error } = await UploadImage(files);
    if (data && data?.length > 0) {
      const formVal = form.values;
      form.setValues({
        ...formVal,
        images: [...formVal.images, data[0]?.id],
      });
      setUploadImages([
        ...uploadImages,
        { id: data[0]?.id, url: data[0]?.url },
      ]);
      setImageLoading(false);
    }
  };
  useEffect(() => {
    console.log(options?.id);
    if (options?.id) {
      setUpdate(true);
      const selectedCategory = category.filter((e) => e.id == options.id)[0];
      console.log(selectedCategory);
      const selectedImages = [selectedCategory?.image];
      setUpdateCategory(selectedCategory);
      form.setValues({
        name: selectedCategory?.name,
        images: [...selectedImages] || [],
      });
      setUploadImages(selectedCategory.image ? [selectedCategory.image] : []);
    }
  }, [category]);

  const UploadedImages = uploadImages
    ? uploadImages.map((e, index) => (
        <div className="w-32 mx-2" key={index}>
          <Card shadow="md" p="sm" withBorder>
            <Card.Section>
              <div
                className="flex justify-end cursor-pointer p-1"
                onClick={(e) => console.log('delete')}
              >
                <TrashIcon className="w-5 h-5"></TrashIcon>
              </div>
            </Card.Section>
            <Image
              src={
                e?.url ||
                'https://versicles-strapi-cms-media.s3.amazonaws.com/store-def.jpg'
              }
              className="w-24"
              alt={updateCategory?.name}
              fit="contain"
            />
          </Card>
        </div>
      ))
    : '';

  return (
    <>
      {/* <h2 className="text-secondary-500 text-xl font-semibold flex justify-center items-center m-2">
        Add Product
      </h2> */}
      <div
        className="flex justify-start items-center p-2 cursor-pointer"
        onClick={() => {
          history.push(router.catalogue().category().$);
        }}
      >
        <ArrowLeftIcon className="w-4 h-4"></ArrowLeftIcon>
        Back
      </div>
      <div className=" productForm m-auto mt-4 w-10/12">
        <form
          onSubmit={form.onSubmit((values) => {
            if (isUpdate) {
              dispatch(
                UpdateCategory({
                  ...values,
                  business_categories: [store.business_categories[0].id],
                  storeId: store?.id,
                  productCategoryId: updateCategory?.id,
                })
              );
            } else {
              dispatch(
                CreateCategory({
                  ...values,
                  business_categories: [store?.business_categories[0].id],
                  storeId: store?.id,
                })
              );
            }
            setOpened(true);
            history.push(router.catalogue().category().$);
          })}
        >
          <div className="flex bg-gray-100 p-2 rounded-md shadow-sm font-semibold mb-2">
            Category Information
          </div>
          <TextInput
            required
            label="Category Name"
            placeholder="Category Name"
            {...form.getInputProps('name')}
          />
          <div className="mt-6">Category Image</div>
          <Dropzone
            mt="md"
            onDrop={(files) => setImage(files)}
            onReject={(files) => console.log('rejected files', files)}
            maxSize={3 * 1024 ** 2}
            accept={IMAGE_MIME_TYPE}
            {...form.getInputProps('images')}
          >
            {(status) => dropzoneChildren(status)}
          </Dropzone>
          {UploadedImages && (
            <>
              <div className="my-6">Uploads:</div>
              <div className="flex flex-wrap">{UploadedImages}</div>
            </>
          )}
          <Group position="right" mt="xl">
            {/* <Button type="submit">
              <Link to={router.product()}>Publish Product</Link>
            </Button> */}
            <AdvButton color="yellow" type="submit" className="text-white ">
              {isUpdate ? 'Save Category' : 'Add Category'}
              {/* <Link to={router.product()}>Add Product</Link> */}
            </AdvButton>
          </Group>
        </form>
      </div>
      {/* <Modal opened={opened} onClose={() => setOpened(false)}>
        <Product formValue={form.values} />
      </Modal> */}
    </>
  );
}

CategoryForm.propTypes = {};

export default CategoryForm;
