import { LoadingState } from '@adv/sdk/common';
import { sessionSet } from '@adv/utils/storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetOrderById,
  GetOrders,
  OrderRefundInfo,
  UpdateOrder,
  UpdateOrderStatusInfo,
} from './orders.thunk';

export const ORDERS_FEATURE_KEY = 'order';

export interface ordersState {
  loading: boolean;
  order: any;
  orders: any;
  updateStatus: LoadingState;
  orderDateRange: any;
}

export const initialOrderState: ordersState = {
  loading: false,
  order: null,
  orders: [],
  updateStatus: LoadingState.idle,
  orderDateRange: null,
};

export const orderSlice = createSlice({
  name: ORDERS_FEATURE_KEY,
  initialState: initialOrderState,
  reducers: {
    setOrder(state, action) {
      state.order = action.payload;
    },
    clearOrder(state) {
      state.order = null;
    },
    setOrderDateRange(state, action) {
      state.orderDateRange = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UpdateOrder.pending, (state, action) => {
        state.updateStatus = LoadingState.loading;
        state.loading = true;
      })
      .addCase(UpdateOrder.fulfilled, (state, { payload }) => {
        state.updateStatus = LoadingState.loaded;
        state.loading = false;
      })
      .addCase(UpdateOrder.rejected, (state, action) => {
        state.updateStatus = LoadingState.error;
      })
      .addCase(UpdateOrderStatusInfo.pending, (state, action) => {
        state.updateStatus = LoadingState.loading;
        state.loading = true;
      })
      .addCase(UpdateOrderStatusInfo.fulfilled, (state, { payload }) => {
        state.order = payload;
        state.updateStatus = LoadingState.loaded;
        state.loading = false;
      })
      .addCase(UpdateOrderStatusInfo.rejected, (state, action) => {
        state.updateStatus = LoadingState.error;
        state.loading = false;
      })
      .addCase(OrderRefundInfo.pending, (state, action) => {
        state.updateStatus = LoadingState.loading;
        state.loading = true;
      })
      .addCase(OrderRefundInfo.fulfilled, (state, { payload }) => {
        state.order = payload;
        state.updateStatus = LoadingState.loaded;
        state.loading = false;
      })
      .addCase(OrderRefundInfo.rejected, (state, action) => {
        state.updateStatus = LoadingState.error;
        state.loading = false;
      })
      .addCase(GetOrders.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(GetOrders.fulfilled, (state, { payload }) => {
        state.orders = [...(payload as any)?.orders];
        state.loading = false;
      })
      .addCase(GetOrders.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(GetOrderById.fulfilled, (state, { payload }) => {
        state.order = (payload as any).orders[0];
        state.loading = false;
      })
      .addCase(GetOrderById.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(GetOrderById.pending, (state, action) => {
        state.loading = true;
      });
  },
});

export const orderReducer = orderSlice.reducer;
export const orderActions = orderSlice.actions;
