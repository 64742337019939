import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';

import { applicationReducer } from './application/application.slice';
import { inventoryReducer } from './inventory/inventory.slice';
import { orderReducer } from './orders/orders.slice';
import { StoreReducer } from './stores/stores.slice';
import { userReducer } from './user/user.slice';

export const store = configureStore({
  reducer: {
    app: applicationReducer,
    inventory: inventoryReducer,
    store: StoreReducer,
    user: userReducer,
    order: orderReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
