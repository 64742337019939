import Cookies from 'js-cookie';
import axios from 'axios';
import { stringify } from 'query-string';

const token = Cookies.get('store_token');
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

const baseURL = 'https://cms-api.versicles.com';
const Api = axios.create({
  baseURL: baseURL + '/api',
});

const UploadBaseURL = 'http://44.199.188.164:1337';
const UploadAPI = axios.create({
  baseURL: UploadBaseURL + '/api',
});

export interface StoreLoginArgs {
  identifier: string;
  password: string;
}
export interface StoreSignUpArgs {
  username: string;
  email: string;
  password: string;
}

export interface CreateBusinessArgs {
  name: string;
  business_category: number;
}
const businessApiPath = 'business';
export const getBusinessCategories = async (query: any): Promise<any> => {
  try {
    const res = await Api.get(`${businessApiPath}/categories`);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};
export const getBusinessProductTypes = async (): Promise<any> => {
  const url = `product-types`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = await Api.get(url, config);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};
export const getBusinessOrders = async (
  business_id: any,
  order_id: any
): Promise<any> => {
  try {
    const res = await Api.get(
      `${businessApiPath}/${business_id}/order/${order_id}`,
      config
    );
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};
export const getStoreById = async (query: any): Promise<any> => {
  try {
    const res = await Api.get(`${businessApiPath}/${query.storeId}/details`);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};
export const getCategoryByStoreId = async (query: any): Promise<any> => {
  try {
    const res = await Api.get(
      `${businessApiPath}/${query.storeId}/admin/product-category/products`
    );
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const getProductByStoreId = async (query: any): Promise<any> => {
  try {
    const res = await Api.get(
      `${businessApiPath}/${query.storeId}/admin/products `
    );
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};
export const getProductByStoreId_categoryId = async (
  query: any
): Promise<any> => {
  try {
    const res = await Api.get(
      `${businessApiPath}/${query.storeId}/product-category/${query.categoryId}/products`
    );
    return {
      status: +res.status || 200,
      data: res?.data?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const getThemeByBusinessCategory = async (query: any): Promise<any> => {
  try {
    const res = await Api.get(
      `${businessApiPath}/category/${query.categoryId}/themes`
    );
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const StoreSignUp = async (args: StoreSignUpArgs): Promise<any> => {
  const url = `auth/local/register`;
  try {
    const res = await Api.post(url, args);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const StoreLogin = async (args: StoreLoginArgs): Promise<any> => {
  const url = `auth/local`;
  try {
    const res = await Api.post(url, args);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const CreateBusiness = async (
  args: CreateBusinessArgs
): Promise<any> => {
  const url = `${businessApiPath}/create`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = await Api.post(url, args, config);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const GetStoreByUserId = async (): Promise<any> => {
  const url = `users/me`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = await Api.get(url, config);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const AddBusinessProduct = async (
  args: any,
  business_id: any
): Promise<any> => {
  const url = `${businessApiPath}/${business_id}/product`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const res = await Api.post(url, args, config);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};
export const GetBusinessProduct = async (
  business_id: any,
  product_id: any
): Promise<any> => {
  const url = `${businessApiPath}/${business_id}/product/${product_id}`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const res = await Api.get(url);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const UpdateBusinessProduct = async (
  args: any,
  business_id: any,
  product_id: any
): Promise<any> => {
  const url = `${businessApiPath}/${business_id}/product/${product_id}`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const res = await Api.put(url, args, config);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const DeleteBusinessProduct = async (
  args: any,
  business_id: any,
  product_id: any
): Promise<any> => {
  const url = `${businessApiPath}/${business_id}/product/${product_id}`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const res = await Api.delete(url, config);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const CreateProductCategory = async (
  args: any,
  business_id: any
): Promise<any> => {
  console.log(args, business_id);

  const url = `${businessApiPath}/${business_id}/product-category`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = await Api.post(url, args, config);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const UpdateProductCategory = async (
  args: any,
  business_id: any,
  product_category_id: any
): Promise<any> => {
  const url = `${businessApiPath}/${business_id}/product-category/${product_category_id}`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const data = {
      name: args.name,
      business_categories: args.business_categories,
    };
    const res = await Api.put(url, data, config);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const DeleteProductCategory = async (
  args: any,
  business_id: any,
  product_category_id: any
): Promise<any> => {
  const url = `${businessApiPath}/${business_id}/product-category/${product_category_id}`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const res = await Api.delete(url, config);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};
export const UpdateBusinessDetails = async (
  args: any,
  business_id: any
): Promise<any> => {
  const url = `${businessApiPath}/${business_id}/update`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = await Api.put(url, args, config);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const UploadImage = async (files: any): Promise<any> => {
  const url = `upload`;
  try {
    const token = Cookies.get('store_token');
    const file = files[0];
    const formData = new FormData();
    formData.append(`files`, file);
    console.log(formData);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await Api.post(url, formData, config);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const getOrderByStoreId = async (data: any): Promise<any> => {
  const queryParams = data.dateRange;
  const url = `${businessApiPath}/${data?.id}/orders`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: queryParams,
    };

    const res = await Api.get(url, config);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const getFaceBookPages = async (token: any): Promise<any> => {
  const ver_fb_api =
    'https://3hqm9zjvp5.execute-api.us-east-1.amazonaws.com/dev';
  const url = `${ver_fb_api}/api/v1/account/pages?access_token=${token}`;
  const config = {
    headers: { accept: 'application/json' },
  };
  try {
    const res = await Api.get(url, config);
    console.log(res?.data);

    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const postFaceBookFeed = async (args: any): Promise<any> => {
  const ver_fb_api =
    'https://3hqm9zjvp5.execute-api.us-east-1.amazonaws.com/dev';
  const url = `${ver_fb_api}/api/v1/feed`;
  try {
    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     'Content-Type': 'multipart/form-data',
    //   },
    // };

    const res = await Api.post(url, { request: args });
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const getOrderByOrderId = async (
  business_id: any,
  order_id: any
): Promise<any> => {
  const url = `${businessApiPath}/${business_id}/order/${order_id}`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const res = await Api.get(url, config);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const updateOrderByOrderId = async (
  business_id: any,
  order_id: any,
  data: any
): Promise<any> => {
  const url = `${businessApiPath}/${business_id}/order/${order_id}`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const res = await Api.put(url, data, config);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const updateKioskLocker = async (
  business_id: any,
  order_id: any,
  data: any
): Promise<any> => {
  const url = `${businessApiPath}/${business_id}/order/${order_id}/kiosk/locker`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const res = await Api.put(url, data, config);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const updateOrderStatus = async (
  business_id: any,
  order_id: any,
  data: any
): Promise<any> => {
  const url = `${businessApiPath}/${business_id}/order/${order_id}/status`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(data);
    const payload = {
      status: data,
    };
    const res = await Api.put(url, payload, config);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const orderRefund = async (
  business_id: any,
  order_id: any
): Promise<any> => {
  const url = `${businessApiPath}/${business_id}/order/${order_id}/refund`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = await Api.put(url, null, config);
    return {
      status: +res.status || 200,
      data: res?.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const getAvailableBox = async (lockerId: any): Promise<any> => {
  const url = `locker/${lockerId}/locker-boxes/available`;
  try {
    const token = Cookies.get('store_token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = await Api.get(url, config);
    return {
      status: +res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const getSellerAgency = async (sellerId: any): Promise<any> => {
  const url = `order/seller/${sellerId}/agency`;
  try {
    const token = Cookies.get('store_token');

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = await Api.get(url, config);
    return {
      status: +res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};
