import React, { forwardRef, useEffect, useState } from 'react';
import { router } from '../routes';

function Delivery() {
  const landingUrl = 'https://retailer.vendngo.in/landing';
  const loginUrl = 'https://retailer.vendngo.in/login';
  const [url, setUrl] = useState(landingUrl);

  // useEffect(() => {
  //   setUrl(loginUrl);
  //   return () => {
  //     const text =
  //       'You will be logged out from Delivery App. Are you sure want to leave?';
  //     if (window.confirm(text) == true) {
  //       setUrl(loginUrl);
  //     } else {
  //       router.delivery();
  //       setUrl(landingUrl);
  //     }
  //   };
  // }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-primary-900 p-0">
      <iframe
        src={loginUrl}
        title="VendnGo ClickNCollect"
        className="w-full h-screen rounded-2xl"
      ></iframe>
    </div>
  );
}
export default Delivery;
