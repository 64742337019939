import React, { useEffect, forwardRef, useState } from 'react';
// const BillForPrint = forwardRef((props, ref) => {
const BillForPrint =  forwardRef((props: any,ref) => {
  //   const { billDetails } = props;
  const [orders, setOrders] = useState([]);
  const [forPrint, setForPrint] = useState([]);



  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      if (!map.has(key)) {
        map.set(key, [item]);
      } else {
        map.get(key).push(item);
      }
    });
    return map;
  }

  useEffect(() => {
    // console.log('props', props);
    // const pps = [...props];
    const propertyValues = Object.values(props.ordersArray);
    // console.log('propertyValues', propertyValues);
    // order.push(props[0]);
    // console.log('order', order[0]);
    // if (order && order?.length) {
    //   order.forEach((item) => {
    //     order.push(item);
    //   });
    // }
    const grouped = groupBy(propertyValues, (order) => order.business.id);

    // console.log('grouped', grouped);
    const arrayForPrint = [];
    for (const [key, value] of grouped) {
      const stringified = JSON.stringify(value);
      arrayForPrint.push(stringified);

      // console.log(key + ' = ' + JSON.stringify(value));
      // console.log('arrayForPrint', arrayForPrint);
    }

    setForPrint(arrayForPrint);
    setOrders(propertyValues);
  }, [props]);

  useEffect(() => {
    // console.log('orders', orders);
  }, [orders]);

  return (
    <div>
      {
        // 👇️ ts-ignore ignores any ts errors on the next line
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore

          
        <div ref={ref} className="bill-print-wrapper w-80 p-2 border border-gray-400 border-solid">
          {/* {forPrint} */}
          <img src="https://vendngo.in/wp-content/uploads/2023/02/logo.png" className="w-32 mx-auto"/>
          <div className='text-lg font-bold text-center py-2 border-b-2 border-gray-500'>{props?.orderData?.business?.name}</div>
          <div className='text-base font-bold text-center py-2 border-b-2 border-gray-500'>Order Id #{props?.orderData?.order_id}</div>
          <div>
            Customer Name: {props?.orderData?.customer?.name}<br />
            Phone: {props?.orderData?.customer?.phone}<br />
          </div>
          <table className='border-black'>
                  <thead>
                    <tr>
                    <th>#</th>
                    <th>Item</th>
                    <th>Qty/Weight</th>
                    </tr>
                  </thead>
                  <tbody>
          {orders &&
            orders?.length &&
            orders.map((odr,i:any) => (<>
            {i>0? 
              orders[i-1].business?.name== odr?.business?.name? <tr><td>''</td></tr>:
              <tr><td colSpan={3}><h6 className='font-bold text-sm'>{odr?.business?.name}</h6></td></tr>
              :<tr><td colSpan={3}><h6 className='font-bold text-sm'>{odr?.business?.name} </h6></td></tr>
            }
                <tr>
                  <td>{i+1} </td>
                  <td>{odr?.product?.name}</td>
                  <td className="text-center">
                    {odr?.product?.product_dimension?.unit==null?  odr?.quantity :
                      
                      
                      odr?.product?.product_dimension?.unit!=''? 
                    
                    odr?.weight/ 1000<1? odr?.weight+'gm': odr?.weight/ 1000+'Kg':

                      odr?.quantity
                    }
                    </td>
                </tr>

                  
            </>))}
            </tbody>
            </table>
            <div className='text-center py-4 border-b-2 border-black'>********* Thank You *********</div>
        </div>
      }
    </div>
  );
});

export default BillForPrint;
