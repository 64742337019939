import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  subMonths,
  subDays,
} from 'date-fns';

export const AdvDatesDefined = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfNextWeek: startOfWeek(addDays(new Date(), +7)),
  endOfNextWeek: endOfWeek(addDays(new Date(), +7)),
  startOfPrevWeek: startOfWeek(subDays(new Date(), +7)),
  endOfPrevWeek: endOfWeek(subDays(new Date(), +7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfTomorrow: startOfDay(addDays(new Date(), +1)),
  endOfTomorrow: endOfDay(addDays(new Date(), +1)),
  startOfYesterday: startOfDay(subDays(new Date(), +1)),
  endOfYesterday: endOfDay(subDays(new Date(), +1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfNextMonth: startOfMonth(addMonths(new Date(), +1)),
  endOfNextMonth: endOfMonth(addMonths(new Date(), +1)),
  startOfPrevMonth: startOfMonth(subMonths(new Date(), +1)),
  endOfPrevMonth: endOfMonth(subMonths(new Date(), +1)),
  startOfNext1Month: startOfMonth(addMonths(new Date(), +2)),
  endOfNext1Month: endOfMonth(addMonths(new Date(), +2)),
  startOfLast7days: startOfDay(subDays(new Date(), +7)),
  endOfLast7days: endOfDay(new Date()),
};
