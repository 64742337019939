export enum CampaignType {
  email = 'email',
  text = 'text',
  page = 'page',
  banner = 'banner',
  social = 'social',
  displayAd = 'displayAd',
  landingPage = 'landingPage',
  websitePopups = 'websitePopups',
}

export enum order_status {
  created = 1,
  accepted = 2,
  inprogress = 3,
  cancelled = 4,
  completed = 5,
  intransit = 6,
  delivered = 7,
  refunded = 8,
  customer_intransit = 9,
  customer_reached = 10,
  team_member_intransit = 11,
}

export const default_order_timeLine = {
  created: {
    step: 1,
    name: 'Order Received',
    btn_success: { label: 'Accept order', next: 'accepted' },
    btn_default: { label: 'Reject order', next: 'cancelled' },
    orderStatus: order_status.created,
    orderStatusLabel: 'Pending',
  },
  accepted: {
    step: 2,
    name: 'Order Accepted',
    btn_success: { label: 'Ready to Prepare', next: 'inprogress' },
    btn_default: { label: 'Cancel order', next: 'cancelled' },
    orderStatus: order_status.accepted,
    orderStatusLabel: 'accepted',
  },
  inprogress: {
    step: 3,
    name: 'Order Preparing',
    btn_success: { label: 'Ready to Pickup', next: 'completed' },
    btn_default: { label: 'Cancel order', next: 'cancelled' },
    orderStatus: order_status.inprogress,
    orderStatusLabel: 'Preparing',
  },
  cancelled: {
    step: 4,
    name: 'Order Cancelled',
    orderStatus: order_status.cancelled,
    orderStatusLabel: 'Cancelled',
  },
  completed: {
    step: 5,
    name: 'Order Ready to pickup',
    btn_success: { label: 'Delivered', next: 'delivered' },
    btn_default: { label: 'Cancel order', next: 'cancelled' },
    orderStatus: order_status.completed,
    orderStatusLabel: 'Ready to pickup',
  },
  intransit: {
    step: 6,
    name: 'Order Ready to Deliver',
    btn_success: { label: 'Delivered', next: 'delivered' },
    btn_default: { label: 'Cancel order', next: 'cancelled' },
    orderStatus: order_status.intransit,
    orderStatusLabel: 'Ready to Deliver',
  },
  delivered: {
    step: 7,
    name: 'Order Delivered',
    orderStatus: order_status.delivered,
    orderStatusLabel: 'Delivered',
  },
  refunded: {
    step: 8,
    name: 'Order Refunded',
    orderStatus: order_status.refunded,
    orderStatusLabel: 'Refunded',
  },
  customer_intransit: {
    step: 9,
    name: 'Customer on the way',
    btn_default: { label: 'Cancel order', next: 'cancelled' },
    orderStatus: order_status.customer_intransit,
    orderStatusLabel: 'Customer on the way',
  },
  customer_reached: {
    step: 10,
    name: 'Customer at the location',
    btn_success: {
      label: 'Team member on the way',
      next: 'team_member_intransit',
    },
    btn_default: { label: 'Cancel order', next: 'cancelled' },
    orderStatus: order_status.customer_reached,
    orderStatusLabel: 'Customer at the location',
  },
  team_member_intransit: {
    step: 5,
    name: 'Team member on the way',
    btn_success: { label: 'Delivered', next: 'delivered' },
    btn_default: { label: 'Cancel order', next: 'cancelled' },
    orderStatus: order_status.team_member_intransit,
    orderStatusLabel: 'Team member on the way',
  },
};
