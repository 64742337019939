import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserLoginArgs, UserSignUpArgs } from '@adv/sdk/user';
import { LoadingState } from '@adv/sdk/common';
import { UserLogin, UserSignUp } from './user.thunk';
import Cookies from 'js-cookie';
import { router } from '../../routes';
import { useHistory } from 'react-router-dom';
import { sessionSet } from '@adv/utils/storage';
import { GetStoreByUserId } from '@adv/sdk/stores';
export const USER_FEATURE_KEY = 'user';

export interface UserState {
  loadingStatus: LoadingState;
  error: string | null | undefined;
  token: string | null | undefined;
  success: string | null | undefined;
  data: any;
}

export const initialUserState: UserState = {
  loadingStatus: LoadingState.idle,
  error: null,
  token: null,
  success: null,
  data: null,
};

export const userSlice = createSlice({
  name: USER_FEATURE_KEY,
  initialState: initialUserState,
  reducers: {
    clearUser(state) {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UserLogin.pending, (state, action) => {
        state.loadingStatus = LoadingState.loading;
      })
      .addCase(UserLogin.fulfilled, (state, action: any) => {
        state.loadingStatus = LoadingState.loaded;
        if (action.payload) {
          const queryParams = new URLSearchParams(window.location.search);
          const url = queryParams.get('url');
          Cookies.set('store_token', action?.payload?.jwt, {
            domain: 'versicles.com',
            expires: 1,
          });
          Cookies.set('store_token', action?.payload?.jwt, { expires: 1 });
          state.data = action.payload.user;
          state.token = action.payload.jwt;
          if (url) {
            window.location.href = `${url}`;
          } else {
            // window.location.replace(router.home().$);
          }
        }
      })
      .addCase(
        UserLogin.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.loadingStatus = LoadingState.error;
          if (action.payload) {
            let msg = action.payload.message;
            switch (action.payload.code) {
              case 300:
                msg =
                  'User not verified. Please verify using the email link that you received during registration.';
                break;
              case 401:
                msg = 'invalid credentials provided. Please try again';
                break;
            }
            state.error = msg;
          } else {
            state.error = action?.error?.message;
          }
        }
      )
      .addCase(UserSignUp.pending, (state, action) => {
        state.loadingStatus = LoadingState.loading;
      })
      .addCase(UserSignUp.fulfilled, (state, action: any) => {
        state.loadingStatus = LoadingState.loaded;
        if (action.payload) {
          console.log(action.payload, 'signup');
          Cookies.set('store_token', action.payload.jwt, {
            domain: 'versicles.com',
            expires: 1,
          });
          Cookies.set('store_token', action.payload.jwt, { expires: 1 });
          state.success = 'We have send an email, please verify to login';
          state.error = '';
          state.data = action.payload.user;
          state.token = action.payload.jwt;
        }
        // state.token = action.payload;
      })
      .addCase(UserSignUp.rejected, (state, action: any) => {
        state.loadingStatus = LoadingState.error;
        if (action.payload) {
          let msg = action?.payload?.message;
          switch (action.payload) {
            case 300:
              msg = '';
              break;
            case 400:
              msg = 'Email already exists.';
              break;
          }
          state.error = msg;
          state.success = '';
        } else {
          state.error = action?.error?.message;
          state.success = '';
        }
      });
  },
});

/*
 * Export reducer for store configuration.
 */
export const userReducer = userSlice.reducer;

export const userActions = userSlice.actions;
