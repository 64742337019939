import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetStore, GetStoreById } from '../stores/stores.thunk';
import {
  StoreLogin,
  StoreLoginArgs,
  StoreSignUp,
  StoreSignUpArgs,
} from '@adv/sdk/stores';

export const UserLogin = createAsyncThunk(
  'user/login',
  async (payload: StoreLoginArgs, thunkAPI) => {
    const { data, status, error } = await StoreLogin(payload);
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);
    return thunkAPI.fulfillWithValue(data);
  }
);

export const UserSignUp = createAsyncThunk(
  'user/sign-up',
  async (payload: StoreSignUpArgs, thunkAPI) => {
    const { data, status, error } = await StoreSignUp(payload);
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(status);
    return thunkAPI.fulfillWithValue(data);
  }
);
