import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  Image,
  Text,
  Badge,
  Button,
  Group,
  Table,
  Menu,
} from '@mantine/core';
import { Link } from 'react-typesafe-routes';
import { router } from '../routes';
import { AdvButton } from '@adv/ui';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import { inventoryActions } from '../store/inventory/inventory.slice';
import { ViewGridIcon, ViewListIcon } from '@heroicons/react/solid';
import { useMediaQuery } from '@mantine/hooks';
import { DeleteCategory } from '../store/inventory/inventory.thunk';
function CategoriesList(props: any) {
  const history = useHistory();
  const category = useAppSelector((state: any) => state.inventory.category);
  const store = useAppSelector((state: any) => state.store.store);

  const dispatch = useAppDispatch();
  const [view, setView] = useState('list');
  const isMobile = useMediaQuery('(max-width: 700px)');
  useEffect(() => {
    if (isMobile) {
      setView('grid');
    }
  }, [isMobile]);

  const rows = category.map((element, index) => (
    <tr
      key={index}
      className="border cursor-pointer hover:bg-gray-200"
      onClick={() => {
        history.push(
          router.catalogue().categoryByProducts({ id: element.id }).$
        );
      }}
    >
      <td>
        <div className="flex  items-center">
          <Image
            src={
              element?.image
                ? element?.image?.url
                : 'https://versicles-strapi-cms-media.s3.amazonaws.com/category-def.jpg'
            }
            height={50}
            width={50}
            alt={element}
          />
          <p className="px-2">{element.name}</p>
        </div>
      </td>
      <td>{element?.products?.length || 0}</td>
      <td>
        <div className="flex justify-end items-center">
          <Menu
            control={<DotsVerticalIcon className="h-5 w-5"></DotsVerticalIcon>}
          >
            <div>
              <Menu.Item
                onClick={() => {
                  history.push(
                    router.catalogue().updateCategory({ id: element.id }).$
                  );
                }}
              >
                <p className="p-2 hover:bg-gray-100 cursor-pointer">Edit</p>
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  dispatch(inventoryActions.categoryDelete(element));

                  dispatch(
                    DeleteCategory({
                      ...element,
                      id: element?.id,
                      storeId: store?.id,
                    })
                  );
                }}
              >
                <p className="p-2 hover:bg-gray-100 cursor-pointer">Delete</p>
              </Menu.Item>
            </div>
          </Menu>
        </div>
      </td>
      {/* <td>{element.category}</td>
      <td>{element.price}</td> */}
    </tr>
  ));
  return (
    <div className="p-2">
      <div className="flex flex-row items-end justify-end m-2 ">
        <div
          className="p-2 bg-gray-100 rounded-md mx-2"
          onClick={() => setView('list')}
        >
          <ViewListIcon className="w-5 h-5 text-primary-900"></ViewListIcon>
        </div>
        <div
          className="p-2 bg-gray-100 rounded-md mx-2"
          onClick={() => setView('grid')}
        >
          <ViewGridIcon className="w-5 h-5 text-primary-900"></ViewGridIcon>
        </div>
        <Link to={router.catalogue().addCategory()}>
          <AdvButton color="yellow" type="submit" className="text-white ">
            Add New Category
          </AdvButton>
        </Link>
      </div>

      <div className="p-2">
        {view === 'grid' && (
          <Grid gutter="xs">
            {category.map((e) => (
              <Grid.Col span={6} xs={6} sm={2}>
                {/* <Link to={router.product()}> */}
                <Card
                  shadow="md"
                  p="sm"
                  withBorder
                  onClick={() => {
                    history.push(
                      router.catalogue().categoryByProducts({ id: e.id }).$
                    );
                  }}
                >
                  <Card.Section>
                    <Menu
                      className="absolute z-10 right-1 top-1"
                      control={
                        <DotsVerticalIcon className="h-5 w-5"></DotsVerticalIcon>
                      }
                    >
                      <div>
                        <p
                          className="p-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            history.push(
                              router.catalogue().updateCategory({ id: e.id }).$
                            );
                          }}
                        >
                          Edit
                        </p>
                        <p
                          className="p-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            dispatch(inventoryActions.categoryDelete(e));
                          }}
                        >
                          Delete
                        </p>
                      </div>
                    </Menu>
                    <div
                      style={{
                        // width: 240,
                        padding: '5px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      <Image
                        radius="md"
                        fit="contain"
                        height={150}
                        src={
                          e?.image
                            ? e?.image?.url
                            : 'https://versicles-strapi-cms-media.s3.amazonaws.com/category-def.jpg'
                        }
                        alt={e.name}
                        // caption={e.name}
                      />
                    </div>
                  </Card.Section>
                  <Text className="mt-1 text-sm">{e.name}</Text>
                </Card>
                {/* </Link> */}
              </Grid.Col>
            ))}
          </Grid>
        )}
        {view === 'list' && category.length > 0 && (
          <Table>
            <thead>
              <tr>
                {/* <th className="w-1/4">Product</th> */}
                <th className="w-1/4">Category</th>
                <th className="w-1/4">Products</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        )}
      </div>
    </div>
  );
}

CategoriesList.propTypes = {};

export default CategoriesList;
