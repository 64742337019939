import React, { useEffect } from 'react';
import { RouterSwitch } from 'react-typesafe-routes';
import { router } from '../routes';

import { Route, Link } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import { NotificationsProvider } from '@mantine/notifications';
import { Layout } from '../components/index';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { AppInitialize } from '../store/application/application.thunk';
export function App() {
  const queryClient = new QueryClient();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(AppInitialize());
  }, []);
  const store = useAppSelector((state) => state.store.store);
  return (
    <MantineProvider theme={{ primaryColor: 'violet' }}>
      <NotificationsProvider>
        <QueryClientProvider client={queryClient}>
          {store ? (
            <Layout>
              <RouterSwitch router={router} />
            </Layout>
          ) : (
            <RouterSwitch router={router} />
          )}
        </QueryClientProvider>
      </NotificationsProvider>
    </MantineProvider>
  );
}

export default App;
