import React, { useEffect, useRef, useState } from 'react';
import { PencilAltIcon, PhotographIcon } from '@heroicons/react/solid';
import { Link, useRouteActive } from 'react-typesafe-routes';
import { router } from '../routes';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {
    ChevronDownIcon
  } from '@heroicons/react/solid';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { FetchAllpersons } from '@adv/sdk/posthog'
import { showNotification, updateNotification } from '@mantine/notifications';
import { useHistory } from 'react-router-dom';
interface StoreInfo {
  name: string;
  address: string;
  category: string;
  country: string;
  email: string;
  phone: string;
  logo: string;
}
// landing_page: {image_carousel: {title: 'Zam Zam1', caption: 'test2'}}


function Session() {
  const dispatch = useAppDispatch();
  const dropzoneRef = useRef<() => void>();
  const store = useAppSelector((state) => state.store.store);
  const history = useHistory();

  const [persons, setPersons] = useState<any>([]);
  const getuserdetails = async () => {
    const data = await FetchAllpersons();
    console.log(data.data.results);
    setPersons(data.data.results);
  };
  useEffect(() => {
    getuserdetails();
    console.log(store);
  }, []);
  function toggle_element(element_id) {
    var element = document.getElementById(element_id);
    const boxes = document.getElementsByClassName(
        'boxes',
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < boxes.length; i++) {
        // boxes[i].style.display = 'none';
      }
    element.style.display = (element.style.display != 'none' ? 'none' : 'block' );
}

  return (
    <div className=" productForm m-auto mt-4 w-full px-4">
        <div className='text-xl font-semibold'>Persons & Groups</div>
        <div className='text-sm text-gray-500 mb-5'>A catalog of your product's end users and groups.</div>
        <div className="flex border-purple-600 border mt-4 mx-2  rounded-t-lg px-4 py-2 bg-gray-100">
            {/* <div className="w-8"></div> */}
            <div className="flex-1">ID</div>
            <div className="w-64 border-l pl-2">TYPE</div>
            <div className="w-72 border-l pl-2">FIRST SEEN</div>
        </div>
        {persons?.length == 0 ? (
            'No Personals exist.'
        ) : ( <>
            {persons?.map((data: any, i: any) => (
                data.properties?.$initial_current_url.includes(store?.id)?(<>
                <div className="flex border border-purple-300 mx-2  px-4 py-2 hover:bg-purple-100">
                    {/* <div className="w-8" onClick={()=>toggle_element('box'+i)}> <ChevronDownIcon className="text-black h-6 float-left" /></div> */}
                    <div className="flex-1">
                    <Link  to={router.session().sessionDetails({id:data.id})} 
                className='text-blue-700 hover:text-indigo-500'>{data.name}</Link>
                    </div>
                    <div className="w-64 border-l pl-2 capitalize">{data.type}</div>
                    <div className="w-72 border-l pl-2"> 
                    {data?.created_at
                        ? format(
                            new Date(data.created_at),
                            'MMMM dd, yyyy hh:ii '
                        )
                        : ''}
                    </div>
                </div>
                <div className='boxes flex border border-purple-300 mx-2  px-4 py-2' id={'box'+i} style={{display: 'none'}}>
                    <div className='text-base font-semibold p-1'>Name: {data.name}</div>
                    <div className='text-base font-semibold p-1'>ID: {data.id}</div>
                    <div className="grid lg:grid-cols-3 my-2">
                        <div className='col-span-1 flex p-1'><div className='font-bold pr-2'>City Name: </div> {data.properties.$geoip_city_name}</div>
                        <div className='col-span-1 flex p-1'><div className='font-bold pr-2'>Continent: </div> {data.properties.$geoip_continent_name}</div>
                        <div className='col-span-1 flex p-1'><div className='font-bold pr-2'>Country Name: </div> {data.properties.$geoip_country_name}</div>
                        <div className='col-span-1 flex p-1'><div className='font-bold pr-2'>Postal Code: </div> {data.properties.$geoip_postal_code}</div>
                        <div className='col-span-1 flex p-1'><div className='font-bold pr-2'>Subdivision 1 Name: </div> {data.properties.$geoip_subdivision_1_name}</div>
                        <div className='col-span-1 flex p-1'><div className='font-bold pr-2'>Timezone: </div> {data.properties.$geoip_time_zone}</div>
                        <div className='col-span-1 flex p-1'><div className='font-bold pr-2'>Initial Browser: </div> {data.properties.$initial_browser}</div>
                        <div className='col-span-2 flex p-1'><div className='font-bold pr-2'>Initial Current URL: </div> <a href={data.properties.$initial_current_url}>{data.properties.$initial_current_url}</a></div>
                    </div>
                </div>
                </>):''
            ))}
        </>)}
    </div>
  );
}

export default Session;
