import {
  getOrderByOrderId,
  getOrderByStoreId,
  orderRefund,
  updateKioskLocker,
  updateOrderByOrderId,
  updateOrderStatus,
} from '@adv/sdk/stores';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const GetOrders = createAsyncThunk(
  'order/GetOrders',
  async (payload: any, thunkAPI) => {
    const { data, status, error } = await getOrderByStoreId(payload);
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);
    return thunkAPI.fulfillWithValue(data);
  }
);

export const UpdateOrder = createAsyncThunk(
  'order/update',
  async (payload: any, thunkAPI) => {
    const { data, status, error } = await updateOrderByOrderId(
      payload?.businessId,
      payload?.orderId,
      payload?.orderDetails
    );
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);
    await thunkAPI.dispatch(GetOrders({id:payload?.businessId}));
    return thunkAPI.fulfillWithValue(data);
  }
);

export const GetOrderById = createAsyncThunk(
  'order/GetOrder',
  async (payload: any, thunkAPI) => {
    const { data, status, error } = await getOrderByOrderId(
      payload.businessId,
      payload.orderId
    );
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);

    return thunkAPI.fulfillWithValue(data);
  }
);

export const UpdateOrderLockerInfo = createAsyncThunk(
  'order/updateOrderLockerInfo',
  async (payload: any, thunkAPI) => {
    const { data, status, error } = await updateKioskLocker(
      payload?.businessId,
      payload?.orderId,
      payload?.kioskLocker
    );
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);

    return thunkAPI.fulfillWithValue(data);
  }
);

export const UpdateOrderStatusInfo = createAsyncThunk(
  'order/updateOrderStatusInfo',
  async (payload: any, thunkAPI) => {
    const { data, status, error } = await updateOrderStatus(
      payload?.businessId,
      payload?.orderId,
      payload?.status
    );
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);
    // thunkAPI.dispatch(
    //   GetOrderById({ businessId: payload.businessId, orderId: payload.orderId })
    // );
    return thunkAPI.fulfillWithValue(data);
  }
);
export const OrderRefundInfo = createAsyncThunk(
  'order/orderRefundInfo',
  async (payload: any, thunkAPI) => {
    const { data, status, error } = await orderRefund(
      payload?.businessId,
      payload?.orderId
    );
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);
    // thunkAPI.dispatch(
    //   GetOrderById({ businessId: payload.businessId, orderId: payload.orderId })
    // );
    return thunkAPI.fulfillWithValue(data);
  }
);
