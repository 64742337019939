import {
  CreateBusiness,
  CreateBusinessArgs,
  getOrderByStoreId,
  getStoreById,
  GetStoreByUserId,
  getThemeByBusinessCategory,
  UpdateBusinessDetails,
} from '@adv/sdk/stores';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetBusinessProductTypes,
  GetCategory,
  GetProducts,
} from '../inventory/inventory.thunk';

export const CreateStore = createAsyncThunk(
  'store/create',
  async (payload: CreateBusinessArgs, thunkAPI) => {
    const { data, status, error } = await CreateBusiness(payload);
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);
    thunkAPI.dispatch(GetStore(data.id));
    return thunkAPI.fulfillWithValue(data);
  }
);

export const GetStoreById = createAsyncThunk(
  'store/GetStoreByUserId',
  async (payload, thunkAPI) => {
    const { data, status, error } = await GetStoreByUserId();
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);
    if (data?.businesses && data.businesses.length > 0) {
      thunkAPI.dispatch(GetStore(data?.businesses[0].id));
    }
    return thunkAPI.fulfillWithValue(data);
  }
);

export const GetStore = createAsyncThunk(
  'store/get',
  async (payload: any, thunkAPI) => {
    const query = {
      storeId: payload,
    };
    const { data, status, error } = await getStoreById(query);
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);
    thunkAPI.dispatch(GetProducts(data.id));
    thunkAPI.dispatch(GetCategory(data.id));
    thunkAPI.dispatch(GetThemes(data?.business_categories[0]?.id));
    thunkAPI.dispatch(GetOrder({ id: data.id }));
    thunkAPI.dispatch(GetBusinessProductTypes());
    return thunkAPI.fulfillWithValue(data);
  }
);
export const UpdateStore = createAsyncThunk(
  'store/update',
  async (payload: any, thunkAPI) => {
    const { data, status, error } = await UpdateBusinessDetails(
      payload,
      payload?.id
    );
    thunkAPI.dispatch(GetStore(payload?.id));
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);

    return thunkAPI.fulfillWithValue(data);
  }
);
export const DeleteStore = createAsyncThunk(
  'store/delete',
  async (payload, thunkAPI) => {
    const { data, status, error } = await DeleteStore(payload);
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);

    return thunkAPI.fulfillWithValue(data);
  }
);

export const GetThemes = createAsyncThunk(
  'store/themes',
  async (payload: any, thunkAPI) => {
    const query = {
      categoryId: payload,
    };
    const { data, status, error } = await getThemeByBusinessCategory(query);
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);
    return thunkAPI.fulfillWithValue(data);
  }
);

export const GetOrder = createAsyncThunk(
  'inventory/GetOrder',
  async (payload: any, thunkAPI) => {
    const { data, status, error } = await getOrderByStoreId(payload);
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);
    console.log(data);

    return thunkAPI.fulfillWithValue(data);
  }
);
