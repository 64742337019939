import { AdvButton } from '@adv/ui';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { router } from '../routes';

function LandingPage() {
  const history = useHistory();
  // useEffect(() => {
  //   Cookies.remove('token');
  //   Cookies.remove('token', { domain: 'versicles.com' });
  // }, []);

  return (
    <div className="w-full h-screen bg-white">
      <div className="mt-4 md:mt-14 md:ml-8 md:w-2/3">
        <div className="flex justify-start pl-4">
          <div className="logo m-4">
            <img
              className="mx-auto h-12"
              src="https://versicles.com/wp-content/uploads/2022/06/Group-2.png"
              alt="Workflow"
            />
          </div>
        </div>
        <div className="flex flex-col mx-4 my-2 md:my-4 font-semibold ">
          <h1 className="text-3xl md:text-5xl ">
            Create and promote your store,
          </h1>
          <h1 className="text-3xl md:text-5xl">from a single platform</h1>
        </div>
        <p className="m-4 text-base md:text-lg font-medium text-gray-700">
          Our Web Store, Visual Website Builder, and Marketing tools let you
          create a store, build a beautiful website, and promote your business
          online.
        </p>
        <span className="flex justify-start m-4">
          <AdvButton
            color="yellow"
            type="submit"
            className="text-white w-28"
            onClick={() => history.push(router.login().$)}
          >
            Get Started
          </AdvButton>
        </span>
        <div className="apps-section fourth"></div>
      </div>
    </div>
  );
}

export default LandingPage;
