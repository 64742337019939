import {
  createContext,
  Fragment,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { Footer, SideMenu, Dropbutton } from '@adv/ui';
import { Userdetails, userRoles } from '@adv/sdk/user';
import {
  CalculatorIcon,
  ChevronDownIcon,
  LibraryIcon,
  DocumentSearchIcon,
  ShoppingCartIcon,
  TemplateIcon,
  HomeIcon,
  CubeIcon,
} from '@heroicons/react/solid';
import Cookies from 'js-cookie';
import { Link, useRouteActive } from 'react-typesafe-routes';
import { isAuthenticated, router } from '../routes';
import classNames from 'classnames';
import defaultAvatar from '../assets/images/avatar.png';
import { Burger, Collapse, Dialog, Group, Text } from '@mantine/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { GetOrder, GetStore } from '../store/stores/stores.thunk';
import { storeActions } from '../store/stores/stores.slice';
import { userActions } from '../store/user/user.slice';
import { sessionGet, sessionRemove } from '@adv/utils/storage';
import { useMediaQuery } from '@mantine/hooks';
import { environment } from '../environments/environment';
import { socket } from '@adv/utils/socket';
import alertTune from '../assets/audio/call-to-attention.mp3';
import { VolumeOffIcon, VolumeUpIcon } from '@heroicons/react/outline';
import useWebSocket from 'react-use-websocket';
import { GetOrders } from '../store/orders/orders.thunk';
// import { registerServiceWorker } from '../registerServiceWorker';

const WebsiteContext = createContext<any>(undefined);
const Layout = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery('(max-width: 700px)');
  const store = useAppSelector((state) => state.store.store);

  const TopMenu = [
    {
      title: 'Home',
      icon: (...classes: string[]) => (
        <HomeIcon className={classNames(...classes)} />
      ),
      slug: '/home',
      group: [],
      active: useRouteActive(router.home, { exact: false }),
      link: router.home,
    },
    {
      title: 'Order',
      icon: (...classes: string[]) => (
        <CalculatorIcon className={classNames(...classes)} />
      ),
      slug: '/order',
      group: [],
      active: useRouteActive(router.order, { exact: false }),
      link: router.order,
    },
    {
      title: 'Store Products',
      icon: (...classes: string[]) => (
        <ShoppingCartIcon className={classNames(...classes)} />
      ),
      slug: '/catalogue',
      active: useRouteActive(router.catalogue, { exact: false }),
      link: router.catalogue().products,
      group: [
        {
          slug: '/products',
          title: 'Products',
          link: router.catalogue().products,
          active: useRouteActive(router.catalogue().products, { exact: false }),
          activeLink: '/catalogue',
        },
        {
          slug: '/product/category',
          title: 'Category',
          link: router.catalogue().category,
          active: useRouteActive(router.catalogue().category, { exact: false }),
          activeLink: '/catalogue',
        },
      ],
    },
    {
      title: 'Delivery',
      icon: (...classes: string[]) => (
        <CubeIcon className={classNames(...classes)} />
      ),
      slug: '/delivery',
      group: [],
      active: useRouteActive(router.delivery, { exact: false }),
      link: router.delivery,
    },
    {
      title: 'Store',
      icon: (...classes: string[]) => (
        <LibraryIcon className={classNames(...classes)} />
      ),
      slug: '/store',
      group: [],
      active: useRouteActive(router.store, { exact: false }),
      link: router.store,
    },
    {
      title: 'Theme',
      icon: (...classes: string[]) => (
        <TemplateIcon className={classNames(...classes)} />
      ),
      slug: '/theme',
      group: [],
      active: useRouteActive(router.template, { exact: false }),
      link: router.template,
    },

    {
      title: 'Session',
      icon: (...classes: string[]) => (
        <DocumentSearchIcon className={classNames(...classes)} />
      ),
      slug: '/session',
      group: [],
      active: useRouteActive(router.session, { exact: false }),
      link: router.session,
    },
  ];

  useEffect(() => {
    store ? setLogged(true) : setLogged(false);
  }, [store, location]);
  useEffect(() => {
    // getuserdetails();
  }, []);
  const getuserdetails = async () => {
    const { data, status } = await Userdetails();
    console.log('🚀', data);
    if (status === 200 && data) {
      console.log(data);
      dispatch(GetStore(1));
    }
  };

  const [currentTabHeader, setTab] = useState(null);
  const [opened, setOpen] = useState(true);
  const [logged, setLogged] = useState(false);
  const [room, setRoom] = useState(null);
  const [isDialogOpened, setDialogOpened] = useState(false);
  const [isMute, setMute] = useState(true);
  const [socketUrl, setSocketUrl] = useState<string | null>(null);

  const { readyState } = useWebSocket(socketUrl, {
    share: true,
    onOpen: () => console.log('opened'),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    onMessage: (event: any) => {
      console.log('msg', event.data);
      playSound();
      dispatch(GetOrders({ id: store?.id }));
      setDialogOpened(true);
      setTimeout(() => {
        setDialogOpened(false);
      }, 3000);
    },
  });

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);

  const playSound = () => {
    (document.getElementById('audio') as HTMLAudioElement).muted = false;
    (document.getElementById('audio') as HTMLAudioElement).play();
    // console.log(document.getElementById('audio'), 'audio');
    setMute(false);
    // registerServiceWorker();
  };

  useEffect(() => {
    if (store && room !== store?.id) {
      if (socket.connected) {
        console.log(socket, 'connect');
        socket.emit('joinStore', store?.id);
        setRoom(store?.id);
      }
      socket.on('connect', (e) => {
        console.log(socket, 'connect'); // x8WIv7-mJelg7on_ALbx
        socket.emit('joinStore', store?.id);
        setRoom(store?.id);
      });
      socket.on('connect_error', (e) => {
        // console.log(e, 'connect_error');
      });

      socket.on('disconnect', (reason) => {
        console.log(reason, 'reason');
      });
      socket.on('joinedStore', (data) => console.log(data, 'joinedRoom'));
      // socket.on('orderNotify', (data) => {
      //   console.log(data, 'orderToClient');
      //   playSound();
      //   dispatch(GetOrder(store?.id));
      //   setDialogOpened(true);
      //   setTimeout(() => {
      //     setDialogOpened(false);
      //   }, 3000);
      // });
      const socketBaseUrl =
        environment?.NX_WEBSOCKET_URL || process.env.NX_WEBSOCKET_URL;
      const endPoint = `${socketBaseUrl}?source=versicles-cms&channel=orders&channelId=${store?.id}`;
      setSocketUrl(endPoint);
    }
  }, [store]);

  const logOut = async () => {
    try {
      Cookies.remove('store_token');
      Cookies.remove('store_token', { domain: 'versicles.com' });
      sessionRemove('storeId');
    } catch (error) {
      console.log(error);
    }
    await dispatch(storeActions.clearStore());
    await dispatch(userActions.clearUser());
    history.push(router.landingPage().$);

    // window.location.href = `${environment.appsUrl}/login/`;
  };
  const closeSideMenu = () => {
    if (isMobile) {
      setOpen((o) => !o);
    }
  };
  const layout = (
    <div className="flex flex-col min-h-screen relative">
      <section className="header  inset-x-0 top-0 flex  items-center justify-between px-4  h-16 border-b bg-white  fixed  z-30 ">
        {isMobile ? (
          <Burger
            opened={opened}
            onClick={() => setOpen((o) => !o)}
            size="sm"
            color={'black'}
            mr="xl"
          />
        ) : (
          ''
        )}

        <div className="flex flex-shrink md:w-1/3 justify-center md:justify-start ">
          <div
            onClick={() => setOpen((o) => !o)}
            className="text-black font-bold pl-4"
          >
            <img
              className="mx-auto h-10 w-auto"
              src="https://versicles.com/wp-content/uploads/2022/06/Group-2.png"
              alt="Workflow"
            />
          </div>
        </div>
        <h2 className="text-secondary-500 text-xl font-semibold text-center w-1/2">
          {/* {currentTabHeader} */}
        </h2>
        <a
          className="flex px-5 py-2 rounded-lg  text-sm font-medium bg-primary-900 hover:bg-primary-300"
          href={`${environment.appsUrl}/apps`}
        >
          Switch to Apps
        </a>
        {isMute ? (
          <VolumeOffIcon
            className="w-5 h-5 cursor-pointer"
            onClick={() => playSound()}
          ></VolumeOffIcon>
        ) : (
          <VolumeUpIcon
            className="w-5 h-5 cursor-pointer"
            onClick={() => setMute(true)}
          ></VolumeUpIcon>
        )}
        {/* <Dropbutton defaultAvatar={defaultAvatar} /> */}
      </section>
      <section className="content flex flex-1 mt-16">
        <Collapse
          in={opened}
          transitionDuration={100}
          transitionTimingFunction="linear"
        >
          <section className="side-menu fixed left-0 top-16 bottom-0 z-10 border-r border-gray-200 ">
            <SideMenu
              selectTab={setTab}
              closeMenu={(e: any) => closeSideMenu()}
              TopMenu={TopMenu}
              store={store}
              logOut={(e: any) => logOut()}
            />
          </section>
        </Collapse>
        <section
          className={`main-content  relative w-full ${opened ? 'ml-48' : ''}`}
          style={{ transition: 'all 0.5s ease' }}
        >
          <div className="w-full" style={{ height: 'calc(100% - 64px)' }}>
            {/* <WebsiteContext.Provider value={{ selectedWebsite }}> */}
            {children}
            {/* </WebsiteContext.Provider> */}
          </div>
        </section>
      </section>
      <Dialog
        opened={isDialogOpened}
        withCloseButton
        onClose={() => setDialogOpened(false)}
        size="lg"
        radius="md"
        position={{ top: 20, right: 20 }}
        transition="slide-up"
        transitionDuration={300}
        transitionTimingFunction="ease"
        className="cursor-pointer"
      >
        <div
          onClick={() => {
            history.push(router.order().$);
            setDialogOpened(false);
          }}
        >
          <Text size="sm" style={{ marginBottom: 10 }} weight={500}>
            New Order
          </Text>
          <Group align="flex-end">
            <Text size="sm" weight={100}>
              New Order Arrived
            </Text>
          </Group>
        </div>
      </Dialog>
      <footer className="footer">{/* <Footer />  */}</footer>
      <span>
        {/* <audio id="audio" muted={true}>
          <source src={alertTune} />
        </audio> */}
        <audio
          src="https://versicles-strapi-cms-media.s3.amazonaws.com/call_to_attention_1f9a2de21d.mp3"
          id="audio"
        ></audio>
      </span>
    </div>
  );

  const login = (
    <div className=" flex w-full h-screen m-auto bg-gradient-to-r from-primary-900 to-primary-400">
      {children}
    </div>
  );

  return <div>{!logged ? login : layout}</div>;
};
export const useWebsite = () => useContext(WebsiteContext);
export default Layout;
