import axios from 'axios';
import { sessionGet } from './storage';
// const URL = process.env.API_BASE_URL || 'http://localhost:5000';
const URL = 'https://api-dev.versicles.com';
console.log(URL, 'URL');
export const GetHeader = () => {
  let uid = '';
  const saved = sessionGet('uid');
  if (saved) uid = saved;
  return {
    'x-uid': uid,
  };
};

export const Api = axios.create({
  baseURL: URL + '/api',
  headers: GetHeader(),
});

export const ApiBaseURL = `${URL}/api`;
