export const sessionSet = (key: string, val: string) => {
  if (val) return sessionStorage.setItem(key, JSON.stringify(val));
  return null;
};
export const sessionGet = (key: string) => {
  const d = sessionStorage.getItem(key);
  if (d) return JSON.parse(d);
  return null;
};
export const sessionRemove = (key: string) => {
  sessionStorage.removeItem(key);
  return null;
};
