import React, { forwardRef, useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Card,
  Image,
  Text,
  Badge,
  Button,
  Group,
  useMantineTheme,
  TextInput,
  Modal,
  Select,
  Avatar,
} from '@mantine/core';
import { AdvButton, useYupValidationResolver } from '@adv/ui';
// import { useForm, yupResolver } from '@mantine/form';
import Cookies from 'js-cookie';
import { router } from '../routes';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { storeActions } from '../store/stores/stores.slice';
import { getBusinessCategories } from '@adv/sdk/stores';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { CreateStore } from '../store/stores/stores.thunk';

const schema = Yup.object().shape({
  name: Yup.string().min(2, 'Name should have at least 2 letters'),
  // msrp: Yup.number().max(1000000, 'Invalid msrp'),
  // discount: Yup.number().min(
  //   18,
  //   'You must be at least 18 to create an account'
  // ),
});
const category = [
  {
    value: 'FMCG & Grocery',
    label: 'FMCG & Grocery',
    image:
      'https://versicles-strapi-cms-media.s3.amazonaws.com/category-def.jpg',
    name: 'FMCG & Grocery',
  },
  {
    value: 'Restaurants & Hotels',
    label: 'Restaurants & Hotels',
    image:
      'https://versicles-strapi-cms-media.s3.amazonaws.com/category-def.jpg',
    name: 'Restaurants & Hotels',
  },
];

interface StoreFormInput {
  name: string;
  category: string;
}

const SelectItem = forwardRef<HTMLDivElement>(
  ({ image, label, description, ...others }: any, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={image} />

        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

export const StoreInputContainer = (props: any) => {
  const methods = useFormContext();
  const categoryList = useAppSelector(
    (state: any) => state.inventory.inventory
  );
  const [categories, setCategories] = useState([...categoryList]);

  useEffect(() => {
    // LoadBusinessCategories();
  }, []);

  useEffect(() => {
    setCategories(categoryList);
  }, [categoryList]);

  return (
    <div
      className={`grid gap-4 ${
        props.type == 'row' ? 'grid-cols-1 sm:grid-cols-2' : 'grid-cols-1'
      }`}
    >
      <div className="flex-grow">
        <Controller
          name="name"
          control={methods.control}
          render={({ field }) => (
            <TextInput
              {...field}
              required
              label="Business Name "
              placeholder="Business Name"
            />
          )}
        />
      </div>
      <div className="flex-grow">
        <Controller
          name="category"
          control={methods.control}
          render={({ field }) => (
            <Select
              {...field}
              // required
              label="Business Category"
              itemComponent={SelectItem}
              data={categories}
              placeholder="Business Category"
              nothingFound="Nothing found"
              searchable
              creatable
              getCreateLabel={(query) => `+ Create ${query}`}
              onCreate={(query) =>
                setCategories((current) => [
                  ...current,
                  {
                    value: query,
                    label: query,
                    image:
                      'https://versicles-strapi-cms-media.s3.amazonaws.com/category-def.jpg',
                    name: query,
                  },
                ])
              }
            />
          )}
        />
      </div>
    </div>
  );
};

function GettingStart() {
  const methods = useForm<StoreFormInput>({
    defaultValues: {
      name: '',
      // businessCategory: '',
    },
    resolver: useYupValidationResolver(schema),
  });
  const history = useHistory();
  const dispatch = useAppDispatch();
  const store = useAppSelector((state) => state.store.store);
  const [opened, setOpened] = useState(false);
  const [data, setCategories] = useState([]);
  const userData = useAppSelector((state: any) => state.user.data);

  const onSubmit: SubmitHandler<StoreFormInput> = (data) => {
    console.log(data);
    const arg = {
      name: data.name,
      business_category: +data.category,
      logo: [121], //for default store logo
    };
    dispatch(CreateStore(arg));
  };
  useEffect(() => {
    if (!store) return;
    history.push(router.store().$);
  }, [store]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-primary-900 py-12 px-4 sm:px-6 lg:px-8">
      <div style={{ width: 340, margin: 'auto', marginTop: 20 }}>
        <Card shadow="md" p="lg" className="productForm">
          <img
            className="mx-auto h-12 w-auto"
            src="https://versicles.com/wp-content/uploads/2022/06/Group-2.png"
            alt="logo"
          />
          <Text size="xl" className="p-2 font-semibold">
            Enter Business Details
          </Text>

          {/* <form
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                required
                label="Business Name "
                placeholder="Business Name"
              />
            )}
          />
          <Controller
            name="category"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                required
                label="Business Category"
                itemComponent={SelectItem}
                data={data}
                placeholder="Business Category"
                nothingFound="Nothing found"
                searchable
                creatable
                getCreateLabel={(query) => `+ Create ${query}`}
                onCreate={(query) =>
                  setCategories((current) => [
                    ...current,
                    {
                      value: query,
                      label: query,
                      image:
                        'https://versicles-strapi-cms-media.s3.amazonaws.com/category-def.jpg',
                      name: query,
                    },
                  ])
                }
              />
            )}
          />
          <AdvButton
            color="yellow"
            type="submit"
            className="text-white w-full mt-4"
            disabled={true}
          >
            Start
          </AdvButton>
        </form> */}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <StoreInputContainer />
              <AdvButton
                color="yellow"
                type="submit"
                className="text-white w-full mt-4"
                disabled={true}
              >
                Start
              </AdvButton>
            </form>
          </FormProvider>
          {/* <Button
          variant="light"
          color="blue"
          fullWidth
          style={{ marginTop: 14 }}
        >
          Finish
        </Button> */}
        </Card>
        {/* <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Choose business category"
      >
        {category.map((e) => (
          <Card
            key={e.name}
            className="flex items-center p-1 m-1 cursor-pointer"
            onClick={(value) => {
              form.setFieldValue('category', e.name);
              setOpened(false);
            }}
          >
            <div>
              <Image radius="md" height={50} src={e.image} alt={e.name} />
            </div>
            <div>{e.name}</div>
          </Card>
        ))}
        <div
          className="border-2 p-2 text-center cursor-pointer"
          onClick={(value) =>
            form.setFieldValue('category', 'My Business Category is not listed')
          }
        >
          My Business Category is not listed
        </div>
      </Modal> */}
      </div>
    </div>
  );
}
export default GettingStart;
