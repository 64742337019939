import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link, useRouteActive } from 'react-typesafe-routes';
// import { router } from '../../../routes';
import {
  AdjustmentsIcon,
  HomeIcon,
  ChartPieIcon,
  SpeakerphoneIcon,
  UserGroupIcon,
  CogIcon,
  IdentificationIcon,
  GlobeIcon,
  UserIcon,
  BriefcaseIcon,
  MenuIcon,
  ArrowCircleLeftIcon,
  ChevronLeftIcon,
  ShoppingCartIcon,
  LogoutIcon,
} from '@heroicons/react/solid';
import { getpermisionsbymodule } from '@adv/sdk/permissions';
import {
  createOrganization,
  Userdetails,
  UpdateUser,
  userRoles,
} from '@adv/sdk/user';
import classNames from 'classnames';
import { ActionIcon, Image, Navbar, ScrollArea, Text } from '@mantine/core';
import SubMenu from './subMenu';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

const SideMenu = (props: any) => {
  const functionHandler = (data: any) => {
    // console.log(data);
    props.selectTab(data);
  };
  const collapseMenu = (data: any) => {
    props.closeMenu(true);
  };

  useEffect(() => {
    const tab = props.TopMenu.filter((e: any) => e.active)[0];
    // console.log(props);
    return functionHandler(tab?.name);
  }, []);
  const docs = props.TopMenu.map((group: any, index: any) => (
    <SubMenu group={group} key={index} onLinkClick={collapseMenu} />
  ));
  const header = (
    <div className="flex  items-center border-b-2">
      <Image
        src={
          props?.store?.logo
            ? props?.store?.logo?.url
            : 'https://versicles-strapi-cms-media.s3.amazonaws.com/store-def.jpg'
        }
        height={50}
        width={50}
        fit="contain"
        alt="logo"
      />
      <p className="px-2 font-semibold text-lg">{props.store.name}</p>
    </div>
  );
  const footer = (
    <div className="flex  items-center">
      <LogoutIcon className="w-5 h-5"></LogoutIcon>

      <p
        className="px-2 font-semibold text-lg cursor-pointer"
        onClick={() => {
          props.logOut(true);
        }}
      >
        Logout
      </p>
    </div>
  );

  return (
    <Navbar
      height={'calc(100vh - 60px)'}
      p="xs"
      width={{ sm: '12rem', lg: '12rem' }}
      className="showButton bg-gray-100"
    >
      <Navbar.Section className="mb-2">{header}</Navbar.Section>

      <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
        {docs}
      </Navbar.Section>

      {/* <Navbar.Section className="mb-2 h-10">{footer}</Navbar.Section> */}
    </Navbar>
  );
};

export default SideMenu;
