import React, { useState, useRef, useEffect } from 'react';

import { Text } from '@mantine/core';
import { NavLink, useHistory } from 'react-router-dom';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Link } from 'react-typesafe-routes';

// import { HEADER_HEIGHT } from '../../Header/Header.styles';

const HEADER_HEIGHT = 60;
function hasActiveLink(group: any, pathname: string) {
  // if (group.group.some((_group: any) => _group.activeLink === pathname)) {
  //   return true;
  // }

  if (group.group.some((_group: any) => pathname.includes(_group.activeLink))) {
    return true;
  }

  return false;
}

export default function SubMenu({ group, onLinkClick }: any) {
  const pathname = useLocation().pathname;
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(!hasActiveLink(group, pathname));
  const itemRefs = useRef<Record<string, HTMLElement>>({});

  useEffect(() => {
    if (hasActiveLink(group, pathname) && itemRefs.current[pathname]) {
      const element = itemRefs.current[pathname];
      const height = typeof window !== 'undefined' ? window.innerHeight : 0;
      const { top, bottom } = element.getBoundingClientRect();

      if (top < HEADER_HEIGHT || bottom > height) {
        element.scrollIntoView({ block: 'center' });
      }
    }
    setCollapsed(!hasActiveLink(group, pathname));
  }, [pathname]);

  const setGroup = (path: any) => {
    history.push(group.slug);
  };

  const sub = group.group.map((link: any, index: any) => (
    // <NavLink
    //   to={link.slug}
    //   className=" pl-5"
    //   activeClassName="text-primary-900"
    // >
    //   {link.title}
    // </NavLink>
    <Link
      key={index}
      className={classNames(
        'flex items-center justify-start space-x-2 py-1 px-4 w-full pl-11 text-base font-semibold',
        {
          'text-primary-900  bg-gray-100': link.active,
          'text-secondary-400 hover:text-secondary-500 hover:bg-gray-100':
            !link.active,
        }
      )}
      to={link.link()}
      onClick={() => onLinkClick()}
    >
      {link.title}
    </Link>
  ));

  return (
    <div className="flex flex-col">
      <li
        className="flex w-full items-center justify-start text-base font-semibold"
        onClick={() => onLinkClick()}
      >
        <Link
          className={classNames(
            'flex items-center justify-start space-x-2 py-2 px-4 w-full rounded-sm',
            {
              'text-white  bg-primary-900': group.active,
              'text-secondary-400 hover:text-secondary-500 hover:bg-gray-100':
                !group.active,
            }
          )}
          to={group.link()}
        >
          {group.icon(
            classNames('h-5 w-5 mr-2', {
              'text-white  bg-primary-900': group.active,
              'text-secondary-400 hover:text-secondary-500 hover:bg-gray-100':
                !group.active,
            })
          )}{' '}
          {group.title}
        </Link>
      </li>
      {!collapsed && sub}
      {/* {!collapsed && categorized} */}
    </div>
  );
}
