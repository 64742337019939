import React, { useEffect, useRef, useState } from 'react';
import { Link, useRouteParams } from 'react-typesafe-routes';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { FetchOnePerson,FetchRecordingSnap,FetcheventsByperson,FetchRecordingsByperson } from '@adv/sdk/posthog'
import { showNotification, updateNotification } from '@mantine/notifications';
import { Route, useHistory } from 'react-router-dom';
import { router } from '../routes';
import  MyComponent from '@posthog/react-rrweb-player'
import '@posthog/react-rrweb-player/dist/index.css'
interface StoreInfo {
  name: string;
  address: string;
  category: string;
  country: string;
  email: string;
  phone: string;
  logo: string;
}
// landing_page: {image_carousel: {title: 'Zam Zam1', caption: 'test2'}}


function SessionDetails() {
  const dispatch = useAppDispatch();
  const dropzoneRef = useRef<() => void>();
  const store = useAppSelector((state) => state.store.store);
  const history = useHistory();
  const options = useRouteParams(router.session().sessionDetails);
  const [persons, setPersons] = useState<any>([]);
  const [event, setEvent] = useState<any>([]);
  const [recordings, setRecording] = useState<any>([]);
  const getuserdetails = async () => {
    const data = await FetchOnePerson(options.id);
    setPersons(data.data);
    const datas:any = await FetcheventsByperson(options.id);
    // console.log(datas.data.results );
    setEvent(datas.data.results);
    const recording:any = await FetchRecordingsByperson(options.id);
    console.log(recording.data.results );
    setRecording(recording.data.results );
  };
  const getRecordings = async () => {
    const data = await FetchRecordingSnap(options.id);
    
   
    
  };

  function toggle_element(element_id,self) {
      var btn = document.getElementById(self);
      var element = document.getElementById(element_id);
      const boxes = document.getElementsByClassName('boxes') as HTMLCollectionOf<HTMLElement>;
      const allbtns = document.getElementsByClassName('navbtn') as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < boxes.length; i++) {
          boxes[i].style.display = 'none';
        } 
        for (let i = 0; i < allbtns.length; i++) {
          allbtns[i].classList.remove('bg-primary-900','text-white');
        }
      element.style.display = (element.style.display != 'none' ? 'none' : 'block' );
        btn.classList.add('bg-primary-900','text-white');
  }  
  useEffect(() => {
    getuserdetails();
    // getRecordings();
    // console.log(store);
  }, []);
  return (
    <div className=" productForm m-auto mt-4 w-full px-4">
        <div className='text-xl font-semibold'>Name: {persons.name}</div>
        <div className='text-base  my-2'>ID: {options.id}</div>
        <div className='text-sm text-gray-500 mb-5'>First seen: {persons?.created_at
          ? format(
              new Date(persons.created_at),
              'MMMM dd, yyyy hh:ii '
          )
          : ''}
        </div>
        <div className="flex mx-4">
          <button className='border-purple-600 border px-2 mx-1 rounded bg-primary-900 text-white hover:bg-primary-900 hover:text-white transition-all navbtn' onClick={()=>history.push(router.session().$)}> 
            &lt; 
          </button>
          <div className='border-purple-600 border px-2 mx-1 cursor-pointer rounded bg-primary-900 text-white hover:bg-primary-900 hover:text-white transition-all navbtn' id='propbtn'  onClick={()=>toggle_element('properties','propbtn')}>Properties</div>
          <div className='border-purple-600 border px-2 mx-1 cursor-pointer rounded hover:bg-primary-900 hover:text-white transition-all navbtn' id='evebtn'  onClick={()=>toggle_element('events','evebtn')}>Events</div>
          <div className='border-purple-600 border px-2 mx-1 cursor-pointer rounded hover:bg-primary-900 hover:text-white transition-all navbtn' id='recbtn'  onClick={()=>toggle_element('recordings','recbtn')}>Recordings</div>
        </div>
        <div className="flex border-purple-600 border mt-4 mx-2  rounded-lg px-4 py-2 bg-gray-100 shadow-md boxes" id="properties">
          {persons?.length == 0 ? (
              'No Personals exist.'
          ) : ( 
            <div className='' >
                <div className='text-lg font-bold p-1'>Properties</div>
                
                <div className="grid lg:grid-cols-1 my-2">
                    <div className='col-span-1 flex p-1'><div className='font-bold pr-2 w-60'>City Name: </div> {persons.properties.$geoip_city_name}</div>
                    <div className='col-span-1 flex p-1'><div className='font-bold pr-2 w-60'>Continent: </div> {persons.properties.$geoip_continent_name} - {persons.properties.$geoip_continent_code}</div>
                    <div className='col-span-1 flex p-1'><div className='font-bold pr-2 w-60'>Country Name: </div> {persons.properties.$geoip_country_name}  - {persons.properties.$geoip_country_code}</div>
                    <div className='col-span-1 flex p-1'><div className='font-bold pr-2 w-60'>Postal Code: </div> {persons.properties.$geoip_postal_code}</div>
                    <div className='col-span-1 flex p-1'><div className='font-bold pr-2 w-60'>Location: </div>Latitude: {persons.properties.$geoip_latitude} <br/>Latitude: {persons.properties.$geoip_longitude}</div>
                    <div className='col-span-1 flex p-1'><div className='font-bold pr-2 w-60'>Subdivision 1 Name: </div> {persons.properties.$geoip_subdivision_1_name}</div>
                    <div className='col-span-1 flex p-1'><div className='font-bold pr-2 w-60'>Subdivision 1 Code: </div> {persons.properties.$geoip_subdivision_1_code}</div>
                    <div className='col-span-1 flex p-1'><div className='font-bold pr-2 w-60'>Timezone: </div> {persons.properties.$geoip_time_zone}</div>
                    <div className='col-span-1 flex p-1'><div className='font-bold pr-2 w-60'>Initial Browser: </div> {persons.properties.$initial_browser}</div>
                    <div className='col-span-1 flex p-1'><div className='font-bold pr-2 w-60'>Initial Current URL: </div> <a href={persons.properties.$initial_current_url}>{persons.properties.$initial_current_url}</a></div>
                    <div className='col-span-1 flex p-1'><div className='font-bold pr-2 w-60'>Initial Path Name: </div> {persons.properties.$initial_pathname}</div>
                    <div className='col-span-1 flex p-1'><div className='font-bold pr-2 w-60'>Initial Referring Domain: </div> {persons.properties.$initial_referring_domain}</div>
                    <div className='col-span-1 flex p-1'><div className='font-bold pr-2 w-60'>Initial OS: </div> {persons.properties.$initial_os}</div>
                    <div className='col-span-1 flex p-1'><div className='font-bold pr-2 w-60'>Initial Browser: </div> {persons.properties.$initial_browser}</div>
                </div>
            </div>
          )}
        </div>


        <div className="flex border-purple-600 border mt-4 mx-2  rounded-lg px-4 py-2 bg-gray-100 shadow-md boxes" id="events" style={{display: 'none'}}>
          <div className='text-lg font-bold p-1'>Events</div>  
          <table className='w-full text-left  rounded '>
            <tr className='border rounded'>
              <th className='p-3 '>Event</th>
              <th className='p-3 '>URL</th>
              <th className='p-3 '>Source</th>
              <th className='p-3 '>Date</th>
            </tr>
          {event?.map((data: any, i: any) => (<>
            <tr className='border-t-2'>
              <td className='p-3 capitalize'>{data.properties.$event_type? data.properties.$event_type:'Other'}</td>
              <td className='p-3 '>{data.properties.$current_url}</td>
              <td className='p-3 capitalize'>{data.properties.$lib}</td>
              <td className='p-3 '>{data?.timestamp
                        ? format(
                            new Date(data.timestamp),
                            'MMMM dd, yyyy hh:ii '
                        )
                        : ''}</td>
            </tr>
          </>
          ))}
          </table>
        </div>
        <div className="flex border-purple-600 border mt-4 mx-2  rounded-lg px-4 py-2 bg-gray-100 shadow-md boxes" id="recordings" style={{display: 'none'}}>
        <div className='text-lg font-bold p-1'>Recordings</div>  
          <table className='w-full text-left  rounded '>
            <tr className='border rounded'>
              <th className='p-3 '>Start Time</th>
              <th className='p-3 '>Duration</th>
              <th className='p-3 text-right'>Date</th>
            </tr>
          {recordings?.map((data: any, i: any) => (<>
            <tr className='border-t-2'>
              <td className='p-3 '>
                {data?.start_time ? format(
                  new Date(data.start_time),
                  'MMMM dd, yyyy hh:ii '
                ): ''}
              </td>
              <td className='p-3 '>{data.recording_duration}</td>
              <td className='p-3 text-right'>Watch Now</td>
            </tr>
            </>
          ))}
          </table>
          {/* <MyComponent /> */}
        </div>
    </div>
  );
}

export default SessionDetails;
