import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import {
  Button,
  Box,
  Group,
  Textarea,
  Text,
  Select,
  Modal,
  Grid,
  Card,
  Image,
  Loader,
} from '@mantine/core';
import { PhotographIcon } from '@heroicons/react/outline';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { Link } from 'react-typesafe-routes';
import { router } from '../routes';
import { AdvButton } from '@adv/ui';
import { useDispatch } from 'react-redux';
import { inventoryActions } from '../store/inventory/inventory.slice';
import { useHistory } from 'react-router';
import { useAppSelector } from '../store/hooks';
import { TrashIcon } from '@heroicons/react/outline';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { getFaceBookPages, postFaceBookFeed } from '@adv/sdk/stores';
import { sessionGet } from '@adv/utils/storage';

const schema = Yup.object().shape({
  // name: Yup.string().min(2, 'Name should have at least 2 letters'),
  // msrp: Yup.number().max(1000000, 'Invalid msrp'),
  // discount: Yup.number().min(
  //   18,
  //   'You must be at least 18 to create an account'
  // ),
});

function ImageUploadIcon({ status, ...props }: any) {
  if (status.accepted) {
    return <PhotographIcon {...props} />;
  }

  if (status.rejected) {
    // return <X {...props} />;
    return <PhotographIcon {...props} />;
  }

  // return <Photo {...props} />;
  return <PhotographIcon {...props} />;
}

export const dropzoneChildren = (status: any) => (
  <Group
    position="center"
    spacing="xl"
    style={{ minHeight: 100, pointerEvents: 'none' }}
  >
    <ImageUploadIcon
      status={status}
      style={{ color: '#ced4da', width: '50px', height: '50px' }}
    />

    <div>
      <Text size="md" inline>
        Drag images here or{' '}
        <span className="text-primary-700">click to select files</span>
      </Text>
      <Text size="sm" color="dimmed" inline mt={7}>
        Attach as many files as you like, each file should not exceed 5mb
      </Text>
    </div>
  </Group>
);

const PublishForm = (props: any) => {
  const fbAppId = '422516223224543';
  const dispatch = useDispatch();
  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      description: '',
      page: '',
    },
  });
  const getImage = (imageFile: any) => {
    setImageUpload(imageFile[0].path);
  };
  const [imageUpload, setImageUpload] = useState('');
  const [opened, setOpened] = useState(false);
  const [token, setToken] = useState('');
  const [login, setLogin] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState('');
  const [feed, setFeed] = useState({
    message: 'test',
    link: 'https://versicles.com',
    images: [
      {
        picture:
          'https://versicles.com/wp-content/uploads/2022/06/Group-87-1536x1423.png',
        link: 'https://versicles.com',
      },
      {
        picture:
          'https://versicles.com/wp-content/uploads/2022/06/Group-87-1536x1423.png',
        link: 'https://versicles.com',
      },
    ],
  });
  const [page_id, setPageId] = useState('');
  const [pages, setPages] = useState([]);
  const [pagesLoading, setPageLoading] = useState(false);
  const [isPostLoading, setPostLoading] = useState(false);

  const history = useHistory();
  const ads = useAppSelector((state: any) => state.inventory.adsCart);
  const store = useAppSelector((state) => state.store.store);
  const responseFacebook = (response) => {
    console.log(response);
    if (response.accessToken) {
      setLogin(true);
      setToken(response.accessToken);
    } else {
      setLogin(false);
    }
  };

  useEffect(() => {
    const fbAuth = sessionGet(`fbssls_${fbAppId}`);
    console.log(fbAuth);
    if (fbAuth?.status === 'connected') {
      setLogin(true);
      setToken(fbAuth?.authResponse?.accessToken);
    }
  }, []);
  const SetFacebookPages = async (token) => {
    setPageLoading(true);
    const { data, error } = await getFaceBookPages(token);
    console.log(data, 'data');
    if (data) {
      setPages(() => data);
      setPageId(data[0].id);
      form.setFieldValue('page', data[0].id);
    }

    setPageLoading(false);
    // setOpened(true);
  };
  const PostFacebookFeed = async () => {
    const storeUrl = `https://store.versicles.com/home?id=${store?.id}`;
    const feedVal = {
      message: form.values.description,
      link: storeUrl,
      images: ads.map((e) => ({
        picture: e.image[0].url,
        link: storeUrl,
      })),
    };
    const request = {
      access_token: token,
      page_id: form?.values?.page,
      feed: feedVal,
    };
    console.log(request, 'req');
    setPostLoading(true);
    const { data, error } = await postFaceBookFeed(request);
    setPostLoading(false);
    if (data) {
      setSubmitted(() => true);
      setMessage(() => `Posted successfully`);
    }
    console.log(data, 'data');
  };
  const onPageChangeHandler = (e) => {
    setPageId(e.target.value);
  };
  useEffect(() => {
    if (token) {
      SetFacebookPages(token);
    }
  }, [token]);
  useEffect(() => {
    setTimeout(() => {
      if (submitted) {
        setSubmitted(() => false);
        setMessage(() => '');
        props.submitForm(true);
      }
    }, 2000);
  }, [submitted]);

  const adProducts = () => {
    return (
      <div>
        {ads.map((e: any, index) => (
          <Card
            shadow="md"
            p="xs"
            withBorder
            className="flex  items-center justify-between my-2 mx-2 "
            key={index}
          >
            <div className="flex  items-center ">
              <Image
                src={
                  e?.image
                    ? e?.image[0]?.url
                    : 'https://versicles-strapi-cms-media.s3.amazonaws.com/category-def.jpg'
                }
                height={48}
                width={48}
                alt={e.name}
              />
              <p className="px-2">{e.name}</p>
            </div>
            <div
              className="flex rounded-sm cursor-pointer p-1 hover:bg-gray-200"
              onClick={() => dispatch(inventoryActions.AddToAds(e))}
            >
              <TrashIcon className="w-5 h-5"></TrashIcon>
            </div>
          </Card>
        ))}
      </div>
    );
  };
  return (
    <>
      {/* <h2 className="text-secondary-500 text-xl font-semibold flex justify-center items-center m-2">
        Add Product
      </h2> */}
      <Box className="productForm" sx={{ maxWidth: '80vw' }} mx="auto">
        <i className="fas  fa-fw fa-facebook w-10 h-10"></i>
        <form
          onSubmit={form.onSubmit(async (values) => {
            console.log(values);
            dispatch(inventoryActions.postAdded(values));
            if (values?.page) {
              await PostFacebookFeed();
            }
          })}
        >
          <div className="p-2 text-sm font-medium">Products</div>
          <div className="max-h-56 overflow-y-auto mb-4">{adProducts()}</div>
          <Textarea
            required
            label="Text"
            placeholder="Text"
            mt="xl"
            autosize
            minRows={3}
            maxRows={4}
            {...form.getInputProps('description')}
          />
          <div className="my-2">
            <label className="p-2 text-sm font-medium ">Facebook page</label>
            {pagesLoading ? (
              <Loader
                variant="dots"
                size="sm"
                color="pink"
                className="m-2 text-primary-900"
              />
            ) : (
              <div>
                {pages?.length > 0 && (
                  <Select
                    required
                    {...form.getInputProps('page')}
                    data={pages.map((e) => ({ label: e.name, value: e.id }))}
                    // value={page_id}
                  >
                    {/* {pages.map((option, index) => (
                    <option value={option.id} key={index}>
                      {option.name}
                    </option>
                  ))} */}
                  </Select>
                )}
                {pages?.length == 0 && (
                  <FacebookLogin
                    appId={fbAppId}
                    autoLoad={false}
                    fields="name,email,picture"
                    scope="public_profile,pages_manage_posts,pages_read_engagement,pages_show_list,pages_read_user_content"
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <div
                        className="text-blue-900 cursor-pointer px-2"
                        onClick={renderProps.onClick}
                      >
                        {' '}
                        {/* <div className="w-5 h-5 text-blue-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 0 320 512"
                            width="24px"
                            fill="#000000"
                          >
                            <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                          </svg>
                        </div> */}
                        Connect Facebook
                      </div>
                    )}
                  />
                )}
              </div>
            )}
          </div>
          <Group position="right" mt="xl">
            {/* <FacebookLogin
              appId="422516223224543"
              autoLoad={false}
              fields="name,email,picture"
              scope="public_profile,pages_manage_posts,pages_read_engagement,pages_show_list,pages_read_user_content"
              callback={responseFacebook}
              icon="fa-facebook"
            /> */}
            <AdvButton
              color="yellow"
              type="submit"
              disabled={isPostLoading}
              isLoading={isPostLoading}
              className="text-white w-40"
            >
              Publish
            </AdvButton>
          </Group>
          {message ? (
            <Card
              color="gray"
              shadow={'lg'}
              withBorder
              className="bg-gray-600 z-20 inline rounded-lg"
            >
              <label className="text-md font-medium">{message}</label>
            </Card>
          ) : (
            ''
          )}
        </form>
      </Box>
      {/* <Modal opened={opened} onClose={() => setOpened(false)}>
        <Product formValue={form.values} />
      </Modal> */}
    </>
  );
};

export default PublishForm;
