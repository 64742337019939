import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, Image, Text, Badge, Button, Group } from '@mantine/core';
import { Link } from 'react-typesafe-routes';
import { router } from '../routes';
import { AdvButton } from '@adv/ui';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { UpdateStore } from '../store/stores/stores.thunk';
function Templates(props: any) {
  const dispatch = useAppDispatch();
  const templates = useAppSelector((state: any) => state.store.themes);
  const store = useAppSelector((state) => state.store.store);
  const selectedTemplate = useAppSelector(
    (state: any) => state?.store?.store?.theme
  );

  const setTheme = (data) => {
    const formattedValue = {
      theme: data?.id,
    };
    dispatch(UpdateStore({ ...store, ...formattedValue }));
  };

  return (
    <div className="p-2">
      <Grid gutter="xs">
        {templates.map((e) => (
          <Grid.Col span={12} sm={4} key={e.id}>
            <Card
              shadow="md"
              p="sm"
              withBorder
              className={`${
                e.id == selectedTemplate.id ? 'border-primary-900' : ''
              }`}
            >
              <Card.Section>
                <div
                  style={{
                    // width: 240,
                    padding: '5px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Image
                    radius="md"
                    fit="contain"
                    height={150}
                    src={
                      e.preview
                        ? e?.preview?.url
                        : 'https://versicles-strapi-cms-media.s3.amazonaws.com/category-def.jpg'
                    }
                    alt={e.name}
                    // caption={e.name}
                  />
                </div>
              </Card.Section>
              <Text weight={500} align="center" className="mt-2">
                {e.name}
              </Text>
              <Group position="right" mt="xl">
                <AdvButton
                  color="transparent"
                  type="submit"
                  className="text-white w-28"
                  onClick={() => window.open(e?.preview?.url, '_blank')}
                >
                  Preview
                </AdvButton>
                {e.id == selectedTemplate.id ? (
                  <AdvButton
                    color="yellow"
                    type="submit"
                    className="text-white w-28"
                  >
                    Customize
                  </AdvButton>
                ) : (
                  <AdvButton
                    color="yellow"
                    type="submit"
                    className="text-white w-28"
                    onClick={() => setTheme(e)}
                  >
                    Apply
                  </AdvButton>
                )}
              </Group>
            </Card>
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
}

export default Templates;
