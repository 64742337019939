import { sessionGet } from '@adv/utils/storage';
import Cookies from 'js-cookie';
import { Component } from 'react';
import { useHistory } from 'react-router-dom';
import {
  OptionsRouter,
  Redirect,
  RouteMiddleware,
  stringParser,
  intParser,
} from 'react-typesafe-routes';
import App from '../app/app';
import {
  Categories,
  CategoryForm,
  GettingStart,
  Home,
  LandingPage,
  Login,
  OrderDetails,
  Product,
  ProductForm,
  SignUp,
  Session,
  StoreForm,
  Templates,
  SessionDetails,
} from '../components';
import CategoriesList from '../components/categoriesList';
import Order from '../components/order';
import { useAppSelector } from '../store/hooks';
import Delivery from '../components/Delivery';

const defaultOptions = {
  appBar: true,
};

export const isAuthenticated = () => {
  const token = checkTokenInCookies();
  const storeId = checkStorIdInSession();
  return !!token && !!storeId;
};
// Cookies.set(
//   'token',
//   ''
// );

const AuthMiddleware: RouteMiddleware = (next: any) => {
  const history = useHistory();
  const userData = useAppSelector((state: any) => state.user.data);
  if (isAuthenticated()) {
    return next;
  } else {
    if (userData) {
      return next;
    } else {
      history.push(router.landingPage().$);
    }
  }
};

export const router = OptionsRouter(defaultOptions, (route) => ({
  landingPage: route('/', {
    component: LandingPage,
  }),
  home: route('home', {
    component: Home,
    middleware: AuthMiddleware,
  }),
  login: route('login', {
    component: Login,
  }),
  signUp: route('signUp', {
    component: SignUp,
  }),
  catalogue: route(
    'catalogue',
    {
      component: Product,
      middleware: AuthMiddleware,
    },
    (route) => ({
      products: route('products', {
        component: Product,
        middleware: AuthMiddleware,
      }),
      addProduct: route('products/new', {
        component: ProductForm,
        middleware: AuthMiddleware,
      }),
      updateProduct: route('products/:id/update', {
        component: ProductForm,
        params: { id: stringParser },
      }),
      category: route('category', {
        component: CategoriesList,
        middleware: AuthMiddleware,
      }),
      categoryByProducts: route('category/:id/products', {
        component: Product,
        params: { id: stringParser },
        middleware: AuthMiddleware,
      }),
      addCategory: route('category/new', {
        component: CategoryForm,
        middleware: AuthMiddleware,
      }),
      updateCategory: route('category/:id/update', {
        component: CategoryForm,
        params: { id: stringParser },
        middleware: AuthMiddleware,
      }),
    })
  ),
  gettingStart: route('get-started', {
    component: GettingStart,
    middleware: AuthMiddleware,
  }),
  delivery: route('delivery', {
    component: Delivery,
    middleware: AuthMiddleware,
  }),
  store: route('store', {
    component: StoreForm,
    middleware: AuthMiddleware,
  }),
  session: route(
    'sessionDetails',
    {
      component: Session,
      middleware: AuthMiddleware,
    },
    (route) => ({
      sessionDetails: route('sessionDetails/:id', {
        component: SessionDetails,
        params: { id: stringParser },
        middleware: AuthMiddleware,
      }),
    })
  ),
  template: route('theme', {
    component: Templates,
    middleware: AuthMiddleware,
  }),
  order: route(
    'order',
    {
      component: Order,
      middleware: AuthMiddleware,
    },
    (route) => ({
      viewOrder: route(':id/details', {
        component: OrderDetails,
        params: { id: stringParser },
        middleware: AuthMiddleware,
      }),
    })
  ),
}));
function checkTokenInCookies() {
  return Cookies.get('store_token');
  // throw new Error('Function not implemented.');
}
function checkStorIdInSession() {
  // return sessionGet('storeId');
  return Cookies.get('storeId');
  // throw new Error('Function not implemented.');
}
