// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  apiUrl: process.env.NX_API_URL || 'https://api-dev.versicles.com/',
  appsUrl: process.env.NX_APPS_URL || 'https://auth.versicles.com',
  NX_WEBSOCKET_URL:
    process.env.NX_WEBSOCKET_URL ||
    'wss://clxu52neqj.execute-api.ap-south-1.amazonaws.com/development',
  designUrl: process.env.NX_DESIGN_STUDIO_URL,
  activeResponseUrl:
    process.env.NX_ACTIVE_RESPONSE_URL || 'https://campaigns.versicles.com/',
  superAdminUrl:
    process.env.NX_SUPER_ADMIN_URL || 'https://superadmin.versicles.com/',
  scriptCdnUrl:
    process.env.NX_SCRIPT_CDN_URL || 'http://analytics.versicles.com',
  NX_ADD_BUILDER:
    process.env.NX_ADD_BUILDER ||
    'https://design-editor.versicles.com/projects',
  flowBuilderUrl: 'https://flow-builder.versicles.com',
  REACT_APP_S3_URL:
    process.env.REACT_APP_S3_URL || 'https://d131wo3hkese53.cloudfront.net/',
};
