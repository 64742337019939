import { ApiResult, Campaign } from '@adv/interface';
import { Api } from '@adv/utils/api';
import { stringify } from 'query-string';
import Cookies from 'js-cookie';
import axios from 'axios';
var POSTHOG_PERSONAL_API_KEY= 'phx_WpV5DDaEEyO2KZDh4q1a7PxiIFQD62hVt43OmjEDvwi';
const config = {
    headers: { Authorization: `Bearer ${POSTHOG_PERSONAL_API_KEY}` }
};




export const FetchAllpersons = async (i=1) => {
  try {
    const res = await axios.get('https://app.posthog.com/api/projects/12363/persons/',config)
        return {
            status: res.status || 200,
            data: res.data || null,
        }

  } catch (error) {
    return { status: 400, error };
  }
};
export const FetchOnePerson = async (id:any) => {
  try {
    const res = await axios.get(`https://app.posthog.com/api/projects/12363/persons/${id}`,config)
        return {
            status: res.status || 200,
            data: res.data || null,
        }

  } catch (error) {
    return { status: 400, error };
  }
};
export const FetchRecordingSnap = async (id:any) => {
    try {
      const res = await axios.get(`https://app.posthog.com/api/projects/12363/session_recordings/${id}/`,config)
          return {
              status: res.status || 200,
              data: res.data || null,
          }
  
    } catch (error) {
      return { status: 400, error };
    }
  };
  export const FetcheventsByperson = async (id:any) => {
    try {
      const res = await axios.get(`https://app.posthog.com/api/projects/12363/events?person_id=${id}`,config)
          return {
              status: res.status || 200,
              data: res.data || null,
          }
  
    } catch (error) {
      return { status: 400, error };
    }
  };
  export const FetchRecordingsByperson = async (id:any) => {
    try {
      const res = await axios.get(`https://app.posthog.com/api/projects/12363/session_recordings?person_id=${id}`,config)
          return {
              status: res.status || 200,
              data: res.data || null,
          }
  
    } catch (error) {
      return { status: 400, error };
    }
  };
// export const EventsByIndustry = async (industryId: string) => {
//   try {
//     const res = await Api.get(`/eventbridge/getIndustryEvents/${industryId}`,config);
//     return {
//       status: res.status || 200,
//       data: res.data || null,
//     };
//   } catch (error) {
//     return { status: 400, error };
//   }
// };
// export const createIndustryRegistry = async (data: any) => {
//   try {
//     const res = await Api.post(`schemas/createIndustryRegistry`, data,config);
//     return {
//       status: 200,
//       data: res || null,
//     };
//   } catch (error) {
//     return { status: 400, error };
//   }
// };
// export const CreateEvents = async (data: any) => {
//   try {
//     const res = await Api.post(`eventbridge/createIndustryEvent`, data,config);
//     return {
//       status: 200,
//       data: res || null,
//     };
//   } catch (error) {
//     return { status: 400, error };
//   }
// };
// export const DeleteEvent = async (id: string) => {
//   try {
//     const res = await Api.delete(`eventbridge/deleteIndustryEvent/${id}`,config);
//     return {
//       status: 200,
//       data: res || null,
//     };
//   } catch (error) {
//     return { status: 400, error };
//   }
// };

// export const DeleteIndustryRegistry = async (industryID: string,registryname:string) => {
//   try {
//     const res = await Api.delete(`schemas/deleteIndustryRegistryFromDB/${industryID}/${registryname}`,config);
//     return {
//       status: 200,
//       data: res || null,
//     };
//   } catch (error) {
//     return { status: 400, error };
//   }
// };
