import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '@adv/sdk/common';
import {
  CreateCategory,
  CreateProduct,
  DeleteCategory,
  DeleteProduct,
  GetAllBusinessCategories,
  GetCategory,
  GetProducts,
  UpdateCategory,
  UpdateProduct,
  GetBusinessProductTypes,
} from './inventory.thunk';
import * as _ from 'lodash';

export const INVENTORY_FEATURE_KEY = 'inventory';

export interface inventoryState {
  loadingStatus: LoadingState;
  inventory: any;
  product: any;
  category: any;
  adsCart: any;
  productTypes: any;
}

export const initialInventoryState: inventoryState = {
  loadingStatus: LoadingState.idle,
  inventory: [],
  product: [],
  category: [],
  adsCart: [],
  productTypes: [],
};

export const updateCategoryWithProduct = (state, category) => {
  const categoryGroup = _.groupBy(state.product, (product) => product.category);
  const selectedCategory = _.find(state.category, { name: category });
  // console.log(categoryGroup, 'categoryGroup');
  // const categoryData = {
  //   ...selectedCategory,
  //   count: categoryGroup[category].length,
  // };
  // const index = _.findIndex(state.category, { id: category.id });
  // state.category[index] = categoryData;
  // console.log(selectedCategory, categoryGroup);
  return categoryGroup[category].length;
};

export const InventorySlice = createSlice({
  name: INVENTORY_FEATURE_KEY,
  initialState: initialInventoryState,
  reducers: {
    postAdded(state, action) {
      // console.log(state, action);
      // state.inventory.push(action.payload);
    },
    AddToAds(state, action) {
      const ad = action.payload;
      const index = _.findIndex(state.adsCart, { id: ad?.id });
      if (index >= 0) {
        // console.log(index, 'indexTT');
        state.adsCart.splice(index, 1);
      } else {
        // console.log(index, 'indexFF');
        state.adsCart.push(ad);
      }
    },
    RemoveAds(state) {
      state.adsCart = [];
    },
    productAdded(state, action) {
      console.log(state, action);
      const product = action.payload;
      const id = _.uniqueId();
      state.product.push({ ...product, id });
      const count = updateCategoryWithProduct(state, product.category);
      const index = _.findIndex(state.category, {
        name: product.category,
      });
      const category = {
        ...state.category[index],
        count: count,
      };
      console.log(category, state.category[index]);
      state.category[index] = category;
    },
    productUpdate(state, action) {
      console.log(state, action);
      const product = action.payload;
      const index = _.findIndex(state.product, { id: product.id });
      state.product[index] = product;
    },
    productDelete(state, action) {
      console.log(state, action);
      const product = action.payload;
      const index = _.findIndex(state.product, { id: product.id });
      state.product.splice(index, 1);
    },
    categoryAdded(state, action) {
      console.log(state, action);
      const category = action.payload;
      const id = _.uniqueId();
      state.category.push({ ...category, id });
    },
    categoryUpdate(state, action) {
      console.log(state, action);
      const category = action.payload;
      const index = _.findIndex(state.category, { id: category.id });
      state.category[index] = category;
    },
    categoryDelete(state, action) {
      console.log(state, action);
      const category = action.payload;
      const index = _.findIndex(state.category, { id: category.id });
      state.category.splice(index, 1);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllBusinessCategories.fulfilled, (state, { payload }) => {
        // state.inventory = payload;
        state.inventory = [...payload];
      })
      .addCase(GetBusinessProductTypes.fulfilled, (state, { payload }) => {
        // state.inventory = payload;
        const product_types = payload.map((e) => ({
          value: '' + e?.id,
          label: e?.attributes?.name,
        }));
        // console.log(payload, 'payload', product_types);

        state.productTypes = [...product_types];
      })
      .addCase(CreateCategory.fulfilled, (state, { payload }: any) => {
        // console.log(payload, 'createCategory');
        // state.category.push({ id: payload?.id, name: payload?.name });
      })
      .addCase(GetCategory.pending, (state, { payload }) => {
        state.loadingStatus = LoadingState.loading;
      })
      .addCase(GetCategory.fulfilled, (state, { payload }) => {
        state.category = (payload as any).product_categories;
        state.loadingStatus = LoadingState.loaded;
      })
      .addCase(GetCategory.rejected, (state, { payload }) => {
        state.loadingStatus = LoadingState.loading;
      })
      .addCase(UpdateCategory.fulfilled, (state, { payload }) => {
        // console.log(payload, 'UpdateCategory');
      })
      .addCase(DeleteCategory.fulfilled, (state, { payload }) => {
        // console.log(payload, 'DeleteCategory');
      })
      .addCase(CreateProduct.fulfilled, (state, { payload }) => {
        // console.log(payload, 'createProduct');
      })
      .addCase(GetProducts.pending, (state, { payload }) => {
        state.loadingStatus = LoadingState.loading;
      })
      .addCase(GetProducts.fulfilled, (state, { payload }) => {
        state.product = (payload as any).products;
        state.loadingStatus = LoadingState.loaded;
      })
      .addCase(GetProducts.rejected, (state, { payload }) => {
        state.loadingStatus = LoadingState.loading;
      })
      .addCase(UpdateProduct.pending, (state, { payload }) => {
        // console.log(payload, 'UpdateProduct');
        state.loadingStatus = LoadingState.loading;
      })
      .addCase(UpdateProduct.fulfilled, (state, { payload }) => {
        // console.log(payload, 'UpdateProduct');
        state.loadingStatus = LoadingState.loaded;
      })
      .addCase(UpdateProduct.rejected, (state, { payload }) => {
        state.loadingStatus = LoadingState.loading;
      })
      .addCase(DeleteProduct.fulfilled, (state, { payload }) => {
        // console.log(payload, 'DeleteProduct');
      });
  },
});

export const inventoryReducer = InventorySlice.reducer;
export const inventoryActions = InventorySlice.actions;
