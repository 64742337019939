import { UserSignUpArgs } from '@adv/sdk/user';
import { Alert, Loader } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-typesafe-routes';
import { router } from '../routes';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { UserSignUp } from '../store/user/user.thunk';
import { getuserbymail, createOrganization, UpdateUser } from '@adv/sdk/user';
import { AdvButton } from '@adv/ui';
import {
  ExclamationCircleIcon,
  ChevronLeftIcon,
  MailIcon,
} from '@heroicons/react/solid';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useHistory } from 'react-router-dom';

const SignUp = () => {
  const [part, setpart] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [termmodel, setTermmodel] = useState(false);
  const [terms, setterms] = useState(false);
  const [newslet, setnewslet] = useState(false);
  const [email, setemail] = useState('nil');
  const [orgcheck, setorgcheck] = useState('nil');
  const [orgName, setorgName] = useState('nil');
  const [description, setdescription] = useState('nil');
  const [grouptype, setgrouptype] = useState('');
  const [flag, setFlag] = useState(0);
  const [error, seterror] = useState<any>(null);
  const [groupdata, setgroupdata] = useState<any[]>([]);
  const success = null;
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state: any) => state.user.data);
  const history = useHistory();
  useEffect(() => {
    if (userData) history.push(router.gettingStart().$);
  }, [userData]);

  const onSubmit = async (data: any) => {
    console.log('submit', data);
  };
  // success = useAppSelector((state) => state.user.success);

  const nexttab = async () => {
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    // const numbers = new RegExp(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
    // );
    const hasNumber = /\d/;
    const demo = true;
    // const status = await getuserbymail(getValues('email'));
    if (
      getValues('password') == '' ||
      getValues('email') == '' ||
      getValues('name') == ''
    ) {
      setorgcheck('Please Fill all details!');
      // } else if (status.status != 400 && !demo) {
      //   setorgcheck('Email already exists.');
    } else if (terms == false && !demo) {
      setorgcheck('Please accept the terms to continue!');
    } else if (!pattern.test(getValues('email'))) {
      setorgcheck('Email Format is not correct!');
    } else if (!hasNumber.test(getValues('password'))) {
      setorgcheck('Password must contain at least one number!');
    } else if (getValues('password').length < 8) {
      setorgcheck('Password must be at least 8 characters in length!');
    } else {
      setorgcheck('nil');
      const userdata = {
        username: getValues('name'),
        email: getValues('email'),
        password: getValues('password'),
      };
      const a = await dispatch(UserSignUp(userdata));
      console.log(a);
      if (a.payload == 'User created successfully. Verify email.') {
        // setpart(2);
      } else {
        setorgcheck('Email or Username are already taken!');
      }
    }
  };
  const loadingStatus = useAppSelector((state) => state.user.loadingStatus);
  useEffect(() => {
    // fetchgroups()
    // if (loadingStatus === LoadingState.loading) setIsLoading(true);
    // else setIsLoading(false);
    // console.log(colors);
  }, [termmodel, terms]);
  return (
    <div className="min-h-screen flex items-center justify-center bg-primary-900 py-12 px-4 sm:px-6 lg:px-8">
      <div
        style={{ width: 340, margin: 'auto' }}
        className="bg-white p-5 rounded-md"
      >
        <div>
          <a href="/home">
            <img
              className="mx-auto h-12 w-auto"
              src="https://versicles.com/wp-content/uploads/2022/06/Group-2.png"
              alt="logo"
            />
          </a>
          <div className={`${part != 1 ? 'hidden' : ''}`}>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Start using Versicles for Free
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Or{' '}
              <Link
                to={router.login()}
                className="font-medium text-primary-900 hover:text-primary-500"
              >
                Have an account already ?
              </Link>
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className={`${part != 1 ? 'hidden' : ''}`}>
            <div className="rounded-md shadow-sm ">
              <div>
                <label htmlFor="name" className="sr-only">
                  Name
                </label>
                <input
                  id="name"
                  type="text"
                  autoComplete="name"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-900 focus:border-primary-900 focus:z-10 sm:text-sm"
                  placeholder="Full name"
                  {...register('name', { required: true })}
                />
              </div>
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-t-md relative block w-full px-3 py-2 border-r border-l border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-primary-900 focus:border-primary-900 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  {...register('email', { required: true })}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  // autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary-900 focus:border-primary-900 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  {...register('password', { required: true })}
                />
              </div>
              <div className="flex py-3 px-1">
                <div>
                  <input
                    id="terms"
                    type="checkbox"
                    required
                    defaultChecked={terms}
                    onChange={(ev) => {
                      console.log(terms);
                      setterms(!terms);
                    }}
                  />
                </div>
                <div className="flex-1 pl-2">
                  I agree with the
                  <span
                    className="text-primary-500"
                    onClick={() => setTermmodel(true)}
                  >
                    {' '}
                    Terms of use{' '}
                  </span>
                  and
                  <span
                    className="text-primary-500"
                    onClick={() => setTermmodel(true)}
                  >
                    {' '}
                    Anti-spam Policy{' '}
                  </span>
                </div>
              </div>
              <div className="flex py-3 px-1">
                <div>
                  <input
                    id="terms"
                    type="checkbox"
                    required
                    defaultChecked={newslet}
                    onChange={(ev) => {
                      console.log(newslet);
                      setnewslet(!terms);
                    }}
                  />
                </div>
                <div className="flex-1 pl-2">
                  Sign me up for Versicles marketing newsletter about product
                  updates.
                </div>
              </div>
            </div>

            <div className="mt-6">
              <div
                className="cursor-pointer  bg-primary-900 text-white text-center p-1 pt-2.5 rounded-md w-full mr-3 shadow-md hover:bg-primary-700 h-10 text-sm font-semibold"
                onClick={() => nexttab()}
              >
                Sign up
              </div>
            </div>
          </div>
          <div className={`${part != 2 ? 'hidden' : 'relative '}`}>
            <MailIcon className="absolute h-60 w-60 text-gray-200 z-0 -top-20"></MailIcon>
            <div className="relative mt-20">
              <div className="text-3xl text-gray-700 z-10 ">
                You've got mail!
              </div>
              <div className="mt-5">
                We've sent an email to{' '}
                <span className="font-bold">{getValues('email')}</span> with a
                confirmation link.
              </div>
              <div>Open the link to activate your account.</div>
              <div className="w-full text-center mt-10 font-bold text-gray-500 text-xl">
                <div
                  className="cursor-pointer mb-10"
                  onClick={() => {
                    console.log('resend');
                  }}
                >
                  Resend email
                </div>
              </div>
              {/* <div className='cursor-pointer  bg-primary-900 text-center p-1 pt-2.5 rounded-md w-full mr-3 shadow-md hover:bg-primary-700 h-10 text-sm font-semibold text-primary-900'
              onClick={()=>setpart(3)}>Check Confirmation</div> */}
            </div>
          </div>
        </form>
        {/* <button onClick={()=>statmentfunction()}>test</button> */}
        {error ? (
          <div className="border border-red-200 rounded">
            <Alert
              icon={<ExclamationCircleIcon className="text-sm" />}
              color="red"
            >
              <span className="text-red-600">{error}</span>
              {/* <span className="text-red-600">Invalid Credentials!</span> */}
            </Alert>
          </div>
        ) : (
          ''
        )}
        {success ? (
          <div className="border border-green-200 rounded">
            <Alert icon={<CheckCircleIcon className="text-sm" />} color="green">
              <span className="text-green-600">{success}</span>
              {/* <span className="text-red-600">Invalid Credentials!</span> */}
            </Alert>
          </div>
        ) : (
          ''
        )}
        {orgcheck != 'nil' ? (
          <div className="border border-red-200 rounded transition-all">
            <Alert icon={<CheckCircleIcon className="text-sm" />} color="red">
              <span className="text-red-600">{orgcheck}</span>
            </Alert>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default SignUp;
