import { Dialog, Transition } from '@headlessui/react';
import { FilterIcon } from '@heroicons/react/outline';
import { Fragment, useState } from 'react';

import { addDays, differenceInCalendarDays, format, isSameDay } from 'date-fns';
import { DateRangePicker, InputRange, Range } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Controller, useForm } from 'react-hook-form';
import { AdvButton } from '@adv/ui';
import { AdvDatesDefined } from '../shared/date-range';
import { useAppSelector } from '../store/hooks';
type FilterFormValues = {
  dateRange: Range[];
};
export default function FilterPopUp(props: any) {
  const dateRange = useAppSelector((state: any) => state.order.orderDateRange);

  const staticRangeHandler = (mainRange?: Range) => ({
    range: {},
    isSelected: (range: Range) => {
      const definedRange = getValues().dateRange[0];
      if (
        !mainRange?.startDate ||
        !mainRange?.endDate ||
        !definedRange.startDate ||
        !definedRange.endDate
      )
        return false;
      return (
        isSameDay(
          mainRange?.startDate || range.startDate,
          definedRange.startDate
        ) &&
        isSameDay(mainRange?.endDate || range.endDate, definedRange.endDate)
      );
    },
  });

  function createStaticRanges(ranges: any[]) {
    return ranges.map((e: any) => ({
      ...staticRangeHandler(e.range),
      ...e,
    }));
  }

  const defaultStaticRanges = createStaticRanges([
    {
      label: 'Today',
      range: () => ({
        startDate: AdvDatesDefined.startOfToday,
        endDate: AdvDatesDefined.endOfToday,
      }),
    },
    {
      label: 'Yesterday',
      range: () => ({
        startDate: AdvDatesDefined.startOfYesterday,
        endDate: AdvDatesDefined.endOfYesterday,
      }),
    },

    {
      label: 'This Week',
      range: () => ({
        startDate: AdvDatesDefined.startOfWeek,
        endDate: AdvDatesDefined.endOfWeek,
      }),
    },
    {
      label: 'Previous Week',
      range: () => ({
        startDate: AdvDatesDefined.startOfPrevWeek,
        endDate: AdvDatesDefined.endOfPrevWeek,
      }),
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: AdvDatesDefined.startOfMonth,
        endDate: AdvDatesDefined.endOfMonth,
      }),
    },
    {
      label: 'Previous Months',
      range: () => ({
        startDate: AdvDatesDefined.startOfPrevMonth,
        endDate: AdvDatesDefined.endOfPrevMonth,
      }),
    },
  ]);

  const defaultInputRanges: InputRange[] = [];
  const {
    getValues,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FilterFormValues>({
    defaultValues: {
      dateRange: [
        {
          startDate: dateRange?.startDate
            ? new Date(dateRange?.startDate)
            : AdvDatesDefined.startOfLast7days,
          endDate: dateRange?.endDate
            ? new Date(dateRange?.endDate)
            : AdvDatesDefined.endOfLast7days,
          key: 'date',
        },
      ],
    },
  });
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function applyFilter() {
    const ranges = getValues('dateRange')[0];
    const data = {
      startDate: format(new Date(ranges.startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(ranges.endDate), 'yyyy-MM-dd'),
    };
    props.setCustomRange(data);
    closeModal();
  }
  function cancelModal() {
    setValue('dateRange', [
      {
        startDate: dateRange?.startDate
          ? new Date(dateRange?.startDate)
          : AdvDatesDefined.startOfLast7days,
        endDate: dateRange?.endDate
          ? new Date(dateRange?.endDate)
          : AdvDatesDefined.endOfLast7days,
        key: 'date',
      },
    ]);
    closeModal();
  }
  return (
    <>
      <div className="flex items-center justify-center px-2">
        <div
          className="p-2 bg-gray-100 rounded-md mx-2 cursor-pointer"
          onClick={openModal}
        >
          <FilterIcon className="w-5 h-5 text-primary-900"></FilterIcon>
        </div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={cancelModal}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block  p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Controller
                  control={control}
                  name="dateRange"
                  render={({ field: { onChange, value } }) => (
                    <DateRangePicker
                      // maxDate={new Date()}
                      // showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      onChange={(item: any) => onChange([item.date])}
                      ranges={value}
                      direction="horizontal"
                      rangeColors={['#B43187']}
                      // color={tailwindColors.violet[700]}
                      staticRanges={defaultStaticRanges}
                      inputRanges={defaultInputRanges}
                    />
                  )}
                />
                <div className="flex justify-end mt-4">
                  <div className="w-32 px-2">
                    <AdvButton
                      color="transparent"
                      type="button"
                      onClick={cancelModal}
                    >
                      Cancel
                    </AdvButton>
                  </div>
                  <div className="w-32">
                    <AdvButton
                      color="primary"
                      type="submit"
                      onClick={applyFilter}
                    >
                      Apply
                    </AdvButton>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
