import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AddBusinessProduct,
  CreateProductCategory,
  getBusinessCategories,
  getCategoryByStoreId,
  getProductByStoreId,
  UpdateBusinessProduct,
  UpdateProductCategory,
  DeleteBusinessProduct,
  DeleteProductCategory,
  getBusinessProductTypes,
  GetBusinessProduct,
  getProductByStoreId_categoryId,
} from '@adv/sdk/stores';

interface Product {
  name: string;
  description: string;
  price: number;
  product_categories: [number];
}

export const GetAllBusinessCategories = createAsyncThunk(
  'inventory/BusinessCategories',
  async (payload, thunkAPI): Promise<any> => {
    const qs = {
      fields: ['name'],
    };
    const { data } = await getBusinessCategories(qs);
    const categories = data?.map
      ? data?.map((e) => ({
          value: String(e.id),
          label: e?.name,
          image: e?.image
            ? e?.image?.url
            : 'https://versicles-strapi-cms-media.s3.amazonaws.com/category-def.jpg',
          name: e?.name,
        }))
      : [];

    return thunkAPI.fulfillWithValue(categories);
  }
);

export const GetBusinessProductTypes = createAsyncThunk(
  'inventory/businessProductTypes',
  async (payload, thunkAPI): Promise<any> => {
    const { data } = await getBusinessProductTypes();
    const productType = data ? data.data : [];
    return thunkAPI.fulfillWithValue(productType);
  }
);

export const CreateProduct = createAsyncThunk(
  'inventory/product_create',
  async (payload: any, thunkAPI) => {
    // console.log(payload, 'CreateProduct');
    const args = {
      name: payload?.name,
      description: payload?.description,
      price: payload?.price,
      product_categories: payload?.product_categories,
      image: payload?.images,
      discounted_price: payload?.discounted_price || null,
      sold_out: payload?.sold_out,
      product_type: payload?.product_type,
      boost: payload?.boost,
      product_dimension: {
        size: payload?.size?.toString(),
        unit: payload?.unit,
        label: payload?.label,
      },
      active: payload?.active,
      publishedAt: payload?.publishedAt,
    };
    const { data, status, error } = await AddBusinessProduct(
      args,
      payload?.storeId
    );
    await thunkAPI.dispatch(GetProducts(payload?.storeId));
    await thunkAPI.dispatch(GetCategory(payload?.storeId));
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);

    return thunkAPI.fulfillWithValue(data);
  }
);
export const GetProducts = createAsyncThunk(
  'inventory/products_get',
  async (payload: any, thunkAPI) => {
    const query = {
      storeId: payload,
    };
    const { data, status, error } = await getProductByStoreId(query);
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);

    return thunkAPI.fulfillWithValue(data);
  }
);
export const GetProduct = createAsyncThunk(
  'inventory/product_get',
  async (payload: any, thunkAPI) => {
    const { data, status, error } = await GetBusinessProduct(
      payload?.storeId,
      payload?.id
    );
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);

    return thunkAPI.fulfillWithValue(data);
  }
);
export const GetCategoryIdByProducts = createAsyncThunk(
  'inventory/categoryIdByProducts',
  async (payload: any, thunkAPI) => {
    const { data, status, error } = await getProductByStoreId_categoryId({
      storeId: payload?.storeId,
      categoryId: payload?.id,
    });
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);

    return thunkAPI.fulfillWithValue(data);
  }
);

export const UpdateProduct = createAsyncThunk(
  'inventory/product_update',
  async (payload: any, thunkAPI) => {
    const args = {
      name: payload?.name,
      description: payload?.description,
      price: payload?.price,
      product_categories: payload?.product_categories,
      image: payload?.images,
      discounted_price: payload?.discounted_price || null,
      sold_out: payload?.sold_out,
      product_type: payload?.product_type,
      boost: payload?.boost,
      product_dimension: payload?.product_dimension,
      active: payload?.active,
    };
    const { data, status, error } = await UpdateBusinessProduct(
      args,
      payload?.storeId,
      payload?.id
    );
    await thunkAPI.dispatch(GetProducts(payload?.storeId));
    await thunkAPI.dispatch(GetCategory(payload?.storeId));
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);

    return thunkAPI.fulfillWithValue(data);
  }
);
export const DeleteProduct = createAsyncThunk(
  'inventory/product_delete',
  async (payload: any, thunkAPI) => {
    const args = {
      ...payload,
      publishedAt: null,
    };
    const { data, status, error } = await UpdateBusinessProduct(
      args,
      payload?.storeId,
      payload?.id
    );
    await thunkAPI.dispatch(GetProducts(payload?.storeId));
    await thunkAPI.dispatch(GetCategory(payload?.storeId));
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);

    return thunkAPI.fulfillWithValue(data);
  }
);
export const CreateCategory = createAsyncThunk(
  'inventory/category_create',
  async (payload: any, thunkAPI) => {
    // console.log(payload, 'CreateCategory');
    const args = {
      name: payload?.name,
      business_categories: payload?.business_categories,
      business: [payload?.storeId],
      image: payload?.images,
    };
    const { data, status, error } = await CreateProductCategory(
      args,
      payload?.storeId
    );
    thunkAPI.dispatch(GetCategory(payload?.storeId));
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);
    return thunkAPI.fulfillWithValue(data);
  }
);
export const GetCategory = createAsyncThunk(
  'inventory/category_get',
  async (payload: any, thunkAPI) => {
    const query = {
      storeId: payload,
    };
    const { data, status, error } = await getCategoryByStoreId(query);
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);

    return thunkAPI.fulfillWithValue(data);
  }
);
export const UpdateCategory = createAsyncThunk(
  'inventory/C_update',
  async (payload: any, thunkAPI) => {
    // console.log(payload, 'UpdateCategory');
    const args = {
      name: payload?.name,
      business_categories: payload?.business_categories,
      image: payload?.images,
    };
    const { data, status, error } = await UpdateProductCategory(
      args,
      payload?.storeId,
      payload?.productCategoryId
    );
    thunkAPI.dispatch(GetCategory(payload?.storeId));

    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);

    return thunkAPI.fulfillWithValue(data);
  }
);
export const DeleteCategory = createAsyncThunk(
  'inventory/category_delete',
  async (payload: any, thunkAPI) => {
    const args = {
      name: payload?.name,
      business_categories: payload?.business_categories,
      image: payload?.images,
    };
    const { data, status, error } = await DeleteProductCategory(
      args,
      payload?.storeId,
      payload?.id
    );
    if ([200, 201].indexOf(status) === -1)
      return thunkAPI.rejectWithValue(error ?? data?.response);

    return thunkAPI.fulfillWithValue(data);
  }
);
