import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AdvButton } from '@adv/ui';
import { Link } from 'react-typesafe-routes';
import { router } from '../routes';
import {
  Card,
  Image,
  Text,
  Badge,
  Button,
  Group,
  Stack,
  Grid,
  Drawer,
  Table,
  Checkbox,
  Menu,
  Skeleton,
  Modal,
  CloseButton,
} from '@mantine/core';
import {
  FilterIcon,
  DotsVerticalIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import PublishForm from './publishForm';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { ArrowLeftIcon, SearchIcon } from '@heroicons/react/solid';
import { useMediaQuery } from '@mantine/hooks';
import { default_order_timeLine } from '@adv/enums';
import { socket } from '@adv/utils/socket';
import { UpdateOrder, GetOrders } from '../store/orders/orders.thunk';
import FilterPopUp from './filter-popup';
import { AdvDatesDefined } from '../shared/date-range';
import { orderActions } from '../store/orders/orders.slice';

function Order(props: any) {
  const Orders = useAppSelector((state: any) => state.order.orders);
  const store = useAppSelector((state: any) => state.store.store);
  const loading = useAppSelector((state: any) => state.order.loading);
  const dateRange = useAppSelector((state: any) => state.order.orderDateRange);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const [opened, setOpened] = useState(false);
  const [checked, setChecked] = useState(false);
  const isMobile = useMediaQuery('(max-width: 700px)');
  const [q, setQ] = useState('');
  const [mobileSearch, setMobileSearch] = useState(false);
  const [order_timeLine, setOrder_timeLine] = useState(default_order_timeLine);
  const [isDeleteDialogOpened, setDeleteDialogOpened] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const rowItems = Array(10).fill(null);
  const [StatsDate, setStatsDate] = useState({
    startDate: format(AdvDatesDefined.startOfLast7days, 'yyyy-MM-dd'),
    endDate: format(AdvDatesDefined.endOfLast7days, 'yyyy-MM-dd'),
  });
  useEffect(() => {
    dispatch(GetOrders({ id: store?.id, dateRange: dateRange || StatsDate }));
    socket.on('statusUpdate', (orderId) => {
      dispatch(GetOrders({ id: store?.id, dateRange: dateRange || StatsDate }));
    });
    const interval = setInterval(fetchData, 60000); // Fetch data every 1 min
    return () => {
      // console.log('closeStatusUpdate');
      socket?.off('statusUpdate');
      clearInterval(interval);
    };
  }, [StatsDate]);
  const fetchData = async () => {
    if (store?.id) {
      dispatch(GetOrders({ id: store?.id, dateRange: dateRange || StatsDate }));
    }
  };
  function search(items) {
    return items.filter((item) => {
      return (
        item.order_id.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      );
    });
  }
  const getStatusColor = (status) => {
    let color = 'text-black-900';
    switch (status) {
      case 'delivered':
        color = 'text-green-700';
        break;
      case 'customer_intransit':
        color = 'text-blue-700';
        break;
      case 'customer_reached':
        color = 'text-primary-700 animate-bounce';
        break;
      case 'cancelled':
        color = 'text-red-700';
        break;
      default:
        break;
    }
    return color;
  };
  const rows = search(Orders)?.map((element) => (
    <tr key={element.id} className="border hover:bg-gray-200 cursor-pointer">
      {/* <td>{element.id}</td> */}
      <td
        onClick={() => {
          history.push(router.order().viewOrder({ id: element.id }).$);
        }}
      >
        {element?.order_id}
      </td>
      <td
        onClick={() => {
          history.push(router.order().viewOrder({ id: element.id }).$);
        }}
      >
        {isMobile
          ? format(new Date(element?.createdAt), 'MMM dd')
          : format(new Date(element?.createdAt), 'yyyy MMM dd')}
      </td>
      <td
        onClick={() => {
          history.push(router.order().viewOrder({ id: element.id }).$);
        }}
      >
        {element?.customer?.name}
      </td>
      <td
        onClick={() => {
          history.push(router.order().viewOrder({ id: element.id }).$);
        }}
      >
        {element?.order_items?.length}
      </td>
      <td
        onClick={() => {
          history.push(router.order().viewOrder({ id: element.id }).$);
        }}
        className={`font-semibold ${getStatusColor(element?.status?.name)}`}
      >
        {order_timeLine[element?.status?.name]?.orderStatusLabel}
      </td>
      <td
        onClick={() => {
          history.push(router.order().viewOrder({ id: element.id }).$);
        }}
      >
        {element.total}
      </td>
      <td
        onClick={() => {
          console.log('delete');
          setSelectedOrder(element);
          setDeleteDialogOpened(true);
        }}
      >
        <TrashIcon className="w-5 h-5 cursor-pointer"></TrashIcon>
      </td>
    </tr>
  ));
  const deleteOrder = async (element) => {
    await dispatch(
      UpdateOrder({
        businessId: store?.id,
        orderId: element?.id,
        orderDetails: { deleted: true },
      })
    );
    setSelectedOrder(null);
    setDeleteDialogOpened(false);
  };

  const customDateRange = (range) => {
    setStatsDate(range);
    dispatch(orderActions.setOrderDateRange(range));
    dispatch(GetOrders({ id: store?.id, dateRange: range }));
    return;
  };
  return (
    <>
      <div className="h-full">
        <div className="flex flex-row items-end justify-end m-2 sticky top-16 z-10 pt-3 pb-1 bg-white w-full">
          <div className="relative">
            <input
              placeholder="Search Order Id"
              className="ml-2 border border-gray-200 rounded-md text-md w-56 h-9 pl-2 outline-none"
              onChange={(ev) => setQ(ev.target.value)}
            />
            <SearchIcon className="h-6 absolute right-1 top-1.5 text-gray-500" />
          </div>
          <div className="col-span-2 flex items-end">
            <FilterPopUp setCustomRange={customDateRange} />
          </div>
          <button
            className="p-2 bg-gray-100 rounded-md mx-2 cursor-pointer"
            onClick={() =>
              dispatch(GetOrders({ id: store?.id, dateRange: StatsDate }))
            }
            title="reload"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        {/* <div>Order</div> */}
        <div className="flex flex-row items-end justify-end  "></div>
        <div className="p-2 h-100 ">
          <Table className="">
            <thead className="sticky top-28 bg-white z-10">
              <tr>
                <th className="w-1/6">OrderId</th>
                <th className="w-1/6">Date</th>
                <th className="w-1/6">Customer</th>
                <th className="w-1/6">Items</th>
                <th className="w-1/6">Status</th>
                <th className="w-1/6">Amount</th>
                <th></th>
              </tr>
            </thead>
            {!loading ? (
              <tbody>{rows}</tbody>
            ) : (
              <tbody>
                {rowItems.map((_, index) => (
                  <tr key={index}>
                    <td colSpan={7}>
                      <Skeleton height={50} width="100%"></Skeleton>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
        </div>
      </div>
      <Modal
        opened={isDeleteDialogOpened}
        onClose={() => setDeleteDialogOpened(false)}
        size="md"
        radius="md"
        transition="slide-up"
        transitionDuration={300}
        transitionTimingFunction="ease"
        withCloseButton={false}
      >
        <CloseButton
          aria-label="Close modal"
          className="absolute"
          style={{ top: '5px', right: '20px' }}
          onClick={() => setDeleteDialogOpened(false)}
        />
        <Text
          size="sm"
          style={{ marginBottom: 10 }}
          weight={500}
          align="center"
        >
          Delete Order
        </Text>
        <div className="flex flex-col justify-center items-center">
          <Text size="sm" weight={100} style={{ marginBottom: 10 }}>
            Once you delete the order, you can’t process it anymore.
          </Text>
          <Button
            className="mt-4"
            color="pink"
            onClick={() => deleteOrder(selectedOrder)}
          >
            Delete Order
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default Order;
