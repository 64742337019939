import React, { FunctionComponent, useEffect, useState } from 'react';
import { LockClosedIcon } from '@heroicons/react/solid';
import { Link } from 'react-typesafe-routes';
import { router } from '../routes';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { UserLoginArgs } from '@adv/sdk/user';
import { UserLogin } from '../store/user/user.thunk';
import { Alert, Loader, Text } from '@mantine/core';
import { LoadingState } from '@adv/sdk/common';
import classNames from 'classnames';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { AdvButton } from '@adv/ui';
import { useHistory } from 'react-router-dom';
import { GetStoreById } from '../store/stores/stores.thunk';
// import googleIconImage from '../../assets/images/google-app.jpg';

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useAppDispatch();
  // const onSubmit = (data: UserLoginArgs) => dispatch(UserLogin(data));
  const onSubmit = (data: any) => {
    const args = {
      identifier: data.email,
      password: data.password,
    };
    dispatch(UserLogin(args));
  };
  const googleAccount = () => {
    window.location.href = process.env.NX_API_URL + '/api/auth/google';
  };
  const store = useAppSelector((state: any) => state.store.store);
  const history = useHistory();
  useEffect(() => {
    if (store) history.push(router.home().$);
  }, [store]);
  const userData = useAppSelector((state: any) => state.user.data);
  useEffect(() => {
    if (userData && !store) dispatch(GetStoreById());
  }, [userData]);
  const error = useAppSelector((state) => state.user.error);
  const loadingStatus = useAppSelector((state) => state.user.loadingStatus);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (loadingStatus === LoadingState.loading) setIsLoading(true);
    else setIsLoading(false);
  }, [loadingStatus]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-primary-900 py-12 px-4 sm:px-6 lg:px-8">
      <div
        style={{ width: 340, margin: 'auto' }}
        className="bg-white p-5 rounded-md"
      >
        <div>
          <a href="/home">
            <img
              className="mx-auto h-12 w-auto"
              src="https://versicles.com/wp-content/uploads/2022/06/Group-2.png"
              alt="logo"
            />
          </a>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            <Link
              to={router.signUp()}
              className="font-medium text-primary-900 hover:text-primary-500"
            >
              Sign Up to get started
            </Link>
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                {...register('email', { required: true })}
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-900 focus:border-primary-900 focus:z-10 sm:text-sm"
                placeholder="Email address"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                {...register('password', { required: true })}
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary-900 focus:border-primary-900 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 text-primary-900 focus:ring-primary-500 border-gray-300 rounded"
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-900"
              >
                Remember me
              </label>
            </div>

            <div className="text-sm">
              <a
                href="#"
                className="font-medium text-primary-900 hover:text-primary-500"
              >
                Forgot your password?
              </a>
            </div>
          </div>

          <div>
            <AdvButton
              color="yellow"
              type="submit"
              disabled={isLoading}
              isLoading={isLoading}
              className="text-white w-full"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-primary-800 group-hover:text-primary-300"
                  aria-hidden="true"
                />
              </span>
              Sign in
            </AdvButton>
          </div>
        </form>
        {error ? (
          <div className="border border-red-200 rounded">
            <Alert
              icon={<ExclamationCircleIcon className="text-sm" />}
              color="red"
            >
              {/* <span className="text-red-600">{error}</span> */}
              <span className="text-red-600">Invalid Credentials!</span>
            </Alert>
          </div>
        ) : (
          ''
        )}
        <div className="text-center ">
          {/* <span className="text-sm text-gray-600">Or</span> */}
          {/* <div onClick={googleAccount}>
          <AdvButton color="blue">
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
            </span>
            Sign in with Google
          </AdvButton>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
