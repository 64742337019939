import { LoadingState } from '@adv/sdk/common';
import { sessionSet } from '@adv/utils/storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateStore,
  DeleteStore,
  GetOrder,
  GetStore,
  GetStoreById,
  GetThemes,
  UpdateStore,
} from './stores.thunk';

export const STORES_FEATURE_KEY = 'store';

export interface storesState {
  loading: boolean;
  store: any;
  themes: any;
  orders: any;
  updateStatus: LoadingState;
  isInitialSetup: boolean;
}

export const initialStoreState: storesState = {
  loading: false,
  store: null,
  themes: [],
  orders: [],
  updateStatus: LoadingState.idle,
  isInitialSetup: false,
};

export const storeSlice = createSlice({
  name: STORES_FEATURE_KEY,
  initialState: initialStoreState,
  reducers: {
    addStore(state, action) {
      state.store = action.payload;
    },
    clearStore(state) {
      state.store = null;
    },
    InitialStoreSetupComplete(state) {
      state.isInitialSetup = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CreateStore.fulfilled, (state, { payload }) => {
        // console.log(payload, 'createStore');
        sessionSet('storeId', (payload as any).id);
        state.isInitialSetup = true;
      })
      .addCase(GetStore.fulfilled, (state, { payload }) => {
        sessionSet('storeId', (payload as any).id);
        // const storeInfo = {
        //   name: (payload as any)?.attributes?.name,
        //   id: (payload as any)?.id,
        // };
        state.store = payload;
      })
      .addCase(UpdateStore.pending, (state, action) => {
        state.updateStatus = LoadingState.loading;
      })
      .addCase(UpdateStore.fulfilled, (state, { payload }) => {
        // console.log(payload, 'UpdateStore');
        state.updateStatus = LoadingState.loaded;
      })
      .addCase(UpdateStore.rejected, (state, action) => {
        state.updateStatus = LoadingState.error;
      })
      .addCase(DeleteStore.fulfilled, (state, { payload }) => {
        // console.log(payload, 'DeleteStore');
      })
      .addCase(GetThemes.fulfilled, (state, { payload }) => {
        state.themes = [...(payload as any)?.themes];
      })
      .addCase(GetOrder.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(GetOrder.fulfilled, (state, { payload }) => {
        state.orders = [...(payload as any)?.orders];
        state.loading = false;
      })
      .addCase(GetStoreById.fulfilled, (state, { payload }) => {
        // sessionSet('storeId', (payload as any).id);
        // state.store = payload;
      });
  },
});

export const StoreReducer = storeSlice.reducer;
export const storeActions = storeSlice.actions;
